import React from 'react';
import { defineMessages } from 'react-intl';
import { getTpuUrl } from '../../api/manage.js';
import warnIcon from './warn.svg';
import styles from './helper-menu.css';

const message = defineMessages({
    help: {
        id: 'gui.modal.help',
        defaultMessage: '帮助',
        description: '帮助'
    },
    tip: {
        id: 'gui.tpu.tip',
        defaultMessage: '请检查是否安装插件，并启动插件',
        description: '请检查是否安装插件，并启动插件'
    },
    link: {
        id: 'gui.tpu.link',
        defaultMessage: '插件下载地址：',
        description: '插件下载地址：'
    }
})

class HelperMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: ''
        }
    }
    componentDidMount() {
        getTpuUrl('tpu_plugins_url')
            .then((res) => {
                if (res.code === 200) {
                    this.setState({
                        url: res.msg
                    })
                }
            })
            .catch((err) => {
                this.setState({
                    url: ''
                })
            })
    }
    render() {
        return (
            <div className={styles.helperBox}>
                <img
                    className={styles.warnIcon}
                    src={warnIcon}
                />

                <div className={styles.tips}>
                    <div className={styles.trangleTop}></div>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <img
                                className={styles.warnIcon}
                                src={warnIcon}
                            />
                            <b className={styles.help}>{this.props.intl.formatMessage(message.help)}</b>
                        </div>
                        <p className={styles.tip1}>{this.props.intl.formatMessage(message.tip)}</p>
                        <div className={styles.dowload}>
                            {this.props.intl.formatMessage(message.link)}<br />
                            <a className={styles.link} target='_blank' href={this.state.url}>{this.state.url}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HelperMenu;
