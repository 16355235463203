import ScratchBlocks from 'scratch-blocks';

const categorySeparator = '<sep gap="36"/>';

const blockSeparator = '<sep gap="36"/>'; // At default scale, about 28px

import motionIcon from '../../skimg/yd.png';
import lookIcon from '../../skimg/wg.png';
import soundIcon from '../../skimg/sy.png';
import eventIcon from '../../skimg/sj.png';
import controlIcon from '../../skimg/kz.png';
import sensingIcon from '../../skimg/zc.png';
import operatorsIcon from '../../skimg/ys.png';
import variablesIcon from '../../skimg/bl.png';
import myblocksIcon from '../../skimg/diy.png';
import displayIcon from '../../skimg/diy.png';
import modelIcon from '../../skimg/model.png';
import trainIcon from '../../skimg/xl.png';
import compileIcon from '../../skimg/by.png'
import reasoningIcon from '../../skimg/tl.png'
import tpuIcon from '../../skimg/tpu(1).png'
import monitorIcon from '../../skimg/xsq.png'

let dataSetIcon = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAYCAYAAADtaU2/AAAAAXNSR0IArs4c6QAAAwVJREFUSEu1ll9IU3EUx79nm1rSTCR9iNKNqUkQ9ZAIPQlFpSGtclsIIdRTL5noW5Z3S18qKHwJohIDCWfmnzIz6qHexBfpoTTTzSVSlILNhK3tnrg37tjc3b066z6e3znnc875nd+5h6DxuQLXbRyNnoABh4m5hJl2EcGsZsKMIBHPM9E0gV6Hwr9fDJS0zaRyT2oHrkCLDaLJA+AsAINWcCnPCFFmPA4bIsJAYXIASWDnXKsTTJ0EZKcFTDLiXyIM53otrf3xRwlgp9/dBPBNAlQrsYlARGax2Wv13FZ8xABOn2AnQl/apdWPSmTGGa9VGJBUZbA90GLLFE0TBGzTt09fg4FgJIwDT0uFWRns8gvdAOr0XFaZK1CbW4l7i0MYW/0oq6vJtPww45HXKtTT6YW2ooxwZFavxBKgPu+47DPKIjp+PEGeMSdJpgSk1e0hEcXk8LkbDMR3tKKMh46vTqI8u0yGG+nvS4uXSQHpw+kSOf3CMAHVqcDx0K6llxgJjsnlVbJ/uzKBu4uDMZlSDS04M4bI5RekyypTA6tBFT3lLB6kJkuR0CS5fMIyCDlrFQ5u3YPmAmlwAUqm+7fYcHGHHf3L7zAaHE/I0vOtC1OhQIJM+NqJ6fB8MpvxUwNchuYCVwK4InsvGvMdCTIpy5qcQ7j1vQez4YUNgadAKF1vqddf/j6MrX5QrzTjk5TxKAhHN9JcTfkuubPjryHxfjWgkhHjFTnm3A0G/pfPSQcKQGTxMtl9giWTII0wzR9D8gDpQ57RvGaA6EMZ4EimySrDnP7WBwQ6rzcyj5nLUbu9EveXhmP3pybTHJnAQ69FuCCDT36+sjvLlPGegFw9+GbOmXk5FI3sGyxu/xIrb63fXWUEP9eb2WmDCVGwWNNj8YxIPpIWAQLf+A9w6V/c6LUKHUrgSQ3l8F87RTB0EdSXujQyXmES67xFnmfxtqqd7JhpLSQj2gkk/aPTX/ZE7uYsXO3d6Q6sDVjzCUnrLVisZvCRjay3EPGGjJHhHpXtUgngD6V0vFlySXg/AAAAAElFTkSuQmCC'

/* eslint-disable no-unused-vars */
const motion = function (isInitialSetup, isStage, targetId) {
    const stageSelected = ScratchBlocks.ScratchMsgs.translate(
        'MOTION_STAGE_SELECTED',
        'Stage selected: no motion blocks'
    );
    return `
    <category name="%{BKY_CATEGORY_MOTION}" id="motion" colour="#4C97FF" secondaryColour="#3373CC" iconURI='${motionIcon}'>
        ${isStage ? `
        <label text="${stageSelected}"></label>
        ` : `
        <block type="motion_movesteps">
            <value name="STEPS">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="motion_turnright">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">15</field>
                </shadow>
            </value>
        </block>


        <block type="motion_turnleft">
            <value name="DEGREES">
                <shadow type="math_number">
                    <field name="NUM">15</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_goto">
            <value name="TO">
                <shadow type="motion_goto_menu">
                </shadow>
            </value>
        </block>
        <block type="motion_gotoxy">
            <value name="X">
                <shadow id="movex" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow id="movey" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="motion_glideto" id="motion_glideto">
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="motion_glideto_menu">
                </shadow>
            </value>
        </block>
        <block type="motion_glidesecstoxy">
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="X">
                <shadow id="glidex" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
            <value name="Y">
                <shadow id="glidey" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_pointindirection">
            <value name="DIRECTION">
                <shadow type="math_angle">
                    <field name="NUM">90</field>
                </shadow>
            </value>
        </block>
        <block type="motion_pointtowards">
            <value name="TOWARDS">
                <shadow type="motion_pointtowards_menu">
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_changexby">
            <value name="DX">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="motion_setx">
            <value name="X">
                <shadow id="setx" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="motion_changeyby">
            <value name="DY">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="motion_sety">
            <value name="Y">
                <shadow id="sety" type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="motion_ifonedgebounce"/>
        ${blockSeparator}
        <block type="motion_setrotationstyle"/>
        ${blockSeparator}
        <block id="${targetId}_xposition" type="motion_xposition"/>
        <block id="${targetId}_yposition" type="motion_yposition"/>
        <block id="${targetId}_direction" type="motion_direction"/>`}
        ${categorySeparator}
    </category>
    `;
};

const xmlEscape = function (unsafe) {
    return unsafe.replace(/[<>&'"]/g, c => {
        switch (c) {
        case '<': return '&lt;';
        case '>': return '&gt;';
        case '&': return '&amp;';
        case '\'': return '&apos;';
        case '"': return '&quot;';
        }
    });
};

const looks = function (isInitialSetup, isStage, targetId, costumeName, backdropName) {
    const hello = ScratchBlocks.ScratchMsgs.translate('LOOKS_HELLO', 'Hello!');
    const hmm = ScratchBlocks.ScratchMsgs.translate('LOOKS_HMM', 'Hmm...');
    return `
    <category name="%{BKY_CATEGORY_LOOKS}" id="looks" colour="#9966FF" secondaryColour="#774DCB" style="float:left; " iconURI='${lookIcon}'>
        ${isStage ? '' : `
        <block type="looks_sayforsecs">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hello}</field>
                </shadow>
            </value>
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">12</field>
                </shadow>
            </value>
        </block>
        <block type="looks_say">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hello}</field>
                </shadow>
            </value>
        </block>
        <block type="looks_thinkforsecs">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hmm}</field>
                </shadow>
            </value>
            <value name="SECS">
                <shadow type="math_number">
                    <field name="NUM">2</field>
                </shadow>
            </value>
        </block>
        <block type="looks_think">
            <value name="MESSAGE">
                <shadow type="text">
                    <field name="TEXT">${hmm}</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        `}
        ${isStage ? `
            <block type="looks_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_switchbackdroptoandwait">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_nextbackdrop"/>
        ` : `
            <block id="${targetId}_switchcostumeto" type="looks_switchcostumeto">
                <value name="COSTUME">
                    <shadow type="looks_costume">
                        <field name="COSTUME">${costumeName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_nextcostume"/>
            <block type="looks_switchbackdropto">
                <value name="BACKDROP">
                    <shadow type="looks_backdrops">
                        <field name="BACKDROP">${backdropName}</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_nextbackdrop"/>
            ${blockSeparator}
            <block type="looks_changesizeby">
                <value name="CHANGE">
                    <shadow type="math_number">
                        <field name="NUM">10</field>
                    </shadow>
                </value>
            </block>
            <block type="looks_setsizeto">
                <value name="SIZE">
                    <shadow type="math_number">
                        <field name="NUM">100</field>
                    </shadow>
                </value>
            </block>
        `}
        ${blockSeparator}
        <block type="looks_changeeffectby">
            <value name="CHANGE">
                <shadow type="math_number">
                    <field name="NUM">25</field>
                </shadow>
            </value>
        </block>
        <block type="looks_seteffectto">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="looks_cleargraphiceffects"/>
        ${blockSeparator}
        ${isStage ? '' : `
            <block type="looks_show"/>
            <block type="looks_hide"/>
        ${blockSeparator}
            <block type="looks_gotofrontback"/>
            <block type="looks_goforwardbackwardlayers">
                <value name="NUM">
                    <shadow type="math_integer">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
            </block>
        `}
        ${isStage ? `
            <block id="backdropnumbername" type="looks_backdropnumbername"/>
            <block type="looks_screenshotbackdropnumbername" />
        ` : `
            <block id="${targetId}_costumenumbername" type="looks_costumenumbername"/>
            <block id="backdropnumbername" type="looks_backdropnumbername"/>
            <block id="${targetId}_size" type="looks_size"/>
            <block type="looks_screenshotrolenumbername" />
        `}
        ${categorySeparator}
    </category>
    `;
};

const sound = function (isInitialSetup, isStage, targetId, soundName) {
    const content = ScratchBlocks.ScratchMsgs.translate('VOICE_CONTENT', '语音内容');
    return `
    <category name="%{BKY_CATEGORY_SOUND}" id="sound" colour="#D65CD6" secondaryColour="#BD42BD" iconURI='${soundIcon}'>
        <block id="${targetId}_sound_playuntildone" type="sound_playuntildone">
            <value name="SOUND_MENU">
                <shadow type="sound_sounds_menu">
                    <field name="SOUND_MENU">${soundName}</field>
                </shadow>
            </value>
        </block>
        <block id="${targetId}_sound_play" type="sound_play">
            <value name="SOUND_MENU">
                <shadow type="sound_sounds_menu">
                    <field name="SOUND_MENU">${soundName}</field>
                </shadow>
            </value>
        </block>
        <block type="sound_stopallsounds"/>
        ${blockSeparator}
        <block type="sound_changeeffectby">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block type="sound_seteffectto">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>
        <block type="sound_cleareffects"/>
        ${blockSeparator}
        <block type="sound_changevolumeby">
            <value name="VOLUME">
                <shadow type="math_number">
                    <field name="NUM">-10</field>
                </shadow>
            </value>
        </block>
        <block type="sound_setvolumeto">
            <value name="VOLUME">
                <shadow type="math_number">
                    <field name="NUM">100</field>
                </shadow>
            </value>
        </block>
        <block id="${targetId}_volume" type="sound_volume"/>
        ${categorySeparator}
        <block type="sound_play_text">
            <value name="VOLUME">
                <shadow type="text">
                    <field name="TEXT">${content}</field>
                </shadow>
            </value>
        </block>
    </category>
    `;
};

const events = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_EVENTS}" id="events" colour="#FFD500" secondaryColour="#CC9900" iconURI='${eventIcon}'>
        <block type="event_whenflagclicked"/>
        <block type="event_whenkeypressed">
        </block>
        ${isStage ? `
            <block type="event_whenstageclicked"/>
        ` : `
            <block type="event_whenthisspriteclicked"/>
        `}
        <block type="event_whenbackdropswitchesto">
        </block>
        ${blockSeparator}
        <block type="event_whengreaterthan">
            <value name="VALUE">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="event_whenbroadcastreceived">
        </block>
        <block type="event_broadcast">
            <value name="BROADCAST_INPUT">
                <shadow type="event_broadcast_menu"></shadow>
            </value>
        </block>
        <block type="event_broadcastandwait">
            <value name="BROADCAST_INPUT">
              <shadow type="event_broadcast_menu"></shadow>
            </value>
        </block>
        ${categorySeparator}
    </category>
    `;
};

const control = function (isInitialSetup, isStage) {
    return `
    <category name="%{BKY_CATEGORY_CONTROL}" id="control" colour="#FFAB19" secondaryColour="#CF8B17" iconURI='${controlIcon}'>
        <block type="control_wait">
            <value name="DURATION">
                <shadow type="math_positive_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="control_repeat">
            <value name="TIMES">
                <shadow type="math_whole_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        <block id="forever" type="control_forever"/>
        ${blockSeparator}
        <block type="control_if"/>
        <block type="control_if_else"/>
        <block id="wait_until" type="control_wait_until"/>
        <block id="repeat_until" type="control_repeat_until"/>
        ${blockSeparator}
        <block type="control_stop"/>
        ${blockSeparator}
        ${isStage ? `
            <block type="control_create_clone_of">
                <value name="CLONE_OPTION">
                    <shadow type="control_create_clone_of_menu"/>
                </value>
            </block>
        ` : `
            <block type="control_start_as_clone"/>
            <block type="control_create_clone_of">
                <value name="CLONE_OPTION">
                    <shadow type="control_create_clone_of_menu"/>
                </value>
            </block>
            <block type="control_delete_this_clone"/>
        `}
        ${categorySeparator}
    </category>
    `;
};

const sensing = function (isInitialSetup, isStage) {
    const name = ScratchBlocks.ScratchMsgs.translate('SENSING_ASK_TEXT', 'What\'s your name?');
    return `
    <category name="%{BKY_CATEGORY_SENSING}" id="sensing" colour="#4CBFE6" secondaryColour="#2E8EB8" iconURI='${sensingIcon}'>
        ${isStage ? '' : `
            <block type="sensing_touchingobject">
                <value name="TOUCHINGOBJECTMENU">
                    <shadow type="sensing_touchingobjectmenu"/>
                </value>
            </block>
            <block type="sensing_touchingcolor">
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="sensing_coloristouchingcolor">
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
                <value name="COLOR2">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="sensing_distanceto">
                <value name="DISTANCETOMENU">
                    <shadow type="sensing_distancetomenu"/>
                </value>
            </block>
            ${blockSeparator}
        `}
        ${isInitialSetup ? '' : `
            <block id="askandwait" type="sensing_askandwait">
                <value name="QUESTION">
                    <shadow type="text">
                        <field name="TEXT">${name}</field>
                    </shadow>
                </value>
            </block>
        `}
        <block id="answer" type="sensing_answer"/>
        ${blockSeparator}
        <block type="sensing_photograph"/>
        <block type="sensing_collectUserObject"/>
        <block type="sensing_vote"/>
        ${blockSeparator}
        <block type="sensing_keypressed">
            <value name="KEY_OPTION">
                <shadow type="sensing_keyoptions"/>
            </value>
        </block>
        <block type="sensing_mousedown"/>
        <block type="sensing_mousex"/>
        <block type="sensing_mousey"/>
        ${isStage ? '' : `
            ${blockSeparator}
            '<block type="sensing_setdragmode" id="sensing_setdragmode"></block>'+
            ${blockSeparator}
        `}
        ${blockSeparator}
        <block id="loudness" type="sensing_loudness"/>
        ${blockSeparator}
        <block id="timer" type="sensing_timer"/>
        <block type="sensing_resettimer"/>
        ${blockSeparator}
        <block id="of" type="sensing_of">
            <value name="OBJECT">
                <shadow id="sensing_of_object_menu" type="sensing_of_object_menu"/>
            </value>
        </block>
        ${blockSeparator}
        <block id="current" type="sensing_current"/>
        <block type="sensing_dayssince2000"/>
        ${blockSeparator}
        <block type="sensing_username"/>
        ${categorySeparator}
    </category>
    `;
};

const operators = function (isInitialSetup) {
    const apple = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_APPLE', 'apple');
    const banana = ScratchBlocks.ScratchMsgs.translate('OPERATORS_JOIN_BANANA', 'banana');
    const letter = ScratchBlocks.ScratchMsgs.translate('OPERATORS_LETTEROF_APPLE', 'a');
    return `
    <category name="%{BKY_CATEGORY_OPERATORS}" id="operators" colour="#40BF4A" secondaryColour="#389438" iconURI='${operatorsIcon}' >
        <block type="operator_add">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_subtract">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_multiply">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_divide">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_random">
            <value name="FROM">
                <shadow type="math_number">
                    <field name="NUM">1</field>
                </shadow>
            </value>
            <value name="TO">
                <shadow type="math_number">
                    <field name="NUM">10</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_gt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_lt">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        <block type="operator_equals">
            <value name="OPERAND1">
                <shadow type="text">
                    <field name="TEXT"/>
                </shadow>
            </value>
            <value name="OPERAND2">
                <shadow type="text">
                    <field name="TEXT">50</field>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_and"/>
        <block type="operator_or"/>
        <block type="operator_not"/>
        ${blockSeparator}
        ${isInitialSetup ? '' : `
            <block type="operator_join">
                <value name="STRING1">
                    <shadow type="text">
                        <field name="TEXT">${apple} </field>
                    </shadow>
                </value>
                <value name="STRING2">
                    <shadow type="text">
                        <field name="TEXT">${banana}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_letter_of">
                <value name="LETTER">
                    <shadow type="math_whole_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_length">
                <value name="STRING">
                    <shadow type="text">
                        <field name="TEXT">${apple}</field>
                    </shadow>
                </value>
            </block>
            <block type="operator_contains" id="operator_contains">
              <value name="STRING1">
                <shadow type="text">
                  <field name="TEXT">${apple}</field>
                </shadow>
              </value>
              <value name="STRING2">
                <shadow type="text">
                  <field name="TEXT">${letter}</field>
                </shadow>
              </value>
            </block>
        `}
        ${blockSeparator}
        <block type="operator_mod">
            <value name="NUM1">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
            <value name="NUM2">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        <block type="operator_round">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${blockSeparator}
        <block type="operator_mathop">
            <value name="NUM">
                <shadow type="math_number">
                    <field name="NUM"/>
                </shadow>
            </value>
        </block>
        ${categorySeparator}
    </category>
    `;
};

const variables = function () {
    return `
    <category
        name="%{BKY_CATEGORY_VARIABLES}"
        iconURI='${variablesIcon}'
        id="variables"
        colour="#FF8C1A"
        secondaryColour="#DB6E00"
        custom="VARIABLE">
    </category>
    `;
};

const myBlocks = function () {
    return `
    <category
        name="%{BKY_CATEGORY_MYBLOCKS}"
        iconURI='${myblocksIcon}'
        id="myBlocks"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="PROCEDURE">
    </category>
    `;
};
const display = function () {
    return `
    <category
        name="%{BKY_CATEGORY_DISPLAY}"
        iconURI='${displayIcon}'
        id="display"
        colour="#FF6680"
        secondaryColour="#FF4D6A"
        custom="DISPLAY">
    </category>
    `;
};

// 模型
const model = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    const desc = ScratchBlocks.ScratchMsgs.translate('ENTER_DESCRIPTION_CONTENT', '请输入描述内容');
    const model_name = ScratchBlocks.ScratchMsgs.translate('ENTER_MODEL_NAME', '请输入模型名称');
    const compile = ScratchBlocks.ScratchMsgs.translate('COMPILE', '编译');
    const REASONING_DEPLOYMENT = ScratchBlocks.ScratchMsgs.translate('REASONING_DEPLOYMENT', '推理部署');
    const CLOUD_MODEL = ScratchBlocks.ScratchMsgs.translate('CLOUD_MODEL', '云模型');
    const PICTURE = ScratchBlocks.ScratchMsgs.translate('PICTURE', '图片');
    const ENTER_NAME = ScratchBlocks.ScratchMsgs.translate('ENTER_NAME', '请输入名称');
    const ENTER_TPU_MODEL_NAME = ScratchBlocks.ScratchMsgs.translate('ENTER_TPU_MODEL_NAME', '请输入TPU模型名称');
    return `
    <category name="%{BKY_MODEl}" id="model" color="#02D8D5" secondaryColour="#02D8D5" iconURI="${modelIcon}">
        <block type="model_set_assignment" style="display: none"></block>
        <block type="model_loop_train"></block>
        <block type="model_set_data">
            <field name="MODEL">${please}</field>
        </block>
        <block type="model_set_parameters">
            <value name="INITMODELS">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="model_resource_pool">
            <field name="RESOURCEPOOL">${please}</field>
            <field name="RESOURCESPEC">${please}</field>
        </block>
        <block type="model_incremental_training">
            <field name="CREATEMODEL">${please}</field>
        </block>
        <block type="model_run_time">
            <value name="RUNTIME">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="model_add_describe">
            <value name="DESCRIBE">
                <shadow type="text">
                    <field name="TEXT">${desc}</field>
                </shadow>
            </value>
        </block>
        <block type="model_add_model_name">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${model_name}</field>
                </shadow>
            </value>
        </block>
        <block type="model_training_model">
            <field name="CREATEMODEL">${please}</field>
        </block>
        <block type="model_train_status_var"></block>
        <block type="model_training_process"></block>
        <block type="model_not_finished_train"></block>
        <block type="model_stop_train"></block>
        <button text="%{BKY_VIEW_TRAINING_TASKS}" callbackKey="createtrain"></button>

        <label text=${compile}></label>
        <block type="model_loop_compile"></block>
        <block type="model_compile_set_parameters">
            <value name="INITMODELS">
                <shadow type="text">
                    <field name="TEXT">[128,128]</field>
                </shadow>
            </value>
        </block>
        <block type="model_compile_resource_pool">
            <field name="RESOURCEPOOL">${please}</field>
            <field name="COMPILERESOURCESPEC">${please}</field>
        </block>
        <block type="model_compiled_model">
            <field name="CREATETPUMODEL">${please}</field>
        </block>
        <block type="model_compile_status_var"></block>
        <block type="model_not_finished_compile"></block>
        <block type="model_stop_compile"></block>
        <button text="%{BKY_VIEW_COMPILATION_TASKS}" callbackKey="createcompile"></button>

        <label text=${REASONING_DEPLOYMENT}></label>
        <block type="model_loop_cloud">
            <field name="CREATECLOUDMODEL">${CLOUD_MODEL}</field>
        </block>
        <block type="model_cloud_resource_pool">
            <field name="RESOURCEPOOL">${please}</field>
            <field name="COLUDRESOURCESPEC">${please}</field>
        </block>
        <block type="model_cloud_status"></block>
        <block type="model_cloud_image_result">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${PICTURE}</field>
                </shadow>
            </value>
            <value name="SIZE">
                <shadow type="math_number">
                    <field name="NUM">640</field>
                </shadow>
            </value>
            <value name="CONFTHRES">
                <shadow type="math_number">
                    <field name="NUM">0.5</field>
                </shadow>
            </value>
            <value name="IOUTHRES">
                <shadow type="math_number">
                    <field name="NUM">0.45</field>
                </shadow>
            </value>
        </block>
        <block type="model_cloud_stop_task"></block>
        <button text="%{BKY_VIEW_INFERENCE_TASKS}" callbackKey="createreason"></button>

        <block type="model_welding_tpu">
            <value name="NEWNAME">
                <shadow type="text">
                    <field name="TEXT">${ENTER_NAME}</field>
                </shadow>
            </value>
            <value name="SHAPES">
                <shadow type="text">
                    <field name="TEXT">[640,640]</field>
                </shadow>
            </value>
        </block>
        <block type="model_image_inference_results">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${ENTER_TPU_MODEL_NAME}</field>
                </shadow>
            </value>
            <value name="SIZE">
                <shadow type="text">
                    <field name="TEXT">[640,640]</field>
                </shadow>
            </value>
            <value name="CONFTHRES">
                <shadow type="math_number">
                    <field name="NUM">0.5</field>
                </shadow>
            </value>
            <value name="IOUTHRES">
                <shadow type="math_number">
                    <field name="NUM">0.45</field>
                </shadow>
            </value>
        </block>
        <block type="model_video_inference_results">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${ENTER_TPU_MODEL_NAME}</field>
                </shadow>
            </value>
            <value name="SIZE">
                <shadow type="text">
                    <field name="TEXT">[640,640]</field>
                </shadow>
            </value>
            <value name="CONFTHRES">
                <shadow type="math_number">
                    <field name="NUM">0.5</field>
                </shadow>
            </value>
            <value name="IOUTHRES">
                <shadow type="math_number">
                    <field name="NUM">0.45</field>
                </shadow>
            </value>
        </block>
        <block type="model_download_model">
            <field name="MODELNAME">${please}</field>
        </block>
        <block type="model_result"></block>
        <block type="model_name_exist">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${model_name}</field>
                </shadow>
            </value>
        </block>
        <block type="model_clear_tpu_model"></block>
    </category>
    `;
    // <block type="model_download_model_statement">
    //     <field name="MODELNAME">${please}</field>
    // </block>
    // <block type="model_download_model">
    //     <field name="MODELNAME">${please}</field>
    // </block>
    // <block type="model_result"></block>
}

// 训练
{/* <block type="model_set_assignment">
    <field name="TRAINALGORITHM">${please}</field>
</block>
<block type="model_loop_train"></block> */}
{/* <value name="INITMODELS">
    <shadow type="math_number">
        <field name="NUM">0</field>
    </shadow>
</value> */}
const train = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    const desc = ScratchBlocks.ScratchMsgs.translate('ENTER_DESCRIPTION_CONTENT', '请输入描述内容');
    const model_name = ScratchBlocks.ScratchMsgs.translate('ENTER_MODEL_NAME', '请输入模型名称');
    return `
    <category name="%{BKY_TRAIN}" id="train" color="#EE91A0" secondaryColour="#EE91A0" iconURI="${trainIcon}">
        <button text="%{BKY_VIEW_TRAINING_TASKS}" callbackKey="createtrain"></button>
        <block type="model_train_categorize_model">
            <field name="TRAINALGORITHM">${please}</field>
        </block>
        <block type="model_set_data">
            <field name="MODEL">${please}</field>
        </block>
        <block type="model_set_parameters">
            <field name="TRAINPARAMETERS">${please}</field>
            <value name="INITMODELS">
                <shadow type="text">
                    <field name="TEXT">0</field>
                </shadow>
            </value>
        </block>
        <block type="model_resource_pool">
            <field name="RESOURCEPOOL">${please}</field>
            <field name="RESOURCESPEC">${please}</field>
        </block>
        <block type="model_incremental_training">
            <field name="CREATEMODEL">${please}</field>
        </block>
        <block type="model_run_time">
            <value name="RUNTIME">
                <shadow type="math_number">
                    <field name="NUM">0</field>
                </shadow>
            </value>
        </block>
        <block type="model_add_describe">
            <value name="DESCRIBE">
                <shadow type="text">
                    <field name="TEXT">${desc}</field>
                </shadow>
            </value>
        </block>
        <block type="model_add_model_name">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${model_name}</field>
                </shadow>
            </value>
        </block>
        <block type="model_training_model">
            <field name="CREATEMODEL">${please}</field>
        </block>
        <block type="model_train_status_var"></block>
        <block type="model_training_process"></block>
        <block type="model_not_finished_train"></block>
        <block type="model_stop_train"></block>
    </category>
    `
}

// 编译
// 隐藏
// <block type="model_loop_compile"></block>
{/* <block type="model_set_compile_assignment">
    <field name="COMPILEALGORITHM">${please}</field>
</block> */}
const compile = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    return `
    <category name="%{BKY_COMPILE}" id="compile" color="#3271AE" secondaryColour="#3271AE" iconURI="${compileIcon}">
        <button text="%{BKY_VIEW_COMPILATION_TASKS}" callbackKey="createcompile"></button>
        <block type="model_compilation_model">
            <field name="COMPILEALGORITHM">${please}</field>
        </block>
        <block type="model_compile_set_parameters">
            <value name="INITMODELS">
                <shadow type="text">
                    <field name="TEXT">[640,640]</field>
                </shadow>
            </value>
        </block>
        <block type="model_compile_resource_pool">
            <field name="RESOURCEPOOL">${please}</field>
            <field name="COMPILERESOURCESPEC">${please}</field>
        </block>
        <block type="model_compiled_model">
            <field name="CREATETPUMODEL">${please}</field>
        </block>
        <block type="model_compile_status_var"></block>
        <block type="model_not_finished_compile"></block>
        <block type="model_stop_compile"></block>
    </category>
    `
}

// 推理
{/* <block type="model_cloud_image_result">
    <value name="CLOUDIMAGERESULTVARIABLE"></value>
    <value name="MODELNAME">
        <shadow type="text">
            <field name="TEXT">${PICTURE}</field>
        </shadow>
    </value>
    <value name="SIZE">
        <shadow type="math_number">
            <field name="NUM">640</field>
        </shadow>
    </value>
    <value name="CONFTHRES">
        <shadow type="math_number">
            <field name="NUM">0.5</field>
        </shadow>
    </value>
    <value name="IOUTHRES">
        <shadow type="math_number">
            <field name="NUM">0.45</field>
        </shadow>
    </value>
</block> */}
const reasoning = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    // const CLOUD_MODEL = ScratchBlocks.ScratchMsgs.translate('CLOUD_MODEL', '云模型');
    const PICTURE = ScratchBlocks.ScratchMsgs.translate('PICTURE', '图片');
    const model_variable = ScratchBlocks.ScratchMsgs.translate('MODEL_VARIABLES', '模型变量');
    const image_variable = ScratchBlocks.ScratchMsgs.translate('IMAGE_VARIABLES', '图片变量');
    const result = ScratchBlocks.ScratchMsgs.translate('INFERENCE_RESULTS', '推理结果');
    // <block type="model_loop_cloud">
    //     <field name="CREATECLOUDMODEL">${CLOUD_MODEL}</field>
    // </block>
    // <value name="CREATECLOUDMODEL">
    //      <shadow type="model_cloud_menu">
    //          <field name="CREATECLOUDMODEL">${CLOUD_MODEL}</field>
    //      </shadow>
    //  </value>
    return `
    <category name="%{BKY_REASONING}" id="reasoning" color="#ABCB52" secondaryColour="#ABCB52" iconURI="${reasoningIcon}">
        <button text="%{BKY_VIEW_INFERENCE_TASKS}" callbackKey="createreason"></button>
        <block type="model_loop_cloud"></block>
        <block type="model_cloud_resource_pool">
            <field name="RESOURCEPOOL">${please}</field>
            <field name="COLUDRESOURCESPEC">${please}</field>
        </block>
        <block type="model_cloud_parameters">
            <field name="CLOUDPARAMETERS">${please}</field>
            <value name="INITMODELS">
                <shadow type="text">
                    <field name="TEXT">0</field>
                </shadow>
            </value>
        </block>
        <block type="model_cloud_status"></block>
        <block type="model_cloud_stop_task"></block>
        <block type="model_cloud_reason">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${model_variable}</field>
                </shadow>
            </value>
            <value name="CLOUDIMAGERESULTVARIABLE">
                <shadow type="text">
                    <field name="TEXT">${image_variable}</field>
                </shadow>
            </value>
            <value name="CLOUDREASONRESULT">
                <shadow type="text">
                    <field name="TEXT">${result}</field>
                </shadow>
            </value>
        </block>
        <block type="model_cloud_stop_all_task"></block>
        <block type="model_tag_name"></block>
    </category>
    `
}

// TPU
// 隐藏
{/* <block type="model_welding_tpu">
    <value name="NEWNAME">
        <shadow type="text">
            <field name="TEXT">${ENTER_NAME}</field>
        </shadow>
    </value>
    <value name="SHAPES">
        <shadow type="text">
            <field name="TEXT">[640,640]</field>
        </shadow>
    </value>
</block> */}
{/* <block type="model_image_inference_results">
    <value name="MODELNAME">
        <shadow type="text">
            <field name="TEXT">${ENTER_TPU_MODEL_NAME}</field>
        </shadow>
    </value>
    <value name="SIZE">
        <shadow type="text">
            <field name="TEXT">[640,640]</field>
        </shadow>
    </value>
    <value name="CONFTHRES">
        <shadow type="math_number">
            <field name="NUM">0.5</field>
        </shadow>
    </value>
    <value name="IOUTHRES">
        <shadow type="math_number">
            <field name="NUM">0.45</field>
        </shadow>
    </value>
</block> */}
{/* <block type="model_video_inference_results">
    <value name="MODELNAME">
        <shadow type="text">
            <field name="TEXT">${ENTER_TPU_MODEL_NAME}</field>
        </shadow>
    </value>
    <value name="SIZE">
        <shadow type="text">
            <field name="TEXT">[640,640]</field>
        </shadow>
    </value>
    <value name="CONFTHRES">
        <shadow type="math_number">
            <field name="NUM">0.5</field>
        </shadow>
    </value>
    <value name="IOUTHRES">
        <shadow type="math_number">
            <field name="NUM">0.45</field>
        </shadow>
    </value>
</block> */}
{/* <block type="model_download_model">
    <value name="MODELNAME">
        <shadow type="model_download_model_menu">
            <field name="MODELNAME">${please}</field>
        </shadow>
    </value>
</block> */}
{/* <block type="model_result"></block> */}
const tpu = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    // const ENTER_NAME = ScratchBlocks.ScratchMsgs.translate('ENTER_NAME', '请输入名称');
    // const ENTER_TPU_MODEL_NAME = ScratchBlocks.ScratchMsgs.translate('ENTER_TPU_MODEL_NAME', '请输入TPU模型名称');
    const entry_model_name = ScratchBlocks.ScratchMsgs.translate('ENTER_MODEL_NAME', '请输入模型名称');
    const model_name = ScratchBlocks.ScratchMsgs.translate('MODEL_NAME', '模型名称');
    const model_variable = ScratchBlocks.ScratchMsgs.translate('MODEL_VARIABLES', '模型变量');
    const image_variable = ScratchBlocks.ScratchMsgs.translate('IMAGE_VARIABLES', '图片变量');
    const result = ScratchBlocks.ScratchMsgs.translate('INFERENCE_RESULTS', '推理结果');
    const dowload_result = ScratchBlocks.ScratchMsgs.translate('DOWNLOAD_STATUS', '下载状态')
    return `
    <category name="%{BKY_TPU}" id="tpu" color="#AF52B5" secondaryColour="#AF52B5" iconURI="${tpuIcon}">
        <block type="model_tpu_download">
            <value name="TPUMODELVARIABLE">
                <shadow type="text">
                    <field name="TEXT">${model_variable}</field>
                </shadow>
            </value>
            <value name="TPUMODELNAME">
                <shadow type="text">
                    <field name="TEXT">${model_name}</field>
                </shadow>
            </value>
            <value name="TPUDOWNLOADRESULT">
                <shadow type="text">
                    <field name="TEXT">${dowload_result}</field>
                </shadow>
            </value>
        </block>
        <block type="model_tpu_reason">
            <value name="MODELNAME">
                <shadow type="model_download_model_menu">
                    <field name="MODELNAME">${please}</field>
                </shadow>
            </value>
            <value name="TPUREASONTVARIABLE">
                <shadow type="text">
                    <field name="TEXT">${image_variable}</field>
                </shadow>
            </value>
            <value name="TPUREASONRESULT">
                <shadow type="text">
                    <field name="TEXT">${result}</field>
                </shadow>
            </value>
        </block>
        <block type="model_tpu_parameters">
            <field name="TPUPARAMETERS">${please}</field>
            <value name="INITMODELS">
                <shadow type="text">
                    <field name="TEXT">0</field>
                </shadow>
            </value>
        </block>
        <block type="model_name_exist">
            <value name="MODELNAME">
                <shadow type="text">
                    <field name="TEXT">${entry_model_name}</field>
                </shadow>
            </value>
        </block>
        <block type="model_clear_tpu_model"></block>
    </category>
    `
}

// 显示器
const monitor = function(isInitialSetup, targetId, isMonitor) {
    // let arr = localStorage.getItem('sprite') ? JSON.parse(localStorage.getItem('sprite')) : []
    // let show;
    // if (arr.length != 0) {
    //     let index = arr.findIndex(item => item === targetId)
    //     show = index != -1 ? true : false
    // } else {
    //     show = false
    // }
    const PICTURE = ScratchBlocks.ScratchMsgs.translate('PICTURE', '图片');
    const VIDEO = ScratchBlocks.ScratchMsgs.translate('VIDEO', '视频');
    return `
    <category name="%{BKY_MONITOR}" id="monitor" color="#DF6138" secondaryColour="#DF6138" iconURI="${monitorIcon}">
        <button text="%{BKY_CREATE_DISPLAY}" callbackKey="createsprites"></button>    
        ${
            isMonitor ? `
            <block type="monitor_insert_rect_to_sprite">
                <value name="X0">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
                <value name="Y0">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
                <value name="X1">
                    <shadow type="math_number">
                        <field name="NUM">100</field>
                    </shadow>
                </value>
                <value name="Y1">
                    <shadow type="math_number">
                        <field name="NUM">100</field>
                    </shadow>
                </value>
                <value name="STORKEWID">
                    <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="monitor_set_image_size">
                <value name="IMAGE_VALUE">
                    <shadow type="text">
                        <field name="TEXT">${PICTURE}</field>
                    </shadow>
                </value>
                <value name="WIDTH">
                    <shadow type="math_number">
                        <field name="NUM">640</field>
                    </shadow>
                </value>
                <value name="HEIGHT">
                    <shadow type="math_number">
                        <field name="NUM">640</field>
                    </shadow>
                </value>
            </block>
            <block type="monitor_insert_rect_to_sprites">
                <value name="IMAGEDATA">
                    <shadow type="text">
                        <field name="TEXT">${PICTURE}</field>
                    </shadow>
                </value>
                <value name="X0">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
                <value name="Y0">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
                <value name="X1">
                    <shadow type="math_number">
                        <field name="NUM">100</field>
                    </shadow>
                </value>
                <value name="Y1">
                    <shadow type="math_number">
                        <field name="NUM">100</field>
                    </shadow>
                </value>
                <value name="STORKEWID">
                    <shadow type="math_number">
                        <field name="NUM">1</field>
                    </shadow>
                </value>
                <value name="COLOR">
                    <shadow type="colour_picker"/>
                </value>
            </block>
            <block type="monitor_insert_image_to_sprite">
                <value name="IMAGE">
                    <shadow type="text">
                        <field name="TEXT">${PICTURE}</field>
                    </shadow>
                </value>
                <value name="WIDTH">
                    <shadow type="math_number">
                        <field name="NUM">480</field>
                    </shadow>
                </value>
                <value name="HEIGTH">
                    <shadow type="math_number">
                        <field name="NUM">360</field>
                    </shadow>
                </value>
                <value name="DX">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
                <value name="DY">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
            </block>
            <block type="monitor_insert_video_to_sprite">
                <value name="VIDEO">
                    <shadow type="text">
                        <field name="TEXT">${VIDEO}</field>
                    </shadow>
                </value>
                <value name="WIDTH">
                    <shadow type="math_number">
                        <field name="NUM">480</field>
                    </shadow>
                </value>
                <value name="HEIGTH">
                    <shadow type="math_number">
                        <field name="NUM">360</field>
                    </shadow>
                </value>
                <value name="DX">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
                <value name="DY">
                    <shadow type="math_number">
                        <field name="NUM">0</field>
                    </shadow>
                </value>
            </block>
            <block type="monitor_clear_sprite"></block>
            ` : ''
        }
    </category>
    `
}

// 新模型
const model_extend = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    return `
    <category name="%{BKY_MODEL_EXTEND}" id="model_extend" color="#02D8D5" secondaryColour="#02D8D5" iconURI="${modelIcon}">
        <button text="%{BKY_CREATE_MODEL}" callbackKey="createModel"></button>
        <button text="%{BKY_TRAIN_MODEL}" callbackKey="trainModel"></button>
        <button text="%{BKY_COMPILE_MODEL}" callbackKey="compileModel"></button>

        <block type="model_extend_variable">
            <field name="MODELEXTENDVARIABLE">${please}</field>
        </block>
    </category>
    `
}

// 数据集
const data_set = function(isInitialSetup) {
    const please = ScratchBlocks.ScratchMsgs.translate('PLEASE_SELECT', '请选择');
    return `
    <category name="%{BKY_DATA_SET}" id="data_set" color="#53D179" secondaryColour="#53D179" iconURI="${dataSetIcon}">
        <button text="%{BKY_VIEW_DATA_ENGINEERING}" callbackKey="dataEngineering"></button>
        <block type="model_data_set">
            <field name="MODEL">${please}</field>
        </block>
    </category>
    `
}

/* eslint-enable no-unused-vars */

const xmlOpen = '<xml style="display: none">';
const xmlClose = '</xml>';

/**
 * @param {!boolean} isInitialSetup - Whether the toolbox is for initial setup. If the mode is "initial setup",
 * blocks with localized default parameters (e.g. ask and wait) should not be loaded. (LLK/scratch-gui#5445)
 * @param {?boolean} isStage - Whether the toolbox is for a stage-type target. This is always set to true
 * when isInitialSetup is true.
 * @param {?string} targetId - The current editing target
 * @param {?Array.<object>} categoriesXML - optional array of `{id,xml}` for categories. This can include both core
 * and other extensions: core extensions will be placed in the normal Scratch order; others will go at the bottom.
 * @property {string} id - the extension / category ID.
 * @property {string} xml - the `<category>...</category>` XML for this extension / category.
 * @param {?string} costumeName - The name of the default selected costume dropdown.
 * @param {?string} backdropName - The name of the default selected backdrop dropdown.
 * @param {?string} soundName -  The name of the default selected sound dropdown.
 * @returns {string} - a ScratchBlocks-style XML document for the contents of the toolbox.
 * @param {boolean} - 判断显示屏
 */
const makeToolboxXML = function (isInitialSetup, isStage = true, targetId, categoriesXML = [],
    costumeName = '', backdropName = '', soundName = '', isMonitor = false) {
    isStage = isInitialSetup || isStage;
    const gap = [categorySeparator];

    costumeName = xmlEscape(costumeName);
    backdropName = xmlEscape(backdropName);
    soundName = xmlEscape(soundName);

    categoriesXML = categoriesXML.slice();
    const moveCategory = categoryId => {
        const index = categoriesXML.findIndex(categoryInfo => categoryInfo.id === categoryId);
        if (index >= 0) {
            // remove the category from categoriesXML and return its XML
            const [categoryInfo] = categoriesXML.splice(index, 1);
            return categoryInfo.xml;
        }
        // return `undefined`
    };
    const motionXML = moveCategory('motion') || motion(isInitialSetup, isStage, targetId);
    const looksXML = moveCategory('looks') || looks(isInitialSetup, isStage, targetId, costumeName, backdropName);
    const soundXML = moveCategory('sound') || sound(isInitialSetup, isStage, targetId, soundName);
    const eventsXML = moveCategory('event') || events(isInitialSetup, isStage, targetId);
    const controlXML = moveCategory('control') || control(isInitialSetup, isStage, targetId);
    const sensingXML = moveCategory('sensing') || sensing(isInitialSetup, isStage, targetId);
    const operatorsXML = moveCategory('operators') || operators(isInitialSetup, isStage, targetId);
    const variablesXML = moveCategory('data') || variables(isInitialSetup, isStage, targetId);
    const myBlocksXML = moveCategory('procedures') || myBlocks(isInitialSetup, isStage, targetId);
    // const modelXML = moveCategory('model') || model(isInitialSetup)
    const trainXML = moveCategory('train') || train(isInitialSetup)
    const compileXML = moveCategory('compile') || compile(isInitialSetup)
    const reasoningXML = moveCategory('reasoning') || reasoning(isInitialSetup)
    const tpuXML = moveCategory('tpu') || tpu(isInitialSetup)
    const monitorXML = moveCategory('monitor') || monitor(isInitialSetup, targetId, isMonitor)
    const modelExtendXML = moveCategory('model_extend') || model_extend(isInitialSetup)
    const dataSetXMl = moveCategory('data_set') || data_set(isInitialSetup)
    // const displayXML = moveCategory('display') || display(isInitialSetup, isStage, targetId);

    const everything = [
        xmlOpen,
        motionXML, gap,
        looksXML, gap,
        soundXML, gap,
        eventsXML, gap,
        controlXML, gap,
        sensingXML, gap,
        operatorsXML, gap,
        variablesXML, gap,
        myBlocksXML, gap,
        // modelXML, gap,
        dataSetXMl,
        modelExtendXML,
        trainXML,
        compileXML,
        reasoningXML,
        tpuXML,
        monitorXML
        // displayXML
    ];

    for (const extensionCategory of categoriesXML) {
        everything.push(gap, extensionCategory.xml);
    }

    everything.push(xmlClose);
    return everything.join('\n');
};

export default makeToolboxXML;
