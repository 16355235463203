const SELECTED_INDEX ='selectedIndex'

// initial state
const initState = {
    selected_index: 0
}

// reducer
const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch(action.type) {
        case SELECTED_INDEX: 
            return Object.assign({}, state, {
                selected_index: action.param
            })
        default:
            return state
    }
}

const selectIndex = (state) => ({
    type: SELECTED_INDEX,
    param: state
})

export {
    reducer as default,
    initState as panelInitState,
    selectIndex
}