import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    camera: {
        id: 'gui.takePhoto.camera',
        description: '摄像头',
        defaultMessage: '摄像头'
    },
    retake: {
        id: 'gui.takePhoto.retake',
        description: '重拍',
        defaultMessage: '重拍'
    },
    photograph: {
        id: 'gui.takePhoto.photograph',
        description: '拍照',
        defaultMessage: '拍照'
    },
    ok: {
        id: 'gui.prompt.ok',
        description: '确定',
        defaultMessage: '确定'
    },
    upload: {
        id: 'gui.takePhoto.upload',
        description: '上传',
        defaultMessage: '上传'
    },
    noCamera: {
        id: 'gui.takePhoto.noCamera',
        description: '无摄像头可以使用',
        defaultMessage: '无摄像头可以使用'
    },
    cameraError: {
        id: 'gui.takePhoto.cameraError',
        description: '拍照错误，请检查摄像头',
        defaultMessage: '拍照错误，请检查摄像头'
    },
    uploadError: {
        id: 'gui.takePhoto.uploadError',
        description: '格式错误,请上传jpg、png格式的文件',
        defaultMessage: '格式错误,请上传jpg、png格式的文件'
    },
    enterIp: {
        id: 'gui.takePhoto.enterIp',
        description: '请输入ip地址',
        defaultMessage: '请输入ip地址'
    },
    incorrectIpAddress: {
        id: 'gui.takePhoto.incorrectIpAddress',
        description: 'ip地址格式不正确',
        defaultMessage: 'ip地址格式不正确'
    },
    ipAddressDuplication: {
        id: 'gui.takePhoto.ipAddressDuplication',
        description: 'ip地址重复',
        defaultMessage: 'ip地址重复'
    },
    delete: {
        id: 'gui.soundEditor.delete',
        description: '删除',
        defaultMessage: '删除'
    },
    pleaseTakePhoto: {
        id: 'gui.createModel.pleaseTakePhoto',
        description: '请拍照',
        defaultMessage: '请拍照'
    },
    entryNameOrAdd: {
        id: 'gui.takePhoto.entryNameOrAdd',
        description: '请填写姓名和地址',
        defaultMessage: '请填写姓名和地址'
    },
    informationCollection: {
        id: 'gui.takePhoto.informationCollection',
        description: '信息采集',
        defaultMessage: '信息采集'
    },
    username: {
        id: 'gui.opcodeLabels.username',
        description: '用户名',
        defaultMessage: '用户名'
    },
    address: {
        id: 'gui.takePhoto.address',
        description: '地址',
        defaultMessage: '地址'
    }
})

export default LANG_MESSAGE