const axios = require('axios').default;

// const {removeToken} = require('../reducers/login')

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const service = axios.create({
    // axios 请求中 URL 的公共部分
    baseURL: ON_LINE_URL + '/codeapi',
    // 设置请求超时时间
    timeout: 10 * 1000
})

// 请求拦截器
service.interceptors.request.use(config => {
    let token = localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : ''
    config.headers.Authorization = token
    if (sessionStorage.getItem('space')) {
        config.headers['Octopus-Space-Id'] = JSON.parse(sessionStorage.getItem('space')).workspaceId
    }
    return config
})

// 响应拦截器
service.interceptors.response.use(res => {
    if(res.headers['content-type'] === 'text/plain') return res.data

    if (
        !res?.data?.success && 
        (res.data.error.subcode === 16004 || res.data.error.subcode === 16010 || res.data.error.subcode === 16007) 
    ) {
        window.current_msg.isLogin = new Date()
        console.log('token 错误')
    }

    if (!res?.data?.success) {
        window.globalMsg.tips = {
            visible: true,
            content: res.data.error.subMessage,
            type: 'fail',
            data: new Date()
        }
    }

    return res.data
}, error => {
    window.globalMsg.tips = {
        visible: true,
        content: error.message,
        type: 'fail',
        data: new Date()
    }
    return Promise.reject(error);
})

module.exports = service;