import React from 'react';
import Quill from 'quill';
import bindAll from 'lodash.bindall';
import {BitmapAdapter} from 'scratch-svg-renderer';
import {defineMessages, intlShape} from 'react-intl';

import bmpConverter from '../../lib/bmp-converter';
import gifDecoder from '../../lib/gif-decoder';
import { uploadWork } from '../../api/manage'

import { getConfigKey } from '../../api/manage.js'

const messages = defineMessages({
    inputTaskRequirements: {
        id: 'gui.quillContainer.inputTaskRequirements',
        description: 'Label for the name of the inputTaskRequirements',
        defaultMessage: 'inputTaskRequirements'
    },
    noRequirements: {
        id: 'gui.quillContainer.noRequirements',
        description: 'Label for the name of the noRequirements',
        defaultMessage: 'noRequirements'
    },
    noLink: {
        id: 'gui.quillContainer.noLink',
        description: 'Label for the name of the noLink',
        defaultMessage: 'noLink'
    }
})

class QuillContainer extends React.Component {
    constructor(props) {
        super(props)
        this.quill = null
        this.guide_quill = null
        this.state = {
            remark: ''
        }
        bindAll(this, [
            'selectImage',
            'extractFileName',
            'costumeUpload',
            'setRemark',
            'setStaticImg'
        ])
    }

    componentDidMount() {
        // 导航映射关系
        // let toolbarOptions = [
        //     ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        //     ['blockquote', 'code-block'],
        //     [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        //     [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //     [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //     [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //     [{ 'direction': 'rtl' }],                         // text direction
        //     [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        //     [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //     [{ 'font': [] }],
        //     [{ 'align': [] }],
        //     ['clean']                                         // remove formatting button
        // ];
        let config = {
            modules: {
                // toolbar: toolbarOptions,
                toolbar: this.props.nav ? '#toolbar' : '',
            },
            placeholder: this.props.nav ? this.props.intl.formatMessage(messages.inputTaskRequirements) : this.props.intl.formatMessage(messages.noRequirements), // 默认提示内容
            strict: true, // 不跟随新版本更改
            theme: 'snow', // 主题
            readOnly: this.props.readOnly, // 是否将编辑器实例设置为只读模式
        }
        let id = '#' + (this.props.editorId || 'quill-editor')
        if (this.props.type === 'work') {
            this.quill = new Quill(id, config)
            // 监听change事件，保存当前的文本内容
            this.quill.on('text-change', (e) => {
                if (this.props.onGetContext) {
                    let content = this.quill.getContents()
                    this.props.onGetContext(content)
                    this.props.vm.runtime.setWorkRichText(content.ops)

                    // 方案二
                    // let content_map = JSON.parse(JSON.stringify(content))
                    // content_map.ops.forEach((item, index) => {
                    //     if (item?.insert && item?.insert instanceof Object && item?.insert?.image) {
                    //         item.insert.image = item.insert.image.split(',')[1].substr(0, 12)
                    //     }
                    // })
                    // console.log(content_map, content)
                    // 提交虚拟数据供接口保存（指对真实数据的映射，区别在于映射数据不会有base64，真实数据会有base64）
                    // this.props.onGetContext(content_map)
                    // this.props.vm.runtime.setRichContent(content.ops, content_map.ops)
                    // this.quill.setSelection(this.quill.getLength(), this.quill.getLength() + 1)
                }
            })

            // 自定义图片
            if (document.querySelector('.quill-upload-image')) {
                let quillUploadImage = document.querySelector('.quill-upload-image')
                quillUploadImage.addEventListener('click', (e) => document.getElementsByName('quill-editor')[0].click())
            }
        } else if (this.props.type === 'guide') {
            this.guide_quill = new Quill(id, config)
            this.guide_quill.on('text-change', (e) => {
                if (this.props.onGetContext) {
                    let content = this.guide_quill.getContents()
                    this.props.onGetContext(content)
                    this.props.vm.runtime.setGuideTextContent(content)
                }
            })

            // 自定义图片
            if (document.querySelector('.quill-upload-image')) {
                let quillUploadImage = document.querySelector('.quill-upload-image')
                quillUploadImage.addEventListener('click', (e) => document.getElementsByName('guide-quill-editor')[0].click())
            }
        }
        
        this.setRemark(this.props.remark)

        // 判断是否是学生，学生不显示菜单栏
        if (!this.props.nav) {
            document.getElementById('quill-editor').style.border = 'none'
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.remark !== this.state.remark) {
            this.setRemark(prevProps.remark)
        }
    }

    /* 本地文件操作 */
    async selectImage(e) {
        let data = Array.from(e.target.files).filter((item) => item.type.startsWith('image/'))
        let formData = new FormData()
        for(let i = 0; i < data.length; i++) {
            let file = data[i]

            if (file.type.startsWith('image/')) {
                // 方案一
                if (this.props.type === 'work') {
                    formData.append('path', '/richImg')
                    formData.append('file', file)
                    let response = await uploadWork(formData)
                    if (response.code === 200) {
                        // 获取富文本光标位置
                        let index;
                        let rang = this.quill.getSelection()
                        // let url = `${ON_LINE_URL || 'http://192.168.1.243:8088'}${response.path}`
                        let urlResponse = await getConfigKey('fileurl')
                        let url;
                        if (urlResponse.code === 200) {
                            url = urlResponse.msg + response.path
                        } else {
                            this.props.onChangeMessage({
                                visible: true,
                                content: this.props.intl.formatMessage(messages.noLink),
                                type: 'fail'
                            })
                            return false
                        }
                        
                        if (rang != null) {
                            index = rang.index
                        } else {
                            index = this.quill.getLength()
                        }
                        this.quill.insertEmbed(index, 'image', url)
                        this.setStaticImg(file)
                    }
                }

                // 方案二
                if (this.props.type === 'guide') {
                    let blob = new Blob([file])
                    let reader = new FileReader()
                    reader.onload = (e) => {
                        // console.log(reader.result, e, file.name, file.type)
                        // 获取富文本光标位置
                        let index;
                        let rang = this.guide_quill.getSelection()
                        if (rang != null) {
                            index = rang.index
                        } else {
                            index = this.guide_quill.getLength()
                        }
                        this.guide_quill.insertEmbed(index, 'image', reader.result)
                    }
                    reader.readAsDataURL(blob)
                }
            }
        }
    }

    extractFileName = function (nameExt) {
        // There could be multiple dots, but get the stuff before the first .
        const nameParts = nameExt.split('.', 1); // we only care about the first .
        return nameParts[0];
    }

    setRemark(remark) {
        this.setState({
            remark: remark
        })

        try {
            let obj = JSON.parse(remark)
            if (this.props.type === 'work') {
                this.quill.setContents(obj.ops)
                this.props.vm.runtime.setWorkRichText(obj.ops)
            }
            if (this.props.type === 'guide') {
                this.guide_quill.setContents(obj.ops)
                this.props.vm.runtime.setGuideTextContent(obj)
            }
        } catch(e) {
            let arr = [{
                insert: remark || ''
            }]
            if (this.props.type === 'work') {
                this.quill.setContents(arr)
                this.props.vm.runtime.setWorkRichText(arr)
            }
            if (this.props.type === 'guide') {
                this.guide_quill.setContents(arr)
                this.props.vm.runtime.setGuideTextContent({ops: arr})
            }
        }
    }

    setStaticImg(file) {
        let reader = new FileReader()
        reader.onload = () => {
            let fileType = file.type
            let fileName = this.extractFileName(file.name)
            this.costumeUpload(reader.result, fileType, this.props.vm.runtime.storage)
        }
        reader.readAsArrayBuffer(file)
    }

    costumeUpload(fileData, fileType, storage) {
        let costumeFormat = null
        let assetType = null

        switch(fileType) {
            case 'image/svg+xml': {
                costumeFormat = storage.DataFormat.SVG;
                assetType = storage.AssetType.ImageVector;
                break;
            }
            case 'image/jpeg': {
                costumeFormat = storage.DataFormat.JPG;
                assetType = storage.AssetType.ImageBitmap;
                break;
            }
            case 'image/bmp': {
                // Convert .bmp files to .png to compress them. .bmps are completely uncompressed,
                // and would otherwise take up a lot of storage space and take much longer to upload and download.
                bmpConverter(fileData).then(dataUrl => {
                    costumeUpload(dataUrl, 'image/png', storage);
                });
                return; // Return early because we're triggering another proper costumeUpload
            }
            case 'image/png': {
                costumeFormat = storage.DataFormat.PNG;
                assetType = storage.AssetType.ImageBitmap;
                break;
            }
            case 'image/gif': {
                let costumes = [];
                gifDecoder(fileData, (frameNumber, dataUrl, numFrames) => {
                    costumeUpload(dataUrl, 'image/png', storage, costumes_ => {
                        costumes = costumes.concat(costumes_);
                        if (frameNumber === numFrames - 1) {
                            handleCostume(costumes);
                        }
                    });
                });
                return; // Abandon this load, do not try to load gif itself
            }
            default:
                return false;      
        }

        let bitmapAdapter = new BitmapAdapter()
        bitmapAdapter.importBitmap(fileData, fileType)
            .then((response) => {
                let asset = storage.createAsset(
                    assetType,
                    costumeFormat,
                    response,
                    null,
                    true
                )

                let img_data = {
                    fileName: `${asset.assetId}.${asset.dataFormat}`,
                    fileContent: asset.data
                }
                this.props.vm.runtime.setWorkRichtImg(img_data)
            })
    }

    render() {
        return (
            <div>
                {this.props.nav ? (
                    <div id="toolbar">
                        <select className="ql-size" >
                            <option value="small"></option>
                            <option></option>
                            <option value="large"></option>
                            <option value="huge"></option>
                        </select>
                        <select className="ql-header">
                            <option value="1"></option>
                            <option value="2"></option>
                            <option value="3"></option>
                            <option value="4"></option>
                            <option value="5"></option>
                            <option value="6"></option>
                            <option></option>
                        </select>

                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                        <button className="ql-strike"></button>

                        <select className="ql-color"></select>
                        <select className="ql-background"></select>

                        {/* <button className="ql-image"></button> */}
                        <button className="quill-upload-image">
                            <svg viewBox="0 0 18 18">
                                <rect className="ql-stroke" height="10" width="12" x="3" y="4"></rect>
                                <circle className="ql-fill" cx="6" cy="7" r="1"></circle>
                                <polyline className="ql-even ql-fill" points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"></polyline>
                            </svg>
                            {/* multiple="multiple" */}
                            <input name={this.props.editorId || 'quill-editor'} id="quill-upload" style={{display: 'none'}} type="file" accept=".jpg, .png, .gif, .bmp, .svg, .webp" onChange={this.selectImage} />
                        </button>

                        <button className="ql-clean"></button>
                        {/* <button className="ql-script" value="sub"></button>
                        <button className="ql-script" value="super"></button> */}
                    </div>
                ) : null}
                {!this.props.editorId ? (<div id="quill-editor"></div>) : null}
            </div>
        )
    }
}

export default QuillContainer
