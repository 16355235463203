import set from './icon_set.png'
import setActive from './icon_set_active.png'
import train from './icon_train.png'
import trainActive from './icon_train_active.png'
import compile from './icon_compile.png'
import compileActive from './icon_compile_active.png'
import reason from './icon_reason.png'
import reasonActive from './icon_reason_active.png'
import data from './icon_data.png'
import dataActive from './icon_data_active.png'
import trainParame from './icon-train-parame.png'
import trainParameActive from './icon-train-parame-act.png'
import mode from './icon_mode.png'
import modeActive from './icon_mode_active.png'
import close from '../../../../static/assets/icon_close.png'
import refresh from '../../../../static/assets/icon_refresh.png'
import upload from '../../../../static/assets/icon_upload.png'
import noCheck from '../../../../static/assets/icon_no_check.png'
import check from  '../../../../static/assets/icon_check.png'
import search from '../../../../static/assets/icon_search.png'

const IMG_CONTAINER = {
    set,
    setActive,
    train,
    trainActive,
    compile,
    compileActive,
    reason,
    reasonActive,
    data,
    dataActive,
    trainParame,
    trainParameActive,
    mode,
    modeActive,
    close,
    refresh,
    upload,
    noCheck,
    check,
    search
}

export default IMG_CONTAINER
