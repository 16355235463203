import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import VM from 'scratch-vm';
import {defineMessages, FormattedMessage} from 'react-intl';
import {STAGE_SIZE_MODES} from '../lib/layout-constants';
import {setStageSize} from '../reducers/stage-size';
import {setFullScreen} from '../reducers/mode';
import {
    setStageNativeSize,
} from '../reducers/stage-size';
import {resetAll} from '../reducers/code-status.js'

import {connect} from 'react-redux';

import {getTrainStatus, getCompileStatus, getCloudStatus} from '../api/code.js'
import StageHeaderComponent from '../components/stage-header/stage-header.jsx';

const messages = defineMessages({
    trainingInitialization: {
        id: 'gui.stageHeader.trainingInitialization',
        description: '训练初始化中',
        defaultMessage: '训练初始化中'
    },
    compileInitialization: {
        id: 'gui.stageHeader.compileInitialization',
        description: '编译初始化中',
        defaultMessage: '编译初始化中'
    },
    trainingWaiting: {
        id: 'gui.stageHeader.trainingWaiting',
        description: '训练等待中',
        defaultMessage: '训练等待中'
    },
    compilationWaiting: {
        id: 'gui.stageHeader.compilationWaiting',
        description: '编译等待中',
        defaultMessage: '编译等待中'
    },
    trainingInProgress: {
        id: 'gui.stageHeader.trainingInProgress',
        description: '正在训练',
        defaultMessage: '正在训练'
    },
    compiling: {
        id: 'gui.stageHeader.compiling',
        description: '正在编译',
        defaultMessage: '正在编译'
    },
    trainingFailed: {
        id: 'gui.stageHeader.trainingFailed',
        description: '训练失败',
        defaultMessage: '训练失败'
    },
    compilationFailed: {
        id: 'gui.stageHeader.compilationFailed',
        description: '编译失败',
        defaultMessage: '编译失败'
    },
    trainingSuccessful: {
        id: 'gui.stageHeader.trainingSuccessful',
        description: '训练成功',
        defaultMessage: '训练成功'
    },
    compilationSuccessful: {
        id: 'gui.stageHeader.compilationSuccessful',
        description: '编译成功',
        defaultMessage: '编译成功'
    },
    trainingStopped: {
        id: 'gui.stageHeader.trainingStopped',
        description: '训练已停止',
        defaultMessage: '训练已停止'
    },
    compilationStopped: {
        id: 'gui.stageHeader.compilationStopped',
        description: '编译已停止',
        defaultMessage: '编译已停止'
    },
    cloudDeploymentInitial: {
        id: 'gui.stageHeader.cloudDeploymentInitial',
        description: '云部署初始中',
        defaultMessage: '云部署初始中'
    },
    cloudDeploymentCreation: {
        id: 'gui.stageHeader.cloudDeploymentCreation',
        description: '云部署创建中',
        defaultMessage: '云部署创建中'
    },
    cloudDeploymentAndOperation: {
        id: 'gui.stageHeader.cloudDeploymentAndOperation',
        description: '云部署运行中',
        defaultMessage: '云部署运行中'
    },
    cloudDeploymentFailed: {
        id: 'gui.stageHeader.cloudDeploymentFailed',
        description: '云部署失败',
        defaultMessage: '云部署失败'
    },
    cloudDeploymentStopped: {
        id: 'gui.stageHeader.cloudDeploymentStopped',
        description: '云部署已停止',
        defaultMessage: '云部署已停止'
    },
    success: {
        id: 'gui.stageHeader.success',
        description: '成功',
        defaultMessage: '成功'
    },
    fail: {
        id: 'gui.stageHeader.fail',
        description: '失败',
        defaultMessage: '失败'
    },
    downloadModel: {
        id: 'gui.stageHeader.downloadModel',
        description: '下载模型',
        defaultMessage: '下载模型'
    }
});


// eslint-disable-next-line react/prefer-stateless-function
class StageHeader extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleKeyPress',
            'handleOpenStageNativeSizePopover',
            'handleCloseStageNativeSizePopover',
            'handleTriggerCoordinate',
            'trainStatus',
            'changeStatus',
            'formatDuring',
            'clearCodeStatus',
            'cloudStatus',
            'cloudTaskStatus',
            'tpuStatus',
            'clearTimer',
            // 'handleZoomOutCoordinateFontSize',
            // 'handleZoomInCoordinateFontSize'
        ]);
        this.state = {
            isShowCoordinate: false, // 是否显示坐标网格
            stageNativeSizePopoverOpen: false,
            code_status: {
                show: false,
                title: null,
                time: null,
            },
        };
        this.status_timer = null
        this.single_tiemr = null
    }
    componentDidMount () {
        document.addEventListener('keydown', this.handleKeyPress);
    }
    componentWillUnmount () {
        document.removeEventListener('keydown', this.handleKeyPress);
    }
    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.trainId !== this.props.trainId) && this.props.trainId) {
            this.trainStatus(this.props.trainId)
            this.clearTimer()
        } else if ((prevProps.compileId !== this.props.compileId) && this.props.compileId) {
            this.compileStatus(this.props.compileId)
            this.clearTimer()
        } else if ((prevProps.cloudId !== this.props.cloudId) && this.props.cloudId) {
            this.cloudStatus(this.props.cloudId)
            this.clearTimer()
        } else if (this.props.tpuId) {
            if (!prevProps.tpuId) {
                this.tpuStatus(this.props.tpuId)
                this.clearTimer()
            } else if (prevProps.tpuId.time !== this.props.tpuId.time) {
                this.tpuStatus(this.props.tpuId)
                // this.clearTimer()
            }
        }
        return
    }
    handleKeyPress (event) {
        if (event.key === 'Escape' && this.props.isFullScreen) {
            this.props.onSetStageUnFull(false);
        }
    }

    /**
     * 触发坐标网格的显示 or 隐藏
     */
    handleTriggerCoordinate () {
        const visible = !this.state.isShowCoordinate;

        this.props.vm.runtime.triggerCoordinate(visible);

        this.setState({
            isShowCoordinate: visible
        });
    }

    /**
     * 缩小坐标系的字体
     */
    // handleZoomOutCoordinateFontSize () {
    //     let temp = this.coordinateFontSize - 1;

    //     temp = temp >= this.minCoordinateFontSize ? temp : this.minCoordinateFontSize;
    //     console.log('temp :>> ', temp);

    //     this.coordinateFontSize = temp;
    //     this.props.vm.runtime.setCoordinateFontSize(temp);
    // }

    /**
     * 放大坐标系的字体
     */
    //  handleZoomInCoordinateFontSize () {
    //     let temp = this.coordinateFontSize + 1;

    //     temp = temp <= this.maxCoordinateFontSize ? temp : this.maxCoordinateFontSize;

    //     this.coordinateFontSize = temp;
    //     this.props.vm.runtime.setCoordinateFontSize(temp);
    // }

    /**
     * 打开 stageNativeSize 的 Popover 组件
     */
    handleOpenStageNativeSizePopover () {
        this.setState({stageNativeSizePopoverOpen: true});
    }

    /**
     * 关闭 stageNativeSize 的 Popover 组件
     */
    handleCloseStageNativeSizePopover () {
        this.setState({stageNativeSizePopoverOpen: false});
    }

    async trainStatus(jobId) {
        let response = await getTrainStatus(jobId)
        if (response.success) {
            let data = response.payload.trainJob
            let title = this.changeStatus(0, data.status)
            let time = this.formatDuring(data.runSec)
            this.setState({
                code_status: {
                    show: true,
                    title,
                    time
                }
            })

            if (['succeeded', 'failed', 'stopped'].includes(data.status)) { 
                this.clearCodeStatus(10000) 
                return
            } else {
                this.single_tiemr = setTimeout(() => this.trainStatus(jobId), 5000)
            }
        } else {
            this.clearCodeStatus(10000) 
            return
        }
    }
    async compileStatus(jobId) {
        let response = await getCompileStatus(jobId)
        if (response.success) {
            let data = response.payload.compileJob
            let title = this.changeStatus(1, data.status)
            let time = this.formatDuring(data.runSec)
            this.setState({
                code_status: {
                    show: true,
                    title,
                    time
                }
            })

            if (['succeeded', 'failed', 'stopped'].includes(data.status)) { 
                this.clearCodeStatus(10000) 
                return
            } else {
                this.single_tiemr = setTimeout(() => this.compileStatus(jobId), 5000)
            }
        } else {
            this.clearCodeStatus(10000) 
            return
        }
    }
    async cloudStatus(cloudId) {
        let response = await getCloudStatus(cloudId)
        if (response.success) {
            let data = response.payload.depInfo
            let title = this.cloudTaskStatus(data.status)
            let time = this.formatDuring(data.runSec)

            this.setState({
                code_status: {
                    show: true,
                    title,
                    time
                }
            })

            if (['Failed', 'Stopped'].includes(data.status)) { 
                this.clearCodeStatus(10000) 
                return
            } else {
                this.single_tiemr = setTimeout(() => this.cloudStatus(cloudId), 5000)
            }
        } else {
            this.clearCodeStatus(10000) 
            return
        }
    }
    tpuStatus(params) {
        this.setState({
            code_status: {
                show: true,
                title: this.props.intl.formatMessage(messages.downloadModel),
                time: params.time
            }
        })
        if (
            params.time === this.props.intl.formatMessage(messages.fail) || 
            params.time === this.props.intl.formatMessage(messages.success)
        ) {
            this.clearCodeStatus(10000) 
            return
        }
    }
    // 清空时间戳
    clearTimer() {
        clearTimeout(this.status_timer)
        clearTimeout(this.single_tiemr)
    }
    // 清空积木状态
    clearCodeStatus(time) {
        this.status_timer = setTimeout(() => {
            this.setState({
                code_status: {
                    show: false,
                    title: null,
                    time: null
                }
            })

            this.props.onResetAll()
        }, time)
    }
    // 任务状态
    changeStatus(type, status) {
        switch(status) {
            case 'preparing':
                return [
                    this.props.intl.formatMessage(messages.trainingInitialization), 
                    this.props.intl.formatMessage(messages.compileInitialization)
                ][type]
            case 'pending':
                return [
                    this.props.intl.formatMessage(messages.trainingWaiting), 
                    this.props.intl.formatMessage(messages.compilationWaiting), 
                ][type]
            case 'running':
                return [
                    this.props.intl.formatMessage(messages.trainingInProgress), 
                    this.props.intl.formatMessage(messages.compiling), 
                ][type]
            case 'failed':
                return [
                    this.props.intl.formatMessage(messages.trainingFailed), 
                    this.props.intl.formatMessage(messages.compilationFailed), 
                ][type]
            case 'succeeded':
                return [
                    this.props.intl.formatMessage(messages.trainingSuccessful), 
                    this.props.intl.formatMessage(messages.compilationSuccessful), 
                ][type]
            case 'stopped':
                return [
                    this.props.intl.formatMessage(messages.trainingStopped), 
                    this.props.intl.formatMessage(messages.compilationStopped), 
                ][type]
            default:
                return ''
        }
    }
    // 云任务状态
    cloudTaskStatus(type) {
        switch(type) {
            case 'Preparing':
                return this.props.intl.formatMessage(messages.cloudDeploymentInitial)
            case 'Creating':
                return this.props.intl.formatMessage(messages.cloudDeploymentCreation)
            case 'Available':
                return this.props.intl.formatMessage(messages.cloudDeploymentAndOperation)
            case 'Failed':
                return this.props.intl.formatMessage(messages.cloudDeploymentFailed)
            case 'Stopped':
                return this.props.intl.formatMessage(messages.cloudDeploymentStopped)
            default:
                return ''
        }
    }
    // 运行时间格式化
    formatDuring(mss) {
        mss = mss * 1000
        var days = parseInt(mss / (1000 * 60 * 60 * 24))
        days = days === 0 ? '' : days + 'd'
        var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        hours = hours === 0 ? '' : hours + 'h'
        var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
        minutes = minutes === 0 ? '' : minutes + 'm'
        var seconds = Math.round((mss % (1000 * 60)) / 1000) + 's'
        seconds = seconds === 0 ? '' : seconds
        return days + hours + minutes + seconds
    }

    render () {
        const {
            ...props
        } = this.props;
        return (
            <StageHeaderComponent
                {...props}
                code_status={this.state.code_status}
                onKeyPress={this.handleKeyPress}
                onTriggerCoordinate={this.handleTriggerCoordinate}
                // onZoomOutCoordinateFontSize={this.handleZoomOutCoordinateFontSize}
                // onZoomInCoordinateFontSize={this.handleZoomInCoordinateFontSize}
                
                stageNativeSizePopoverOpen={this.state.stageNativeSizePopoverOpen}
                onOpenStageNativeSizePopover={this.handleOpenStageNativeSizePopover}
                onCloseStageNativeSizePopover={this.handleCloseStageNativeSizePopover}
            />
        );
    }
}

StageHeader.propTypes = {
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    onSetStageUnFull: PropTypes.func.isRequired,
    showBranding: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    vm: PropTypes.instanceOf(VM).isRequired,
    stageNativeSize: PropTypes.array.isRequired,
    onSetStageNativeSize: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    showBranding: state.scratchGui.mode.showBranding,
    isFullScreen: state.scratchGui.mode.isFullScreen,
    isPlayerOnly: state.scratchGui.mode.isPlayerOnly,
    stageNativeSize: state.scratchGui.stageSize.stageNativeSize, // 当前舞台尺寸
    trainId: state.scratchGui.codeStatus.train,
    compileId: state.scratchGui.codeStatus.compile,
    tpuId: state.scratchGui.codeStatus.tpu,
    cloudId: state.scratchGui.codeStatus.cloud
});

const mapDispatchToProps = dispatch => ({
    onSetStageLarge: () => dispatch(setStageSize(STAGE_SIZE_MODES.large)),
    onSetStageSmall: () => dispatch(setStageSize(STAGE_SIZE_MODES.small)),
    onSetStageFull: () => dispatch(setFullScreen(true)),
    onSetStageUnFull: () => dispatch(setFullScreen(false)),
    onSetStageNativeSize: (stageNativeSize) => dispatch(setStageNativeSize(stageNativeSize)),
    onResetAll: () => dispatch(resetAll())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StageHeader);
