import { getWorkDetail } from "../api/zhiya"

const CAN_SAVE = 'canSave'
const DIALOG_VISIBLE_ZHIYA = 'dialogVisibleZhiya'
const PROGRAM_ID = 'programId'
const WORK_ID = 'workId'
const WORK_COVER = 'workCover'
const WORK_PATH = 'workPath'
const USER_ID = 'userId'
const TITLE = 'title'
const SORT = 'sort'
const REMARK = 'remark'
const GET_WORK_DETAIL_ZHIYA = 'getWorkDetailZhiya'

const initState = {
  canSave: false, // 是否可以保存
  dialogVisibleZhiya: false, // 保存作品弹窗标识
  programId: '', // 编程平台id
  workId: '', // 作品id
  workCover: '', // 作品封面
  workPath: '', // 作品地址
  userId: '', // 作者id
  title: '', // 标题
  sort: 0, // 排序
  remark: '', // 备注
}

const reducer = function (state = initState, action) {
  if (typeof state === 'undefined') state = initState;
  switch (action.type) {
    case CAN_SAVE:
      return Object.assign({}, state, {
        canSave: action.param
      })
    case DIALOG_VISIBLE_ZHIYA:
      return Object.assign({}, state, {
        dialogVisibleZhiya: action.param
      })
    case PROGRAM_ID:
      return Object.assign({}, state, {
        programId: action.param
      })
    case WORK_ID:
      return Object.assign({}, state, {
        workId: action.param
      })
    case WORK_COVER:
      return Object.assign({}, state, {
        workCover: action.param
      })
    case WORK_PATH:
      return Object.assign({}, state, {
        workPath: action.param
      })
    case USER_ID:
      return Object.assign({}, state, {
        userId: action.param
      })
    case TITLE:
      return Object.assign({}, state, {
        title: action.param
      })
    case SORT:
      return Object.assign({}, state, {
        sort: action.param
      })
    case REMARK:
      return Object.assign({}, state, {
        remark: action.param
      })
    case GET_WORK_DETAIL_ZHIYA:
      getWorkDetail(action.param)
        .then((response) => {
            if (response.msg === '操作成功') {
                action.callback(response.data)
            }
        })
        .catch(() => {
            return state
        })
      return state
    default:
      return state
  }
}

const setCanSaveZhiya = (state) => ({
  type: CAN_SAVE,
  param: state
})
const setDialogVisibleZhiya = (state) => ({
  type: DIALOG_VISIBLE_ZHIYA,
  param: state
})
const setProgramId = (state) => ({
  type: PROGRAM_ID,
  param: state
})
const setWorkId = (state) => ({
  type: WORK_ID,
  param: state
})
const setWorkCover = (state) => ({
  type: WORK_COVER,
  param: state
})
const setWorkPath = (state) => ({
  type: WORK_PATH,
  param: state
})
const setUserId = (state) => ({
  type: USER_ID,
  param: state
})
const setTitle = (state) => ({
  type: TITLE,
  param: state
})
const setSort = (state) => ({
  type: SORT,
  param: state
})
const setRemark = (state) => ({
  type: REMARK,
  param: state
})
const getWorkDetailZhiya = (state, callback) => ({
  type: GET_WORK_DETAIL_ZHIYA,
  param: state,
  callback
})

export {
  reducer as default,
  initState as workZhiYaInitState,
  setCanSaveZhiya,
  setDialogVisibleZhiya,
  setProgramId,
  setWorkId,
  setWorkCover,
  setWorkPath,
  setUserId,
  setTitle,
  setSort,
  setRemark,
  getWorkDetailZhiya
}
