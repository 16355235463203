const service = require('../utils/request');
const minIOService = require('../utils/minIO.js')

export function login(data) {
    return service({
        url: '/openaiserver/v1/authmanage/token',
        method: 'post',
        data
    })
}

export function loginOUT(data) {
    return service({
        url: '/openaiserver/v1/authmanage/token?casToken=' + data,
        method: 'delete',
    })
}

export function userManage(data) {
    return service({
        url: '/openaiserver/v1/usermanage/user?token=' + data,
        method: 'get'
    })
}

export function getCastoken(data) {
    return service({
        url: '/openaiserver/v1/authmanage/castoken',
        method: 'post',
        data
    })
}

// 单点cas_token换取编程平台token
export function getCodeToken(data) {
    return service({
        url: '/openaiserver/v1/authmanage/cas/token',
        method: 'post',
        data
    })
}

// 训练平台
// 数据集类型
export function getDataSetType(pageIndex, pageSize) {
    return service({
        url: `/openaiserver/v1/datasetmanage/datasettype?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: 'get',
    })
}

// 标签类型
export function getTagType(pageIndex, pageSize) {
    return service({
        url: `/openaiserver/v1/datasetmanage/datasetapply?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: 'get',
    })
}

// 创建数据集
export function createDataSet(data) {
    return service({
        url: '/openaiserver/v1/datasetmanage/dataset',
        method: 'post',
        data
    })
}

// 获取上传地址
export function getUploadUrl(datasetId, version, data) {
    return service({
        url: `/openaiserver/v1/datasetmanage/dataset/${datasetId}/version/${version}/upload`,
        method: 'post',
        data
    })
}

// 获取默认配置
export function getDefaultConfig(params) {
    return service({
      url: '/openaiserver/v1/systemmanage/config',
      method: 'get',
      params
    })
}

// 上传文件
export function minIO(url, data) {
    return minIOService({
        method: 'put',
        url: url,
        data: data
    })
}

// 上传文件确认
export function confirmUploadFile(datasetId, version, data) {
    return service({
        url: `/openaiserver/v1/datasetmanage/dataset/${datasetId}/version/${version}/uploadconfirm`,
        method: 'put',
        data
    })
}

// 群组信息
export function getSpace(params) {
    return service({
        url: `/openaiserver/v1/usermanage/user/${params}/workspace`,
        method: 'get'
    })
}
