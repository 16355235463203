import React from 'react';
import styles from './params.css';
import ICON_DEL from '../img/icon_del.png'
import ICON_EDI from '../img/icon_edi.png'

const Params = props => {
    return (
        <>
            {props.list.map((item, index) => {
                return (
                    <li key={index} className={styles.listItem}>
                        {/* <div className={styles.name}>显示名称</div> */}
                        <div 
                            className={styles.name}
                            onDoubleClick={() => props.doubleInput(index, 'name', item.name, item.value)}
                            onBlur={() => props.blurInput(index, 'name')}
                        >
                            {props.edit_name_index === index ? (
                                <input 
                                    className={styles.editInput} 
                                    value={item.name} 
                                    autoFocus="true"
                                    onChange={(e) => props.editNameChange(e, index)}
                                />
                            ) : 
                            (<span>{item.name}</span>)}
                        </div>
                        {/* <div className={styles.name}>{
                            item.value.indexOf('\u272A') !== -1 ? item.value.substring(item.value.indexOf('\u272A') + 1) : item.value
                        }</div> */}
                        <div 
                            className={styles.name}
                            onDoubleClick={() => props.doubleInput(index, 'value', item.name, item.value)}
                            onBlur={() => props.blurInput(index, 'value')}
                        >
                            {props.edit_value_index === index ? (
                                <input
                                    className={styles.editInput}
                                    value={item.value.indexOf('\u272A') !== -1 ? item.value.substring(item.value.indexOf('\u272A') + 1) : item.value}
                                    autoFocus="true"
                                    onChange={(e) => props.editValueChange(e, index)}
                                />
                            ) : 
                            (<span>{
                                item.value.indexOf('\u272A') !== -1 ? item.value.substring(item.value.indexOf('\u272A') + 1) : item.value
                            }</span>)}
                        </div>
                        {item.operate ?
                            (
                                <div className={styles.operate}>{item.operate}</div>
                            ) :
                            (
                                <div className={styles.operate}>
                                    <div className={styles.operateDel} onClick={() => props.deleteParams(item.name)}>
                                        <img src={ICON_DEL} alt="" />
                                        <span>{props.delete}</span>
                                    </div>
                                    <div className={styles.operateEdi} onClick={
                                        () => props.showParamsDialog(
                                            true, 
                                            'edit', 
                                            item.value.indexOf('\u272A') !== -1 ? item.value.substring(item.value.indexOf('\u272A') + 1) : item.value, 
                                            item.name
                                        )}
                                    >
                                        <img src={ICON_EDI} alt="" />
                                        <span>{props.edit}</span>
                                    </div>
                                    {/* <input className={styles.edit} type="button" value={props.edit} onClick={
                                        () => props.showParamsDialog(
                                            true, 
                                            'edit', 
                                            item.value.indexOf('\u272A') !== -1 ? item.value.substring(item.value.indexOf('\u272A') + 1) : item.value, 
                                            item.name
                                        )}
                                    />
                                    <input className={styles.delete} type="button" value={props.delete} onClick={() => props.deleteParams(item.name)} /> */}
                                </div>
                            )
                        }
                    </li>
                )
            })}
        </>
    )
}

export default Params
