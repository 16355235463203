import { getWorkDetails, getConfigKey, getDictFile } from "../api/manage"

const GET_WORK_DETAIL = 'getWorkDetails'
const WORK_ID = 'workId'
const WORK_PATH = 'workPath'
const DESCRIPTION = 'description'
const WORK_COVER = 'workCover'
const TYPE = 'type'
const IS_WORK = 'isWork'
const IMAGE_BASE_URL = 'imageBaseUrl'
const GET_IMAGE_BASE_URL = 'getImageBaseUrl'
const DIALOG_VISIBLE = 'dialogVisible'
const WORK_NAME = 'workName'
const FILE_ID = 'fileId'
const IS_FILE = 'isFile'
const FILE_PATH = 'filePath'
const GET_DICT_FILE = 'getDictFile'
const SET_FILE = "setFile"
const FILE_NAME = 'fileName'
const AVATAR_PATH = 'avatarPath'


const initState = {
    isWork: false, // 标识是否是作品
    workId: '', // 作品id
    workPath: '', // 作品地址
    description: '', // 作品描述
    workCover: '', // 封面地址
    type: '', 
    imageBaseUrl: '', // 图片请求头url
    dialogVisible: false, // 标识作品保存弹窗
    workName: '', // 作品名
    isFile: false, // 标识是否是课程资源
    fileId: '', // 课程资源id
    filePath: '', // 课程资源地址
    file: {}, // 资源文件
    fileName: '', // 资源文件名
    avatarPath: '', // 封面
}

const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch(action.type) {
        case IS_WORK:
            return Object.assign({}, state, {
                isWork: action.param
            })
        case WORK_ID:
            return Object.assign({}, state, {
                workId: action.param
            })
        case WORK_PATH:
            return Object.assign({}, state, {
                workPath: action.param
            })
        case DESCRIPTION: 
            return Object.assign({}, state, {
                description: action.param
            })
        case WORK_COVER:
            return Object.assign({}, state, {
                workCover: action.param
            })
        case TYPE: 
            return Object.assign({}, state, {
                type: action.param
            })
        case IMAGE_BASE_URL: 
            return Object.assign({}, state, {
                imageBaseUrl: action.param
            })
        case DIALOG_VISIBLE:
            return Object.assign({}, state, {
                dialogVisible: action.param
            })
        case WORK_NAME:
            return Object.assign({}, state, {
                workName: action.param
            })
        case IS_FILE:
            return Object.assign({}, state, {
                isFile: action.param
            })
        case FILE_ID:
            return Object.assign({}, state, {
                fileId: action.param
            })
        case FILE_PATH:
            return Object.assign({}, state, {
                filePath: action.param
            })
        case SET_FILE:
            return Object.assign({}, state, {
                file: action.param
            })
        case FILE_NAME: 
            return Object.assign({}, state, {
                fileName: action.param
            })
        case AVATAR_PATH:
            return Object.assign({}, state, {
                avatarPath: action.param
            })
        case GET_DICT_FILE:
            getDictFile(action.param).then((response) => {
                if(response.code === 200) {
                    action.callback(response.data)
                }
            }).catch(() => {
                return state
            })
            return state
        case GET_IMAGE_BASE_URL:
            getConfigKey(action.param).then((response) => {
                if (response.code === 200) {
                    action.callback(response.msg)
                }
            }).catch(() => {
                return state
            })
            return state
        case GET_WORK_DETAIL:
            getWorkDetails(action.param)
                .then((response) => {
                    if (response.msg === '操作成功') {
                        action.callback(response.data)
                        // let obj = response.data
                        // return Object.assign({}, state, {
                        //     task_detail: obj
                        // })
                    }
                })
                .catch(() => {
                    return state
                })
            return state
        default:
            return state
    }
}

const setIsWork = (state) => ({
    type: IS_WORK,
    param: state
})
const setWorkId = (state) => ({
    type: WORK_ID,
    param: state
})
const setWorkPath = (state) => ({
    type: WORK_PATH,
    param: state
}) 
const setDescription = (state) => ({
    type: DESCRIPTION,
    param: state
})
const setWorkCover = (state) => ({
    type: WORK_COVER,
    param: state
})
const setType = (state) => ({
    type: TYPE,
    param: state
})
const setImageBaseUrl = (state) => ({
    type: IMAGE_BASE_URL,
    param: state
})
const setDialogVisible = (state) => ({
    type: DIALOG_VISIBLE,
    param: state
})
const setWorkName = (state) => ({
    type: WORK_NAME,
    param: state
})
const setFileId = (state) => ({
    type: FILE_ID,
    param: state
})
const setIsFile = (state) => ({
    type: IS_FILE,
    param: state
})
const setFilePath = (state) => ({
    type: FILE_PATH,
    param: state
})
const setFileData = (state) => ({
    type: SET_FILE,
    param: state
})
const setFileName = (state) => ({
    type: FILE_NAME,
    param: state
})
const setAvatarPath = (state) => ({
    type: AVATAR_PATH,
    param: state
})

const getWorkDetail = (state, callback) => ({
    type: GET_WORK_DETAIL,
    param: state,
    callback
})
const getImageBaseUrl = (state, callback) => ({
    type: GET_IMAGE_BASE_URL,
    param: state,
    callback
})
const getDictFileDetail = (state, callback) => ({
    type: GET_DICT_FILE,
    param: state,
    callback
})

export {
    reducer as default,
    initState as workInitState,
    getWorkDetail,
    setWorkId,
    setWorkPath,
    setDescription,
    setWorkCover,
    setType,
    setIsWork,
    setImageBaseUrl,
    getImageBaseUrl,
    setDialogVisible,
    setWorkName,
    setFileId,
    setIsFile,
    setFilePath,
    getDictFileDetail,
    setFileData,
    setAvatarPath,
    setFileName
}