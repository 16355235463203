import React from 'react';
import classNames from 'classnames';
import styles from '../create-model.css';
import close from '../../../../static/assets/icon_close.png'

const Create = (props) => {
    return (
        <div className={styles.box}>
            <div className={styles.header}>
                <div>{props.intl.formatMessage(props.LANG_MESSAGE.newModel)}</div>
                <img className={styles.closeIcon} src={close} onClick={props.closeDialog} />
            </div>
            <div className={styles.content}>
                <div className={styles.modelName}>
                    <span>{props.intl.formatMessage(props.LANG_MESSAGE.MODEL_NAME)}：</span>
                    <input 
                        type="text" 
                        value={props.model_name}
                        onChange={props.setModelName}
                    />
                </div>

                {props.action ? (
                    <div className={styles.taskType}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.algorithmType)}：</span>
                        <ul onClick={props.taskListEvent}>
                            {props.task_type_list.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={classNames(
                                            styles.typeList,
                                            props.task_type_act === index ? styles.typeListAct : ''
                                        )}
                                    >{item.value}</li> 
                                )
                            })}
                        </ul>
                    </div>
                ) : null}
            </div>
            <div className={styles.footer}>
                <input 
                    className={classNames(styles.cancle, styles.comBtn)}
                    type="button" 
                    value={props.intl.formatMessage(props.LANG_MESSAGE.cancel)}
                    onClick={props.cancle}
                />
                <input 
                    className={classNames(styles.confirm, styles.comBtn)}
                    type="button"
                    value={props.intl.formatMessage(props.LANG_MESSAGE.ok)}
                    onClick={props.confirm}
                />
            </div>
        </div>
    )
}

export default Create