const axios = require('axios').default;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const manageService = axios.create({
    // axios 请求中 URL 的公共部分
    baseURL: ON_LINE_URL + '/adminapi',
    // baseURL: ON_LINE_MANAGE + '/adminapi', // 新增单独给教学管理平台，防止地址不相同
    // 设置请求超时时间
    timeout: 10 * 1000
})

// 请求拦截器
manageService.interceptors.request.use(config => {
    let token = localStorage.getItem('manage_token') ? 'Bearer ' + localStorage.getItem('manage_token') : ''
    config.headers.Authorization = token
    return config
})

// 响应拦截器
manageService.interceptors.response.use(res => {
    return res.data
}, error => {
    window.globalMsg.tips = {
        visible: true,
        content: error.message,
        type: 'fail',
        data: new Date()
    }
    return Promise.reject(error);
})

module.exports = manageService;