import searchIcon from './search.png';
import closeIcon from './close.png';
import backgroundIcon from './background.png';
import backgroundActiveIcon from './background-act.png';
import roleIcon from './role.png';
import roleActiveIcon from './role-act.png';
import soundIcon from './sound.png';
import soundActiveIcon from './sound-act.png';
import moreIcon from './more.png';
import moreActiveIcon from './more-act.png';
import soundSvg from './sound.svg'
import sound1 from './sound1.png'

const IMG_CONTAINER = {
    searchIcon,
    closeIcon,
    backgroundIcon,
    backgroundActiveIcon,
    roleIcon,
    roleActiveIcon,
    soundIcon,
    soundActiveIcon,
    moreIcon,
    moreActiveIcon,
    soundSvg,
    sound1,
}
export default IMG_CONTAINER