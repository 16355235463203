import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import DeleteButton from '../delete-button/delete-button.jsx';
import styles from './sprite-selector-item.css';
import {ContextMenuTrigger} from 'react-contextmenu';
import {DangerousMenuItem, ContextMenu, MenuItem} from '../context-menu/context-menu.jsx';
import {FormattedMessage} from 'react-intl';
import deleteIcon from './delete.svg';
import copyIcon from './copy.svg';
import editIcon from './edit.svg'
import detailIcon from './detail.svg'

// react-contextmenu requires unique id to match trigger and context menu
let contextMenuId = 0;

const SpriteSelectorItem = props => (
    <ContextMenuTrigger
        attributes={{
            className: classNames(props.className, styles.spriteSelectorItem, {
                [styles.isSelected]: !props.isPan && props.selected,
                [styles.isPanSelected]: props.isPan && props.selected,
                [styles.spriteSelectorItemNew]: props.isPan
            }),
            onClick: props.onClick,
            onMouseEnter: props.onMouseEnter,
            onMouseLeave: props.onMouseLeave,
            onMouseDown: props.onMouseDown,
            onTouchStart: props.onMouseDown
        }}
        disable={props.preventContextMenu}
        id={`${props.name}-${contextMenuId}`}
        ref={props.componentRef}
    >
        {(!props.details && !props.isPan) ?
            <div
                className={styles.spriteContainer}
                style={{
                    width: props.selected ? '100%' : '86px',
                    background: props.selected ? '#FDF6DC' : '#FEFBF2'
                }}
            >
                {props.costumeURL ? 
                    (
                        <div className={classNames(styles.spriteImageOuter, styles.spriteContainerImg)}>
                            <div style={{position: 'relative'}} className={styles.spriteImageInner}>
                                <img
                                    className={styles.spriteImage}
                                    draggable={false}
                                    src={props.costumeURL}
                                />
                            </div>

                            <div className={classNames(styles.spriteInfo, {
                                [styles.spriteInfoWidth]: props.isPan
                            })}>
                                <div className={styles.spriteName}>{props.name}</div>
                                <div className={styles.spriteDetails}>{props.details}</div>
                            </div>

                            {props.selected ? 
                                (<img
                                    className={styles.detailImage}
                                    draggable={false}
                                    src={detailIcon}
                                />) : null
                            }
                        </div>
                    ) : null
                }

                <img
                    className={styles.spriteHoverImage}
                    draggable={false}
                    src={detailIcon}
                />

                <DeleteButton
                    className={styles.spriteHoverButton}
                    onClick={props.onDeleteButtonClick}
                />
            </div>
            : null
        }

        {typeof props.number === 'undefined' ? null : (
            <div className={styles.number}>{props.number}</div>
        )}

        {(props.isPan || props.details) && props.costumeURL ? (
            <div className={classNames(styles.spriteImageOuter, {
                [styles.spriteImageOuterWidth]: props.isPan
            })}>
                <div className={styles.spriteImageInner}>
                    <img
                        className={styles.spriteImage}
                        draggable={false}
                        src={props.costumeURL}
                    />
                </div>
            </div>
        ) : null}
        {(props.isPan || props.details) && props.costumeURL ? (
            <div className={classNames(styles.spriteInfo, {
                [styles.spriteInfoWidth]: props.isPan
            })}>
                <div className={styles.spriteName}>{props.name}</div>
                {props.details ? (
                    <div className={styles.spriteDetails}>{props.details}</div>
                ) : null}
            </div>
        ) : null}

        {/* {props.costumeURL ? (
            <div className={classNames(styles.spriteImageOuter, {
                [styles.spriteImageOuterWidth]: props.isPan
            })}>
                <div className={styles.spriteImageInner}>
                    <img
                        className={styles.spriteImage}
                        draggable={false}
                        src={props.costumeURL}
                    />
                </div>
            </div>
        ) : null} */}
        {/* <div className={classNames(styles.spriteInfo, {
            [styles.spriteInfoWidth]: props.isPan
        })}>
            <div className={styles.spriteName}>{props.name}</div>
            {props.details ? (
                <div className={styles.spriteDetails}>{props.details}</div>
            ) : null}
        </div> */}
        
        {props.isPan ? (
            <div className={styles.spriteCopyWidth}>
                {(props.selected && props.onDuplicateButtonClick) ? (
                    <div onClick={props.onDuplicateButtonClick}>
                        <img src={copyIcon} alt="" />
                    </div>
                ) : null}
            </div>
        ) : null}
        {props.isPan ? (
            <div className={styles.spriteEditWidth}>
                {(props.selected && props.onEditButtonClick) ? (
                    <div onClick={props.onEditButtonClick}>
                        <img src={editIcon} alt="" />
                    </div>
                ) : null}
            </div>
        ) : null}
        {props.isPan ? (
            <div className={styles.spriteDeleteWidth}>
                {(props.selected && props.onDeleteButtonClick) ? (
                    <div  onClick={props.onDeleteButtonClick}>
                        <img src={deleteIcon} alt="" />
                    </div>
                ) : null}
            </div>
        ): null}
        
       
        {(props.selected && props.onDeleteButtonClick && !props.isPan) ? (
            <DeleteButton
                className={styles.deleteButton}
                onClick={props.onDeleteButtonClick}
            />
        ) : null }
        {props.onDuplicateButtonClick || props.onDeleteButtonClick || props.onExportButtonClick ? (
            <ContextMenu id={`${props.name}-${contextMenuId++}`}>
                {props.onDuplicateButtonClick ? (
                    <MenuItem onClick={props.onDuplicateButtonClick}>
                        <FormattedMessage
                            defaultMessage="duplicate"
                            description="Menu item to duplicate in the right click menu"
                            id="gui.spriteSelectorItem.contextMenuDuplicate"
                        />
                    </MenuItem>
                ) : null}
                {props.onExportButtonClick ? (
                    <MenuItem onClick={props.onExportButtonClick}>
                        <FormattedMessage
                            defaultMessage="export"
                            description="Menu item to export the selected item"
                            id="gui.spriteSelectorItem.contextMenuExport"
                        />
                    </MenuItem>
                ) : null }
                {props.onDeleteButtonClick ? (
                    <DangerousMenuItem onClick={props.onDeleteButtonClick}>
                        <FormattedMessage
                            defaultMessage="delete"
                            description="Menu item to delete in the right click menu"
                            id="gui.spriteSelectorItem.contextMenuDelete"
                        />
                    </DangerousMenuItem>
                ) : null }
            </ContextMenu>
        ) : null}
    </ContextMenuTrigger>
);

SpriteSelectorItem.propTypes = {
    className: PropTypes.string,
    componentRef: PropTypes.func,
    costumeURL: PropTypes.string,
    details: PropTypes.string,
    name: PropTypes.string.isRequired,
    number: PropTypes.number,
    onClick: PropTypes.func,
    onDeleteButtonClick: PropTypes.func,
    onDuplicateButtonClick: PropTypes.func,
    onExportButtonClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    preventContextMenu: PropTypes.bool,
    selected: PropTypes.bool.isRequired,
    onEditButtonClick: PropTypes.func
};

export default SpriteSelectorItem;
