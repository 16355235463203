import React from 'react';
import classNames from 'classnames';
import styles from './task-type.css';
import close from '../../../../static/assets/icon_close.png';
import taskTypeIcon from '../img/task-type.png'

const TaskType = (props) => {
    return (
        <div className={styles.box}>
            <div className={styles.header}>
                <div>{props.intl.formatMessage(props.LANG_MESSAGE.taskType)}</div>
                <img className={styles.closeIcon} src={close} onClick={props.closeDialog} />
            </div>
            <div className={styles.content}>
                <img className={styles.taskTypeIcon} src={taskTypeIcon} />
                <ul onClick={props.taskListEvent}>
                    {props.task_type_list.map((item, index) => {
                        return (
                            <li
                                key={index}
                                className={classNames(
                                    styles.typeList,
                                    props.task_type_act === index ? styles.typeListAct : ''
                                )}
                            >{item.value}</li> 
                        )
                    })}
                </ul>
            </div>
            <div className={styles.footer}>
                <input 
                    className={classNames(styles.select, styles.comBtn)}
                    type="button" 
                    value={props.intl.formatMessage(props.LANG_MESSAGE.selectDataset)}
                    onClick={() => props.selectDataSet('train')}
                />
                {/* <input 
                    className={classNames(styles.create, styles.comBtn)}
                    type="button"
                    value={props.intl.formatMessage(props.LANG_MESSAGE.createDataset)}
                    onClick={props.createDataSet}
                /> */}
            </div>
        </div>
    )
}

export default TaskType