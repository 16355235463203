const SET_PROJECT_UISTYLE = 'projectUIStyle/SET_PROJECT_UISTYLE';

// we are initializing to a blank string instead of an actual title,
// because it would be hard to localize here
const initialUIState = true;

const reducer = function (state) {
    if (typeof state === 'undefined') state = initialUIState;
    return state
};
const setProjectUIStyle = title => ({
    type: SET_PROJECT_UISTYLE,
    title: title
});

export {
    reducer as default,
    initialUIState as projectUIStyleInitial,
    setProjectUIStyle
};
