import React from 'react';
import styles from './data_set.css';

import IMG_CONTAINER from '../img';

const DataSet = props => {
    return (
        <>
            {props.list.map((item, index) => {
                return (
                    <li key={index} className={styles.listItem} style={{background: props.list_check === index ? 'rgb(253, 246, 220)' : null}}>
                        {(index === 0 || [1, 2, 4].includes(item.status)) ? 
                            <div className={styles.empty}></div> : 
                            <img 
                                src={props.list_check === index ? IMG_CONTAINER.check : IMG_CONTAINER.noCheck}
                                onClick={() => props.checkHandle(index)}
                                className={styles.image}
                            />
                        }
                        <div className={styles.name}>{item.name}</div>
                        <div className={styles.desc}>{item.desc}</div>
                        <div className={styles.desc}>{item.state}</div>
                        <div className={styles.date}>{item.createdAt}</div>
                    </li>
                )
            })}
        </>
    )
}

export default DataSet