import React from 'react';
import styles from './model.css';
import classNames from 'classnames';

import IMG_CONTAINER from '../img';

const Model = props => {
    return (
        <>
            {props.list.map((item, index) => {
                return (
                    <li key={index} className={styles.listItem} style={{background: props.list_check === index ? 'rgb(253, 246, 220)' : null}}>
                        {index === 0 ? 
                            null : 
                            <img 
                                src={props.list_check === index ? IMG_CONTAINER.check : IMG_CONTAINER.noCheck}
                                onClick={() => props.checkHandle(index)}
                                className={styles.image}
                            />
                        }
                        <div className={styles.itemCom}>{item?.versionDetail?.userName || item.userName}</div>
                        <div className={styles.itemCom}>{item?.versionDetail?.versionName || item.versionName}</div>
                        <div className={styles.itemCom} title={item?.versionDetail?.descript || item.descript}>{item?.versionDetail?.descript || item.descript}</div>
                        <div className={styles.itemCom}>{item?.versionDetail?.version || item.version}</div>
                        <div className={styles.date}>{item?.versionDetail?.createdAt || item.createdAt}</div>
                        {(props.type === 1 || props.type === 5) ? (<div className={styles.itemCom}>{item?.versionDetail?.algorithmName || item.algorithmName}</div>) : null}
                        {props.type === 4 ? (<div className={styles.itemCom}>{item?.versionDetail?.type || item.type}</div>) : null}
                        
                        {/* {(props.type === 1 || props.type === 5) ? ( */}
                            <div className={styles.itemCom}>
                                {item.operation ? item.operation : (
                                    // <>
                                        (item.hasOwnProperty('fileStatus') ? (
                                            <span 
                                                onClick={() => props.downloadHandle(item)}
                                                className={classNames(item.fileStatus === 2 ? styles.download : styles.disDownload)}
                                            >{props.down_title}</span>
                                        ) : (
                                            <span 
                                                onClick={() => props.downloadHandle(item)}
                                                className={classNames(item.versionDetail.fileStatus === 2 ? styles.download : styles.disDownload)}
                                            >{props.down_title}</span>
                                        ))
                                    // </>
                                )}
                            </div>
                        {/* ) : null} */}
                    </li>
                )
            })}
        </>
    )
}

export default Model
