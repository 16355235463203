import projectData from './project-data';

/* eslint-disable import/no-unresolved */
import popWav from '!arraybuffer-loader!./83a9787d4cb6f3b7632b4ddfebf74367.wav';
import meowWav from '!arraybuffer-loader!./83c36d806dc92327b9e7049a565c6bff.wav';
import backdrop from '!raw-loader!./cd21514d0531fdffb22204e0ec5ed84a.svg';
import costume1 from '!raw-loader!./bcf454acf82e4504149f7ffe07081dbc.svg';
import costume2 from '!raw-loader!./0fb9be3e8397c983338cb71dc84d0b25.svg';
// import costume3 from '!raw-loader!./d69bd1e511e97c34937b5c0293626123.png';
// import Desert from '!raw-loader!./d98a9526a34890cf4bad11b5409eae2a.svg';
// import backdrop1 from '!raw-loader!./3b8bcabd0ac683b7cb3673208039799b.png';
/* eslint-enable import/no-unresolved */

const defaultProject = translator => {
    let _TextEncoder;
    if (typeof TextEncoder === 'undefined') {
        _TextEncoder = require('text-encoding').TextEncoder;
    } else {
        /* global TextEncoder */
        _TextEncoder = TextEncoder;
    }
    const encoder = new _TextEncoder();

    const projectJson = projectData(translator);
    return [
        {
            id: 0,
            assetType: 'Project',
            dataFormat: 'JSON',
            data: JSON.stringify(projectJson)
        },
        {
            id: '83a9787d4cb6f3b7632b4ddfebf74367',
            assetType: 'Sound',
            dataFormat: 'WAV',
            data: new Uint8Array(popWav)
        }, 
        {
            id: '83c36d806dc92327b9e7049a565c6bff',
            assetType: 'Sound',
            dataFormat: 'WAV',
            data: new Uint8Array(meowWav)
        },
        {
            id: 'cd21514d0531fdffb22204e0ec5ed84a',
            assetType: 'ImageVector',
            dataFormat: 'SVG',
            data: encoder.encode(backdrop)
        }, 
        {
            id: 'bcf454acf82e4504149f7ffe07081dbc',
            assetType: 'ImageVector',
            dataFormat: 'SVG',
            data: encoder.encode(costume1)
        }, {
            id: '0fb9be3e8397c983338cb71dc84d0b25',
            assetType: 'ImageVector',
            dataFormat: 'SVG',
            data: encoder.encode(costume2)
        }
        // {
        //     id: 'jB8RtNQ2nOTQw5jRvF0HKqZHLmvL6tag',
        //     assetType: 'ImageBitmap',
        //     dataFormat: 'PNG',
        //     data: encoder.encode(costume3)
        // },
        // {
        //     id: '3b8bcabd0ac683b7cb3673208039799b',
        //     assetType: 'ImageBitmap',
        //     dataFormat: 'PNG',
        //     data: encoder.encode(backdrop1)
        // },
    ];
};

export default defaultProject;
