// import PropTypes from 'prop-types';
// import React from 'react';

// import locales from 'scratch-l10n';
// import styles from './language-selector.css';

// // supported languages to exclude from the menu, but allow as a URL option
// const ignore = [];  // 可切换的语言

// const LanguageSelector = ({currentLocale, label, onChange}) => (
//     <select
//         aria-label={label}
//         className={styles.languageSelect}
//         value={currentLocale}
//         onChange={onChange}
//     >
//         {
//             Object.keys(locales)
//                 .filter(l => !ignore.includes(l))
//                 .map(locale => (
//                     <option
//                         key={locale}
//                         value={locale}
//                     >
//                         {locales[locale].name}
//                     </option>
//                 ))
//         }
//     </select>
// );

// LanguageSelector.propTypes = {
//     currentLocale: PropTypes.string,
//     label: PropTypes.string,
//     onChange: PropTypes.func
// };

// export default LanguageSelector;

import PropTypes from 'prop-types';
import React from 'react';

import locales from 'scratch-l10n';
import styles from './language-selector.css';
import classNames from 'classnames';

// supported languages to exclude from the menu, but allow as a URL option
const ignore = [];  // 可切换的语言

const LanguageSelector = ({currentLocale, label, onMouseUp, showLangule}) => (
    <div
        aria-label={label}
        className={classNames([styles.languageSelect])}
        value={currentLocale}
        id="language-id"
    >
        {
            Object.keys(locales)
                .filter(l => !ignore.includes(l))
                .map(locale => (
                    <div
                        className={classNames(styles.languageSelectItem)}
                        key={locale}
                        value={locale}
                        onMouseUp={() => onMouseUp(locale)}
                    >
                        {locales[locale].name}
                    </div>
                ))
        }
    </div>
);

LanguageSelector.propTypes = {
    currentLocale: PropTypes.string,
    label: PropTypes.string,
    onMouseUp: PropTypes.func,
    showLangule: PropTypes.bool
};

export default LanguageSelector;

