const axios = require('axios').default;

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const dimensionService = axios.create({
    // axios 请求中 URL 的公共部分
    baseURL: '',
    // 设置请求超时时间
    timeout: 10 * 1000
})

// 请求拦截器
dimensionService.interceptors.request.use(config => {
    let token = localStorage.getItem('dimension_token') ? 'Token ' + localStorage.getItem('dimension_token') : ''
    config.headers.Authorization = token
    return config
})

// 响应拦截器
dimensionService.interceptors.response.use(res => {
    if ('content-disposition' in res.headers) {
        let matches = res.headers['content-disposition'].match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        if (matches !== null && matches[1]) {
            let fileName = matches[1].replace(/['"]/g, '');
            return {
                data: res.data,
                fileName
            }
        }
    }
    return res.data
}, error => {
    if (error?.response?.data) {
        const blob = error.response.data
        if (blob instanceof Blob) {
            const reader = new FileReader(); 
            reader.onload = function(event) {  
                const text = event.target.result;
                let json = ''
                try {
                    json = JSON.parse(text)?.detail || 'errorCode500'
                } catch(error) {
                    json = 'errorCode500'
                }
                window.globalMsg.tips = {
                    content: json,
                    type: 'fail',
                    data: new Date()
                }
                return Promise.reject(error);
            };  
                
            // 使用FileReader读取Blob数据  
            reader.readAsText(blob);  
        }
    }

    window.globalMsg.tips = {
        content: error.message,
        type: 'fail',
        data: new Date()
    }   

    return Promise.reject(error);
})

module.exports = dimensionService;