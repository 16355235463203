exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".model_list-item_TNATm {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    width: 100%;\n    height: 3rem;\n    margin: 0 0 1rem 0;\n    border-radius: .375rem;\n}\n.model_list-item_TNATm:nth-child(1)>:first-child {\n    margin-left: 3.375rem;\n}\n.model_list-item_TNATm:nth-child(1)>div {\n    color: black;\n}\n.model_list-item_TNATm>.model_image_5AvFs {\n    width: 1.25rem;\n    height: 1.25rem;\n    margin: 0 .625rem 0 1.5rem;\n}\n.model_item-com_hSBzN {\n    width: 8.75rem;\n    font-size: .875rem;\n    color: #333333;\n    overflow: hidden;\n    word-wrap: break-word;\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    -webkit-line-clamp: 2;\n    text-overflow: ellipsis;\n}\n.model_date_2Pf-b {\n    width: 7.5rem;\n    margin: 0 1.5rem 0 0;\n    font-size: .75rem;\n    color: #999999;\n    overflow: hidden;\n}\n.model_download_108zo {\n    color: #00a8cd;\n    cursor: pointer;\n}\n.model_dis-download_xZBSc {\n    color: #999999;\n    cursor: pointer;\n}", ""]);

// exports
exports.locals = {
	"list-item": "model_list-item_TNATm",
	"listItem": "model_list-item_TNATm",
	"image": "model_image_5AvFs",
	"item-com": "model_item-com_hSBzN",
	"itemCom": "model_item-com_hSBzN",
	"date": "model_date_2Pf-b",
	"download": "model_download_108zo",
	"dis-download": "model_dis-download_xZBSc",
	"disDownload": "model_dis-download_xZBSc"
};