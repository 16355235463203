exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".com-dialog_com-dialog-container_2oVMj {\n    position: fixed;\n    z-index: 1001;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    text-align: center;\n    background: rgba(0, 0, 0, .4);\n}\n.com-dialog_box_yaxI9 {\n    display: inline-block;\n    text-align: left;\n    vertical-align: middle;\n    width: auto;\n    max-width: 31.25rem;\n    height: auto;\n    margin: 20rem 0 0 0;\n    padding: 2.5rem 2.5rem 1.5625rem 2.5rem;\n    border-radius: .5rem;\n    background: #FFFFFF;\n    font-family: Source Han Sans CN-Regular;\n}\n.com-dialog_header_2GfC3 {\n    font-size: 1.125rem;\n    color: #333333;\n}\n.com-dialog_content_2UXb5 {\n    margin: 1rem 0 1.9375rem 0;\n    color: #666666;\n    font-size: .875rem;\n}\n.com-dialog_footer_IVtRi {\n    text-align: center;\n}\n.com-dialog_cancle_18K1D {\n    margin: 0 3.375rem 0 0 ;\n    border: 1px solid #CE8137;\n    background: #FFFFFF;\n    color: #CE8137;\n}\n.com-dialog_comfirm_3c3DD {\n    border: none;\n    background: #CE8137;\n    color: #FFFFFF;\n}\n.com-dialog_com-btn_2_8so {\n    width: auto;\n    height: auto;\n    border-radius: .375rem;\n    padding: .6875rem 2.8125rem .5625rem;\n    font-size: 1rem;\n}\n.com-dialog_cancle_18K1D:active,\n.com-dialog_comfirm_3c3DD:active {\n    scale: 0.8;\n}", ""]);

// exports
exports.locals = {
	"com-dialog-container": "com-dialog_com-dialog-container_2oVMj",
	"comDialogContainer": "com-dialog_com-dialog-container_2oVMj",
	"box": "com-dialog_box_yaxI9",
	"header": "com-dialog_header_2GfC3",
	"content": "com-dialog_content_2UXb5",
	"footer": "com-dialog_footer_IVtRi",
	"cancle": "com-dialog_cancle_18K1D",
	"comfirm": "com-dialog_comfirm_3c3DD",
	"com-btn": "com-dialog_com-btn_2_8so",
	"comBtn": "com-dialog_com-btn_2_8so"
};