import React from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux'
import {defineMessages, injectIntl} from 'react-intl';
import {setComDialog, setDialogConfirm} from '../../reducers/status'
import styles from './com-dialog.css'
const messages = defineMessages({
    cancle: {
        id: 'gui.customProcedures.cancel',
        description: '取消',
        defaultMessage: '取消'
    },
    confirm: {
        id: 'gui.soundEditor.delete',
        description: '删除',
        defaultMessage: '删除'
    }
})

class ComDialog extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className={styles.comDialogContainer}>
                <div className={styles.box}>
                    <div className={styles.header}>{this.props.comDialog.header}</div>
                    <div className={styles.content}>{this.props.comDialog.content}</div>
                    <div className={styles.footer}>
                        <input
                            className={classNames(styles.cancle, styles.comBtn)}
                            type="button"
                            value={this.props.intl.formatMessage(messages.cancle)}
                            onClick={() => this.props.onSetComDialog({
                                show: false,
                                header: null,
                                content: null,
                                type: null,
                                id: null
                            })}
                        />
                        <input
                            className={classNames(styles.comfirm, styles.comBtn)}
                            type="button"
                            value={this.props.intl.formatMessage(messages.confirm)}
                            onClick={() => this.props.onSetDialogConfirm({
                                bol: true,
                                type: this.props.comDialog.type,
                                id: this.props.comDialog.id
                            })}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    comDialog: state.scratchGui.status.com_dialog
})

const mapDispatchToProps = dispatch => ({
    onSetDialogConfirm: (params) => dispatch(setDialogConfirm(params)),
    onSetComDialog: (params) => dispatch(setComDialog(params))
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(ComDialog)) 
