import React from 'react';
import classNames from 'classnames';
import {defineMessages, injectIntl} from 'react-intl';
import {connect} from 'react-redux'
import bindAll from 'lodash.bindall';
import styles from './group-menu.css'

import MenuBarMenu from '../menu-bar/menu-bar-menu.jsx';
import { MenuItem, MenuSection } from '../menu/menu.jsx';

import {
    groupMenuStatus,
    editGroupMenu,
    setGroupName
} from '../../reducers/login.js'

const message = defineMessages({
    nothingGroup: {
        id: 'gui.menuBar.nothingGroup',
        defaultMessage: '暂无群组',
        description: '暂无群组'
    }
})

class GroupMenu extends React.Component {
    constructor(props) {
        super(props),
        bindAll(this, [
            'changeGroup'
        ])
    }
    changeGroup(index) {
        let data = {
            workspaceName: this.props.group_data[index]['name'],
            workspaceId: this.props.group_data[index]['id'],
            resourcePools: this.props.group_data[index]['resourcePools']
        }
        data = JSON.stringify(data)
        sessionStorage.setItem('space', data)
        this.props.onSetGroupName(this.props.group_data[index].name)
    }

    render() {
        return (
            <div
                className={classNames(this.props.containerStyle[0], this.props.containerStyle[1], this.props.containerStyle[2])}
                onMouseUp={() => this.props.onEditGroup(true)}
            >
                <div className={classNames(this.props.groupBtnStyle[0])}>
                    {this.props.group_name || this.props.intl.formatMessage(message.nothingGroup)}
                    <img
                        className={classNames(this.props.fileCaretStyle[0])}
                        src={this.props.imgSrc}
                    />
                </div>
                <MenuBarMenu
                    className={classNames(this.props.menuBarMenuStyle[0])}
                    open={this.props.groupMenuStatus}
                    place={this.props.isRtl ? 'right' : 'left'}
                    onRequestClose={() => this.props.onEditGroup(false)}
                >
                    <div className={styles.menuSections}>
                        {/* <MenuSection> */}
                            {this.props?.group_data?.length > 0 ? this.props.group_data.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        isRtl={this.props.isRtl}
                                        onClick={() => this.changeGroup(index)}
                                    >
                                        <div className={classNames(this.props.outBoxStyle[0])}>
                                            <div>{item.name}</div>
                                        </div>
                                    </MenuItem>
                                )
                            }) : null}
                        {/* </MenuSection> */}
                    </div>
                </MenuBarMenu>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    group_data: state.scratchGui.login.group_data,
    group_name: state.scratchGui.login.group_name,
    groupMenuStatus: groupMenuStatus(state)
})

const mapDispatchToProps = dispatch => ({
    onEditGroup: (params) => dispatch(editGroupMenu(params)),
    onSetGroupName: (params) => dispatch(setGroupName(params))
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupMenu)) 
