const axios = require('axios').default;

// create an axios instance
const minIOService = axios.create({
    baseURL: ''// url = base url + request url
})

// request interceptor
minIOService.interceptors.request.use(
    config => {
        return config
    },
    error => { // for debug
        return Promise.reject(error)
    }
)

minIOService.interceptors.response.use(

    response => {
        return response
    },
    error => {
        return Promise.reject(error)
    }
)

module.exports = minIOService
