import {defineMessages} from 'react-intl';

const LANG_MESSAGE = defineMessages({
    allTag: {
        id: 'gui.library.allTag',
        defaultMessage: 'All',
        description: 'Label for library tag to revert to all items after filtering by tag.'
    },
    sprite: {
        id: 'gui.SpriteInfo.sprite',
        description: 'Label for the name of the sprite',
        defaultMessage: 'sprite'
    },
    backdropsTab: {
        id: 'gui.gui.backdropsTab',
        description: 'Label for the name of the backdropsTab',
        defaultMessage: 'backdropsTab'
    },
    soundsTab: {
        id: 'gui.gui.soundsTab',
        description: 'Label for the name of the soundsTab',
        defaultMessage: 'soundsTab'
    },
    addTip: {
        id: 'gui.sourceMaterial.addTip',
        description: 'Label for the name of the addTip',
        defaultMessage: 'addTip'
    },
    addSpriteFromStore: {
        id: 'gui.spriteSelector.addSpriteFromStore',
        description: 'Label for the name of the addSpriteFromStore',
        defaultMessage: 'addSpriteFromStore'
    },
    keywordSearchMaterials: {
        id: 'gui.sourceMaterial.keywordSearchMaterials',
        description: 'Label for the name of the keywordSearchMaterials',
        defaultMessage: 'keywordSearchMaterials'
    },
    defaultMaterial: {
        id: 'gui.sourceMaterial.defaultMaterial',
        description: 'Label for the name of the defaultMaterial',
        defaultMessage: 'defaultMaterial'
    }
});

export default LANG_MESSAGE
