import PropTypes from 'prop-types';
import React from 'react';
import bindAll from 'lodash.bindall';
import cloneDeep from 'lodash.clonedeep'
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import VM from 'scratch-vm';

import AssetPanel from '../components/asset-panel/asset-panel.jsx';
import PaintEditorWrapper from './paint-editor-wrapper.jsx';
import {connect} from 'react-redux';
import {handleFileUpload, costumeUpload} from '../lib/file-uploader.js';
import errorBoundaryHOC from '../lib/error-boundary-hoc.jsx';
import DragConstants from '../lib/drag-constants';
import {emptyCostume} from '../lib/empty-assets';
import sharedMessages from '../lib/shared-messages';
import downloadBlob from '../lib/download-blob';

import {BitmapAdapter as V2BitmapAdapter1} from 'scratch-svg-renderer';

// import {
//     openCostumeLibrary,
//     openBackdropLibrary
// } from '../reducers/modals';

import {
    activateTab,
    SOUNDS_TAB_INDEX
} from '../reducers/editor-tab';

import {setRestore} from '../reducers/restore-deletion';
import {showStandardAlert, closeAlertWithId} from '../reducers/alerts';
import { changeSourceMaterial, setComDialog, setDialogConfirm, addRole, setCostumeSource } from '../reducers/status.js'

import addLibraryBackdropIcon from '../components/asset-panel/icon--add-backdrop-lib.svg';
import addLibraryCostumeIcon from '../components/asset-panel/icon--add-costume-lib.svg';
import fileUploadIcon from '../components/action-menu/icon--file-upload.svg';
import paintIcon from '../components/action-menu/icon--paint.svg';
import surpriseIcon from '../components/action-menu/icon--surprise.svg';
import searchIcon from '../components/action-menu/icon--search.svg';

import fileUploadIconSk from '../components/action-menu/icon--file-upload-block.png';
import paintIconSk from '../components/action-menu/icon--paint-block.svg';
import surpriseIconSk from '../components/action-menu/icon--surprise-block.svg';
import searchIconSk from '../components/action-menu/icon--search-block.svg';

import costumeLibraryContent from '../lib/libraries/costumes.json';
import backdropLibraryContent from '../lib/libraries/backdrops.json';

import { uploadBackdropToQiniu } from '../utils/index.js';

import { selectIndex } from '../reducers/panel-state.js'

let messages = defineMessages({
    addLibraryBackdropMsg: {
        defaultMessage: 'Choose a Backdrop',
        description: 'Button to add a backdrop in the editor tab',
        id: 'gui.costumeTab.addBackdropFromLibrary'
    },
    addLibraryCostumeMsg: {
        defaultMessage: 'Choose a Costume',
        description: 'Button to add a costume in the editor tab',
        id: 'gui.costumeTab.addCostumeFromLibrary'
    },
    addBlankCostumeMsg: {
        defaultMessage: 'Paint',
        description: 'Button to add a blank costume in the editor tab',
        id: 'gui.costumeTab.addBlankCostume'
    },
    addSurpriseCostumeMsg: {
        defaultMessage: 'Surprise',
        description: 'Button to add a surprise costume in the editor tab',
        id: 'gui.costumeTab.addSurpriseCostume'
    },
    addFileBackdropMsg: {
        defaultMessage: 'Upload Backdrop',
        description: 'Button to add a backdrop by uploading a file in the editor tab',
        id: 'gui.costumeTab.addFileBackdrop'
    },
    addFileCostumeMsg: {
        defaultMessage: 'Upload Costume',
        description: 'Button to add a costume by uploading a file in the editor tab',
        id: 'gui.costumeTab.addFileCostume'
    },
    addLibraryCostumeMsgSk: {
        defaultMessage: 'Choose a Costume',
        description: 'Button to add a costume in the editor tab',
        id: 'gui.spriteSelector.addSpriteFromStore'
    },
    deleteRoleHead: {
        defaultMessage: '删除“{name}”吗？',
        description: '删除“{name}”吗？',
        id: 'gui.costumeTab.deleteRoleHead'
    },
    deleteStageContent: {
        defaultMessage: '是否删除背景',
        description: '是否删除背景',
        id: 'gui.costumeTab.deleteStageContent'
    },
    deleteRoleContent: {
        defaultMessage: '是否删除造型',
        description: '是否删除造型',
        id: 'gui.costumeTab.deleteRoleContent'
    },
});

messages = {...messages, ...sharedMessages};

class CostumeTab extends React.Component {
    constructor (props) {
        super(props);
        bindAll(this, [
            'handleSelectCostume',
            'handleDeleteCostume',
            'handleDuplicateCostume',
            'handleExportCostume',
            'handleNewCostume',
            'handleNewBlankCostume',
            'handleSurpriseCostume',
            'handleSurpriseBackdrop',
            'handleFileUploadClick',
            'handleCostumeUpload',
            'handleDrop',
            'setFileInput',
            'handleCloseRole',
            'handleEditCostume',
            'clearSource'
        ]);
        const {
            editingTarget,
            sprites,
            stage
        } = props;
        const target = editingTarget && sprites[editingTarget] ? sprites[editingTarget] : stage;
        if (target && target.currentCostume) {
            this.state = {selectedCostumeIndex: target.currentCostume};
        } else {
            this.state = {selectedCostumeIndex: 0};
        }
    }
    componentWillReceiveProps (nextProps) {
        const {
            editingTarget,
            sprites,
            stage
        } = nextProps;

        const target = editingTarget && sprites[editingTarget] ? sprites[editingTarget] : stage;
        if (!target || !target.costumes) {
            return;
        }
        // 根据当前开启对应画板
        this.props.onSelectIndex(target.currentCostume)
        if (this.props.editingTarget === editingTarget) {
            // If costumes have been added or removed, change costumes to the editing target's
            // current costume.
            const oldTarget = this.props.sprites[editingTarget] ?
                this.props.sprites[editingTarget] : this.props.stage;
            // @todo: Find and switch to the index of the costume that is new. This is blocked by
            // https://github.com/LLK/scratch-vm/issues/967
            // Right now, you can land on the wrong costume if a costume changing script is running.

            if (oldTarget.costumeCount !== target.costumeCount) {
                this.setState({selectedCostumeIndex: target.currentCostume});
            }
        } else {
            // If switching editing targets, update the costume index
            this.setState({selectedCostumeIndex: target.currentCostume});
        }

        if (
            (this.props.dialogConfirm !== nextProps.dialogConfirm) && 
            ['single-role', 'single-stage'].includes(nextProps.dialogConfirm.type)
        ) {
            const restoreCostumeFun = this.props.vm.deleteCostume(nextProps.dialogConfirm.id);
            this.props.dispatchUpdateRestore({
                restoreFun: restoreCostumeFun,
                deletedItem: 'Costume'
            });

            this.props.onSetComDialog({
                show: false,
                header: null,
                content: null,
                type: null,
                id: null
            })
            this.props.onSetDialogConfirm({
                bol: false,
                type: '',
                id: null
            })
        }
    }
    async handleCloseRole () {
        // 角色系列
        if (this.props.addRole.type !== '' && this.props.addRole.type === 'all-role') {
            const restoreSprite = this.props.vm.deleteSprite(this.props.addRole.id);
            const restoreFun = () => restoreSprite().then(this.handleActivateBlocksTab);

            this.props.dispatchUpdateRestore({
                restoreFun: restoreFun,
                deletedItem: 'Sprite'
            });
            // 处理选中状态
            this.props.vm.setEditingTarget(this.props.addRole.old_id)
            // 关闭弹框
            this.props.onActivateTab(-1)
            this.clearSource()
        } 
        // 单个角色/背景
        else if (this.props.addRole.type !== '' && this.props.addRole.type === 'single-role') {
            let index = this.props.addRole.old_id !== null ? this.props.addRole.old_id : this.props.selectedIndex
            let data = Object.values(this.props.costumeSource)
            let id = this.props.vm.editingTarget.id
            
            // // 添加假数据
            // let str = Math.floor((Math.random(0, 10) * 100)) + ''
            // this.handleNewCostume(emptyCostume(str))
            
            // 从后面开始删除
            let costumes_ = this.props.vm.editingTarget.sprite.costumes_
            let old_index = costumes_.length
            let name = []
            // for (let i = costumes_.length - 1; i >= 0; i--) {
            //     let restoreCostumeFun = this.props.vm.deleteCostume(i);
            //     this.props.dispatchUpdateRestore({
            //         restoreFun: restoreCostumeFun,
            //         deletedItem: 'Costume'
            //     })
            // }

            // setTimeout(async () => {
            //     if (this.props.vm.editingTarget.sprite.costumes_.length > 0) {
            //         // 删除第一条数据
            //         let restoreCostumeFun = this.props.vm.deleteCostume(0);
            //         await this.props.dispatchUpdateRestore({
            //             restoreFun: restoreCostumeFun,
            //             deletedItem: 'Costume'
            //         })
                    // 添加原始数据
                    for (let i in data) {
                        await this.handleNewCostume(data[i], true, id)
                    }
                    // for (let i = costumes_.length - 1; i > 0; i--) {
                    for (let i = old_index - 1; i >= 0; i--) {
                        name.unshift(costumes_[i].name)
                        let restoreCostumeFun = this.props.vm.deleteCostume(i);
                        this.props.dispatchUpdateRestore({
                            restoreFun: restoreCostumeFun,
                            deletedItem: 'Costume'
                        })
                        if (i === 0) {
                            this.handleSelectCostume(index)
                            this.props.vm.editingTarget.sprite.costumes_.forEach((item, index) => {
                                item.name = name[index]
                            })
                        }
                    }
                    
            //         // 删除空白数据
            //         await this.props.dispatchUpdateRestore({
            //             restoreFun: this.props.vm.deleteCostume(0),
            //             deletedItem: 'Costume'
            //         })
            //         // 重新渲染
            //         this.props.vm.emitTargetsUpdate()
            //     }
                // 关闭弹框
                this.props.onActivateTab(-1)
                this.clearSource()
            // })
        }
    }
    handleSelectCostume (costumeIndex) {
        this.props.vm.editingTarget.setCostume(costumeIndex);
        this.setState({selectedCostumeIndex: costumeIndex});

        let target = this.props.vm.runtime.getTargetForStage().getCurrentCostume()
        let bitmapAdapter1 = new V2BitmapAdapter1();
        bitmapAdapter1.changeBackdropBitmap(target.asset.data, `image/${target.asset.dataFormat}`)
            .then((imageData) => {
                this.props.vm.updateStageTargetBitmap(
                    this.props.vm.runtime.getTargetForStage().currentCostume, 
                    imageData,
                    imageData.width / 2,
                    imageData.height / 2,
                    2
                )
            })
            .catch(() => {})
            .finally(() => { bitmapAdapter1 = null })
    }
    handleDeleteCostume (costumeIndex) {
        let is_stage = this.props.vm.editingTarget.isStage
        let header = this.props.intl.formatMessage(messages.deleteRoleHead, {name: this.props.vm.editingTarget.sprite.costumes_[costumeIndex].name})
        let content = is_stage ? 
        this.props.intl.formatMessage(messages.deleteStageContent) :
        this.props.intl.formatMessage(messages.deleteRoleContent)
        let type = is_stage ? 'single-stage' : 'single-role'

        this.props.onSetComDialog({
            show: true,
            header: header,
            content: content,
            type: type,
            id: costumeIndex
        })
        // 旧的逻辑
        // const restoreCostumeFun = this.props.vm.deleteCostume(costumeIndex);
        // this.props.dispatchUpdateRestore({
        //     restoreFun: restoreCostumeFun,
        //     deletedItem: 'Costume'
        // });
    }
    handleDuplicateCostume (costumeIndex) {
        this.props.vm.duplicateCostume(costumeIndex);
    }
    handleExportCostume (costumeIndex) {
        const item = this.props.vm.editingTarget.sprite.costumes[costumeIndex];
        const blob = new Blob([item.asset.data], {type: item.asset.assetType.contentType});
        downloadBlob(`${item.name}.${item.asset.dataFormat}`, blob);
    }
    handleEditCostume (costumeIndex) {
        this.props.vm.editingTarget.setCostume(costumeIndex);
        this.setState({selectedCostumeIndex: costumeIndex});
        this.props.onActivateCostumesTab()
    }
    handleNewCostume (costume, fromCostumeLibrary, targetId) {
        const costumes = Array.isArray(costume) ? costume : [costume];
        return Promise.all(costumes.map(c => {
            if (fromCostumeLibrary) {
                return this.props.vm.addCostumeFromLibrary(c.md5, c);
            }
            // If targetId is falsy, VM should default it to editingTarget.id
            // However, targetId should be provided to prevent #5876,
            // if making new costume takes a while
            return this.props.vm.addCostume(c.md5, c, targetId, undefined, 
                () => {
                    // 由于角色背景公用一个方法，添加判断处理背景
                    if (this.props.vm.editingTarget.isStage) {
                        let bitmapAdapter1 = new V2BitmapAdapter1();
                        bitmapAdapter1.changeBackdropBitmap(costumes[0].asset.data, `image/${costumes[0].asset.dataFormat}`)
                            .then((imageData) => {
                                this.props.vm.updateStageTargetBitmap(
                                    this.props.vm.runtime.getTargetForStage().currentCostume, 
                                    imageData,
                                    imageData.width / 2,
                                    imageData.height / 2,
                                    2
                                )
                            })
                            .catch(() => {})
                            .finally(() => { bitmapAdapter1 = null })
                    }
                }
            );
        }));
    }
    handleNewBlankCostume () {
        const name = this.props.vm.editingTarget.isStage ?
            this.props.intl.formatMessage(messages.backdrop, {index: 1}) :
            this.props.intl.formatMessage(messages.costume, {index: 1});
        this.handleNewCostume(emptyCostume(name));
        this.props.onActivateCostumesTab()

        if ((this.props.addRole.type !== 'all-role') && !this.props.addRole.is_bol) {
            // 记录当前编辑目标信息
            let data = cloneDeep(this.props.vm.editingTarget.sprite.costumes_)
            this.props.onSetCostumeSource(data)
            this.props.onAddRole({
                is_bol: true,
                type: 'single-role',
                id: '',
                old_id: this.state.selectedCostumeIndex
            })
        }
    }
    handleSurpriseCostume () {
        const item = costumeLibraryContent[Math.floor(Math.random() * costumeLibraryContent.length)];
        const vmCostume = {
            name: item.name,
            md5: item.md5ext,
            rotationCenterX: item.rotationCenterX,
            rotationCenterY: item.rotationCenterY,
            bitmapResolution: item.bitmapResolution,
            skinId: null
        };
        this.handleNewCostume(vmCostume, true /* fromCostumeLibrary */);
    }
    handleSurpriseBackdrop () {
        const item = backdropLibraryContent[Math.floor(Math.random() * backdropLibraryContent.length)];
        const vmCostume = {
            name: item.name,
            md5: item.md5ext,
            rotationCenterX: item.rotationCenterX,
            rotationCenterY: item.rotationCenterY,
            bitmapResolution: item.bitmapResolution,
            skinId: null
        };
        this.handleNewCostume(vmCostume);
    }
    handleCostumeUpload (e) {
        const storage = this.props.vm.runtime.storage;
        const targetId = this.props.vm.editingTarget.id;

        const isStage = this.props.vm.editingTarget.isStage; // true: 舞台背景、false: 不是舞台背景
        const nativeSize = this.props.vm.renderer.getNativeSize() || [];
        this.props.onShowImporting();
        handleFileUpload(e.target, (buffer, fileType, fileName, fileIndex, fileCount) => {
            costumeUpload(buffer, fileType, storage, vmCostumes => {
                vmCostumes.forEach((costume, i) => {
                    costume.name = `${fileName}${i ? i + 1 : ''}`;

                    // 上传造型这个功能，背景和角色是共同的，这里需要做一下判断，只对有 originAsset 进行这一步操作
                    if (costume.originAsset) {
                        uploadBackdropToQiniu(costume.originAsset);
                    }
                });
                this.handleNewCostume(vmCostumes, false, targetId).then(() => {
                    if (fileIndex === fileCount - 1) {
                        this.props.onCloseImporting();
                    }
                });
            }, this.props.onCloseImporting, isStage, nativeSize);
        }, this.props.onCloseImporting);
    }
    handleFileUploadClick () {
        this.fileInput.click();
    }
    handleDrop (dropInfo) {
        if (dropInfo.dragType === DragConstants.COSTUME) {
            const sprite = this.props.vm.editingTarget.sprite;
            const activeCostume = sprite.costumes[this.state.selectedCostumeIndex];
            this.props.vm.reorderCostume(this.props.vm.editingTarget.id,
                dropInfo.index, dropInfo.newIndex);
            this.setState({selectedCostumeIndex: sprite.costumes.indexOf(activeCostume)});
        } else if (dropInfo.dragType === DragConstants.BACKPACK_COSTUME) {
            this.props.vm.addCostume(dropInfo.payload.body, {
                name: dropInfo.payload.name
            });
        } else if (dropInfo.dragType === DragConstants.BACKPACK_SOUND) {
            this.props.onActivateSoundsTab();
            this.props.vm.addSound({
                md5: dropInfo.payload.body,
                name: dropInfo.payload.name
            });
        }
    }
    setFileInput (input) {
        this.fileInput = input;
    }
    formatCostumeDetails (size, optResolution) {
        // If no resolution is given, assume that the costume is an SVG
        const resolution = optResolution ? optResolution : 1;
        // Convert size to stage units by dividing by resolution
        // Round up width and height for scratch-flash compatibility
        // https://github.com/LLK/scratch-flash/blob/9fbac92ef3d09ceca0c0782f8a08deaa79e4df69/src/ui/media/MediaInfo.as#L224-L237
        return `${Math.ceil(size[0] / resolution)} x ${Math.ceil(size[1] / resolution)}`;
    }
    // 清除保存数据
    clearSource() {
        this.props.onSetCostumeSource([])
        this.props.onAddRole({
            is_bol: false,
            type: '',
            id: null,
            old_id: null
        })
    }

    render () {
        const {
            dispatchUpdateRestore, // eslint-disable-line no-unused-vars
            intl,
            isRtl,
            onNewLibraryBackdropClick,
            onNewLibraryCostumeClick,
            vm
        } = this.props;
        if (!vm.editingTarget) {
            return null;
        }

        const isStage = vm.editingTarget.isStage;
        const target = vm.editingTarget.sprite;

        const addLibraryMessage = isStage ? messages.addLibraryBackdropMsg : messages.addLibraryCostumeMsg;
        const addFileMessage = isStage ? messages.addFileBackdropMsg : messages.addFileCostumeMsg;
        const addSurpriseFunc = isStage ? this.handleSurpriseBackdrop : this.handleSurpriseCostume;
        const addLibraryFunc = isStage ? onNewLibraryBackdropClick : onNewLibraryCostumeClick;
        const addLibraryIcon = isStage ? addLibraryBackdropIcon : addLibraryCostumeIcon;
        const addLibraryCostumeMsgSkMessage = messages.addLibraryCostumeMsgSk

        const costumeData = target.costumes ? target.costumes.map(costume => ({
            name: costume.name,
            asset: costume.asset,
            details: costume.size ? this.formatCostumeDetails(costume.size, costume.bitmapResolution) : null,
            dragPayload: costume
        })) : [];

        if (this.props.istype == 1) {
            return <AssetPanel
                buttons={[
                    {
                        title: intl.formatMessage(addLibraryMessage),
                        img: addLibraryIcon,
                        onClick: addLibraryFunc
                    },
                    {
                        title: intl.formatMessage(addFileMessage),
                        img: fileUploadIconSk,
                        onClick: this.handleFileUploadClick,
                        fileAccept: '.svg, .png, .bmp, .jpg, .jpeg, .gif',
                        fileChange: this.handleCostumeUpload,
                        fileInput: this.setFileInput,
                        fileMultiple: true
                    },
                    {
                        title: intl.formatMessage(messages.addSurpriseCostumeMsg),
                        img: surpriseIconSk,
                        onClick: addSurpriseFunc
                    },
                    {
                        title: intl.formatMessage(messages.addBlankCostumeMsg),
                        img: paintIconSk,
                        onClick: this.handleNewBlankCostume
                    },
                    {
                        title: intl.formatMessage(addLibraryCostumeMsgSkMessage),
                        img: searchIconSk,
                        onClick: addLibraryFunc
                    }
                ]}
                dragType={DragConstants.COSTUME}
                isRtl={isRtl}
                items={costumeData}
                // selectedItemIndex={this.state.selectedCostumeIndex}
                selectedItemIndex={this.props.selectedIndex}
                onDeleteClick={target && target.costumes && target.costumes.length > 1 ?
                    this.handleDeleteCostume : null}
                onDrop={this.handleDrop}
                onDuplicateClick={this.handleDuplicateCostume}
                onExportClick={this.handleExportCostume}
                onItemClick={this.handleSelectCostume}
                istype= {this.props.istype}
                onEditClick={this.handleEditCostume}
            >
            </AssetPanel>
        }

        return (
            <AssetPanel
                buttons={[
                    {
                        title: intl.formatMessage(addLibraryMessage),
                        img: addLibraryIcon,
                        onClick: addLibraryFunc
                    },
                    {
                        title: intl.formatMessage(addFileMessage),
                        img: fileUploadIcon,
                        onClick: this.handleFileUploadClick,
                        fileAccept: '.svg, .png, .bmp, .jpg, .jpeg, .gif',
                        fileChange: this.handleCostumeUpload,
                        fileInput: this.setFileInput,
                        fileMultiple: true
                    },
                    {
                        title: intl.formatMessage(messages.addSurpriseCostumeMsg),
                        img: surpriseIcon,
                        onClick: addSurpriseFunc
                    },
                    {
                        title: intl.formatMessage(messages.addBlankCostumeMsg),
                        img: paintIcon,
                        onClick: this.handleNewBlankCostume
                    },
                    {
                        title: intl.formatMessage(addLibraryMessage),
                        img: searchIcon,
                        onClick: addLibraryFunc
                    }
                ]}
                dragType={DragConstants.COSTUME}
                isRtl={isRtl}
                items={costumeData}
                // selectedItemIndex={this.state.selectedCostumeIndex}
                selectedItemIndex={this.props.selectedIndex}
                onDeleteClick={target && target.costumes && target.costumes.length > 1 ?
                    this.handleDeleteCostume : null}
                onDrop={this.handleDrop}
                onDuplicateClick={this.handleDuplicateCostume}
                onExportClick={this.handleExportCostume}
                onItemClick={this.handleSelectCostume}
                onCloseRole={this.handleCloseRole}
                onEditClick={this.handleEditCostume}
            >
                {target.costumes ?
                    <PaintEditorWrapper
                        // selectedCostumeIndex={this.state.selectedCostumeIndex}
                        selectedCostumeIndex={this.props.selectedIndex}
                        onActivateTab={this.props.onActivateTab}
                        onAddRole={this.props.onAddRole}
                        onSetCostumeSource={this.props.onSetCostumeSource}
                    /> :
                    null
                }
            </AssetPanel>
        );
    }
}

CostumeTab.propTypes = {
    dispatchUpdateRestore: PropTypes.func,
    editingTarget: PropTypes.string,
    intl: intlShape,
    isRtl: PropTypes.bool,
    onActivateSoundsTab: PropTypes.func.isRequired,
    onCloseImporting: PropTypes.func.isRequired,
    onNewLibraryBackdropClick: PropTypes.func.isRequired,
    onNewLibraryCostumeClick: PropTypes.func.isRequired,
    onShowImporting: PropTypes.func.isRequired,
    sprites: PropTypes.shape({
        id: PropTypes.shape({
            costumes: PropTypes.arrayOf(PropTypes.shape({
                url: PropTypes.string,
                name: PropTypes.string.isRequired,
                skinId: PropTypes.number
            }))
        })
    }),
    stage: PropTypes.shape({
        sounds: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired
        }))
    }),
    vm: PropTypes.instanceOf(VM)
};

const mapStateToProps = state => ({
    editingTarget: state.scratchGui.targets.editingTarget,
    isRtl: state.locales.isRtl,
    sprites: state.scratchGui.targets.sprites,
    stage: state.scratchGui.targets.stage,
    dragging: state.scratchGui.assetDrag.dragging,
    dialogConfirm: state.scratchGui.status.dialog_confirm,
    addRole: state.scratchGui.status.add_role,
    costumeSource: state.scratchGui.status.costume_source
});

const mapDispatchToProps = dispatch => ({
    onActivateSoundsTab: () => dispatch(activateTab(SOUNDS_TAB_INDEX)),
    onNewLibraryBackdropClick: e => {
        e.preventDefault();
        // dispatch(openBackdropLibrary()); // 原始打开背景素材库
        dispatch(changeSourceMaterial({
            status: true,
            type: 2
        }))
    },
    onNewLibraryCostumeClick: e => {
        e.preventDefault();
        // dispatch(openCostumeLibrary()); // 原始打开角色素材库
        dispatch(changeSourceMaterial({
            status: true,
            type: 1
        }))
    },
    dispatchUpdateRestore: restoreState => {
        dispatch(setRestore(restoreState));
    },
    onCloseImporting: () => dispatch(closeAlertWithId('importingAsset')),
    onShowImporting: () => dispatch(showStandardAlert('importingAsset')),
    onSelectIndex: (param) => dispatch(selectIndex(param)),
    onSetComDialog: (params) => dispatch(setComDialog(params)),
    onSetDialogConfirm: (params) => dispatch(setDialogConfirm(params)),
    onAddRole: (params) => dispatch(addRole(params)),
    onSetCostumeSource: (params) => dispatch(setCostumeSource(params))
});

export default errorBoundaryHOC('Costume Tab')(
    injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps
    )(CostumeTab))
);
