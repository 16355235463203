import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import stopAllIcon from '../../../skimg/stop.png';
import styles from './stop-all.css';


const StopAllComponent = function (props) {
    const {
        active,
        className,
        onClick,
        title,
        ...componentProps
    } = props;
    return (
        <div className={styles.button}
            onClick={onClick}
        >
            <img
                className={classNames(
                    className,
                    styles.stopAll,
                    {
                        [styles.isActive]: active
                    }
                )}
                draggable={false}
                src={stopAllIcon}
                title={title}
                
                {...componentProps}
            />
            <span className={styles.title}>{title}</span>
        </div>
       
    );
};

StopAllComponent.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string
};

StopAllComponent.defaultProps = {
    active: false,
    title: 'Stop'
};

export default StopAllComponent;
