import React from "react";
import styles from './download-model.css'
import close from '../../../../static/assets/icon_close.png';
import suc from '../img/download-suc.png'
import fail from '../img/download-fail.png'
import classNames from "classnames";

const DownloadState = (props) => {
    const {
        state,
        setDownStateShow
    } = props

    return (
        <div className={styles.comModelContainer}>
            <div className={styles.downloadBox}>
                <div className={styles.header}>
                    <img className={styles.close} src={close} onClick={() => setDownStateShow(false)} />
                </div>
                <div className={styles.content}>
                    <div className={styles.tipsBox}>
                        <img src={state ? suc : fail} alt="" />
                        <span>{state ? props.intl.formatMessage(props.LANG_MESSAGE.downloadSuccessful) + '！' : props.intl.formatMessage(props.LANG_MESSAGE.downloadFailed) + '！'}</span>
                    </div>
                </div>
                <div className={styles.downStateFooter}>
                    <input
                        className={classNames(styles.comBtn, styles.cancle)}
                        type="button" 
                        value={props.intl.formatMessage(props.LANG_MESSAGE.close)}
                        onClick={() => setDownStateShow(false)}
                    />
                    {/* {!state ? (
                        <input
                            className={classNames(styles.comBtn, styles.confirm)}
                            type="button"
                            value={props.intl.formatMessage(props.LANG_MESSAGE.downloadAgain)}
                        />
                    ) : null} */}
                </div>
            </div>
        </div>
    )
}

export default DownloadState
