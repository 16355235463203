import classNames from 'classnames';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {defineMessages, FormattedMessage, injectIntl, intlShape} from 'react-intl';
import {connect} from 'react-redux';
import MediaQuery from 'react-responsive';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import tabStyles from 'react-tabs/style/react-tabs.css';
import VM from 'scratch-vm';
import Renderer, { style } from 'scratch-render';

import Blocks from '../../containers/blocks.jsx';
import CostumeTab from '../../containers/costume-tab.jsx';
import TargetPane from '../../containers/target-pane.jsx';
import SoundTab from '../../containers/sound-tab.jsx';
import StageWrapper from '../../containers/stage-wrapper.jsx';
import Loader from '../loader/loader.jsx';
import Box from '../box/box.jsx';
import MenuBar from '../menu-bar/menu-bar.jsx';
import Watermark from '../../containers/watermark.jsx';

import WebGlModal from '../../containers/webgl-modal.jsx';
import TipsLibrary from '../../containers/tips-library.jsx';
import Cards from '../../containers/cards.jsx';
import Alerts from '../../containers/alerts.jsx';
import DragLayer from '../../containers/drag-layer.jsx';
import ConnectionModal from '../../containers/connection-modal.jsx';
import TelemetryModal from '../telemetry-modal/telemetry-modal.jsx';
import Login from '../login/login.jsx';
import Work from '../work/work.jsx';
import Dialog from '../work/dialog.jsx';
import DialogZhiya from '../workZhiya/dialog.jsx'
import Message from '../message/message.jsx'
import Dimension from '../dimension/dimension.jsx'
import SourceMaterial from '../source-material/source-material.jsx';
import ComDialog from '../com-dialog/com-dialog.jsx'
import BlocklyDialog from '../blocks-dialog/blockly_dialog.jsx';
import CreateModel from '../create-model/create-model.jsx'
import Guide from '../guide/guide.jsx'
// import SelectModel from '../select-model/select-model.jsx'
import TakePhoto from '../take-photo/take-photo.jsx'

import layout, {STAGE_SIZE_MODES} from '../../lib/layout-constants';
import {resolveStageSize} from '../../lib/screen-utils';

import styles from './gui.css';
import addExtensionIcon from './icon--code_add.svg';
import menuIcon from './menu.png'

// 无用引入
// import CostumeLibrary from '../../containers/costume-library.jsx';
// import BackdropLibrary from '../../containers/backdrop-library.jsx';
// import Backpack from '../../containers/backpack.jsx';
// import { changeWork } from '../../reducers/status.js';
// import codeIcon from '../../skimg/code.png';
// import costumesIcon from '../../skimg/sculpt.png';
// import soundsIcon from '../../skimg/voice.png';

const messages = defineMessages({
    addExtension: {
        id: 'gui.gui.addExtension',
        description: 'Button to add an extension in the target pane',
        defaultMessage: 'Add Extension'
    }
});

// Cache this value to only retrieve it once the first time.
// Assume that it doesn't change for a session.
let isRendererSupported = null;

const GUIComponent = props => {
    const {
        accountNavOpen,
        activeTabIndex,
        alertsVisible,
        authorId,
        authorThumbnailUrl,
        authorUsername,
        basePath,
        backdropLibraryVisible,
        backpackHost,
        blocksTabVisible,
        cardsVisible,
        canChangeLanguage,
        canCreateNew,
        canEditTitle,
        canManageFiles,
        canRemix,
        canSave,
        canCreateCopy,
        canShare,
        canUseCloud,
        children,
        connectionModalVisible,
        costumeLibraryVisible,
        costumesTabVisible,
        enableCommunity,
        intl,
        isCreating,
        isFullScreen,
        isPlayerOnly,
        isRtl,
        isShared,
        isTelemetryEnabled,
        isUIStyle,
        isSKPack,
        isSKHelp,
        isSKSetting,
        isResourceSpace,
        loading,
        logo,
        renderLogin,
        onClickAbout,
        onClickAccountNav,
        onCloseAccountNav,
        onLogOut,
        onOpenRegistration,
        onToggleLoginOpen,
        onActivateCostumesTab,
        onActivateSoundsTab,
        onActivateTab,
        onClickLogo,
        onChangeUIStyle,
        onExtensionButtonClick,
        onProjectTelemetryEvent,
        onRequestCloseBackdropLibrary,
        onRequestCloseCostumeLibrary,
        onRequestCloseTelemetryModal,
        onSeeCommunity,
        onShare,
        onShowPrivacyPolicy,
        onStartSelectingFileUpload,
        onTelemetryModalCancel,
        onTelemetryModalOptIn,
        onTelemetryModalOptOut,
        onRemoveToken,
        showComingSoon,
        soundsTabVisible,
        stageSizeMode,
        targetIsStage,
        telemetryModalVisible,
        tipsLibraryVisible,
        targetPanetype,
        vm,
        toolType,
        iframe_url,
        onOpenRoleTab,  // 打开角色弹窗
        onOpenSoundsTab, // 打开声音弹窗
        login_show,
        token,
        cas_token,
        manage_token,
        selected_index,
        roleStatus,
        workVisible,
        guideVisible,
        guideMenu,
        isWork, // 判断是否是教学平台保存作品
        isFile, // 判断是否是教学平台保存课程资源
        dialogVisible, // 作品保存弹窗
        dialogVisibleZhiya, // 社区保存作品弹窗标识
        workType,
        task_detail,
        onChangeWork,
        onChangeGuide,
        onChangeGuideMenu,
        onChangeGuideType,
        messageVisible,
        dimensionVisible,
        onChangeMessage,
        onSetMessage,
        onSetDimension,
        onSetDimensionToken,
        onSetCodeUserMessage,
        onSetGroupData,
        onSetBlocklyDialog,
        sourceMaterialStatus,
        comDialog,
        dialogconfirm,
        blocklyDialog,
        createModel,
        selectModel,
        debugState,
        onSetDebugState,
        onSetCodeStatus,
        onSetCreateModel,
        onSetSelectModel,
        iframeState,
        iframeConnectState,
        onShowIframe,
        onSetIframeParams,
        checkIframeConnect,
        takePhotoState,
        onCloseTakePhoto,
        onOpenTakePhoto,
        ...componentProps
    } = omit(props, 'dispatch');
    
    if (children) {
        return <Box {...componentProps}>{children}</Box>;
    }
    const tabClassNames = {
        tabs: styles.tabs,
        tabs1: styles.tabs1,
        tab: classNames(tabStyles.reactTabsTab, styles.tab),
        tabList: classNames(tabStyles.reactTabsTabList, styles.tabList),
        tabPanel: classNames(tabStyles.reactTabsTabPanel, styles.tabPanel),
        tabPanelSelected: classNames(tabStyles.reactTabsTabPanelSelected, styles.isSelected),
        tabSelected: classNames(tabStyles.reactTabsTabSelected, styles.isSelected)
    };

    if (isRendererSupported === null) {
        isRendererSupported = Renderer.isSupported();
    }

    const takePhotoConfirm = function(callback) {
        let { photo, close, name, address } = callback
        if (close) close()
        let str = takePhotoState.type === 'user' ? JSON.stringify({
            image: photo,
            name,
            address
        }) : photo
        takePhotoState.callback(str)
        onCloseTakePhoto()
    }
    
    return (<MediaQuery minWidth={layout.fullSizeMinWidth}>{isFullSize => {
        const stageSize = resolveStageSize(stageSizeMode, isFullSize); //屏幕大小分类 large，small
        return isPlayerOnly ? (
            <StageWrapper
                isFullScreen={isFullScreen}
                isRendererSupported={isRendererSupported}
                isRtl={isRtl}
                isUIStyle={isUIStyle}
                loading={loading}
                stageSize={STAGE_SIZE_MODES.large}
                vm={vm}
                intl={intl}
            >
                {alertsVisible ? (
                    <Alerts className={styles.alertsContainer} />
                ) : null}
            </StageWrapper>
        ) : (
            <Box
                className={styles.pageWrapper}
                dir={isRtl ? 'rtl' : 'ltr'}
                {...componentProps}
            >
                {telemetryModalVisible ? (
                    <TelemetryModal
                        isRtl={isRtl}
                        isTelemetryEnabled={isTelemetryEnabled}
                        onCancel={onTelemetryModalCancel}
                        onOptIn={onTelemetryModalOptIn}
                        onOptOut={onTelemetryModalOptOut}
                        onRequestClose={onRequestCloseTelemetryModal}
                        onShowPrivacyPolicy={onShowPrivacyPolicy}
                    />
                ) : null}
                {loading ? (
                    <Loader />
                ) : null}
                {isCreating ? (
                    <Loader messageId="gui.loader.creating" />
                ) : null}
                {isRendererSupported ? null : (
                    <WebGlModal isRtl={isRtl} />
                )}
                {tipsLibraryVisible ? (
                    <TipsLibrary />
                ) : null}
                {cardsVisible ? (
                    <Cards />
                ) : null}
                {alertsVisible ? (
                    <Alerts className={styles.alertsContainer} />
                ) : null}
                {connectionModalVisible ? (
                    <ConnectionModal
                        vm={vm}
                    />
                ) : null}
                {/* 单个角色/角色编辑 素材库弹框 */}
                {/* {costumeLibraryVisible ? (
                    <CostumeLibrary
                        vm={vm}
                        onRequestClose={onRequestCloseCostumeLibrary}
                    />
                ) : null} */}
                {/* 背景/背景编辑 素材库弹框 */}
                {/* {backdropLibraryVisible ? (
                    <BackdropLibrary
                        vm={vm}
                        onRequestClose={onRequestCloseBackdropLibrary}
                    />
                ) : null} */}
                {/* 默认登录弹框 */}
                {login_show ? (
                    <Login intl={intl} />
                ) : null}
                <MenuBar
                    accountNavOpen={accountNavOpen}
                    authorId={authorId}
                    authorThumbnailUrl={authorThumbnailUrl}
                    authorUsername={authorUsername}
                    canChangeLanguage={canChangeLanguage}
                    canCreateCopy={canCreateCopy}
                    canCreateNew={canCreateNew}
                    canEditTitle={canEditTitle}
                    canManageFiles={canManageFiles}
                    canRemix={canRemix}
                    canSave={canSave}
                    canShare={canShare}
                    className={styles.menuBarPosition}
                    enableCommunity={enableCommunity}
                    isShared={isShared}
                    isSKPack={isSKPack}
                    isSKHelp={isSKHelp}
                    isSKSetting={isSKSetting}
                    isResourceSpace={isResourceSpace}
                    logo={logo}
                    renderLogin={renderLogin}
                    showComingSoon={showComingSoon}
                    onClickAbout={onClickAbout}
                    onClickAccountNav={onClickAccountNav}
                    onClickLogo={onClickLogo}
                    onCloseAccountNav={onCloseAccountNav}
                    onChangeUIStyle={onChangeUIStyle}
                    onLogOut={onLogOut}
                    onOpenRegistration={onOpenRegistration}
                    onProjectTelemetryEvent={onProjectTelemetryEvent}
                    onSeeCommunity={onSeeCommunity}
                    onShare={onShare}
                    onStartSelectingFileUpload={onStartSelectingFileUpload}
                    onToggleLoginOpen={onToggleLoginOpen}
                    token={token}
                    cas_token={cas_token}
                    manage_token={manage_token}
                    task_detail={task_detail}
                    guideVisible={guideVisible}
                    onChangeWork={onChangeWork}
                    onChangeMessage={onChangeMessage}
                    onChangeGuide={onChangeGuide}
                    onChangeGuideType={onChangeGuideType}
                    onSetCodeUserMessage={onSetCodeUserMessage}
                    onSetGroupData={onSetGroupData}
                    onShowIframe={onShowIframe}
                    checkIframeConnect={checkIframeConnect}
                    blocklyDialog={blocklyDialog}
                />
                {/* <Box style={{background: '#FEF9E9'}}>
                    <TabList className={tabClassNames.tabList} style={{margin: '0px'}}>
                        <Tab className={tabClassNames.tab}>
                            <img
                                draggable={false}
                                src={codeIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Code"
                                description="Button to get to the code panel"
                                id="gui.gui.codeTab"
                            />
                        </Tab>
                        <Tab
                            className={tabClassNames.tab}
                            onClick={onOpenRoleTab}
                        >
                            <img
                                draggable={false}
                                src={costumesIcon}
                            />
                            {targetIsStage ? (
                                <FormattedMessage
                                    defaultMessage="Backdrops"
                                    description="Button to get to the backdrops panel"
                                    id="gui.gui.backdropsTab"
                                />
                            ) : (
                                <FormattedMessage
                                    defaultMessage="Costumes"
                                    description="Button to get to the costumes panel"
                                    id="gui.gui.costumesTab"
                                />
                            )}
                        </Tab>
                        <Tab
                            className={tabClassNames.tab}
                            // onClick={onActivateSoundsTab}
                            onClick={onOpenSoundsTab}
                        >
                            <img
                                draggable={false}
                                src={soundsIcon}
                            />
                            <FormattedMessage
                                defaultMessage="Sounds"
                                description="Button to get to the sounds panel"
                                id="gui.gui.soundsTab"
                            />
                        </Tab>
                    </TabList>
                </Box> */}
               
                <Box id={'flexWrapper'} className={classNames(styles.bodyWrapper, styles.flexWrapper)}>
                    {/* 引导功能 */}
                    {guideVisible ? (
                        <>
                            {guideMenu ? (
                                <Guide 
                                    vm={vm}
                                    intl={intl}
                                    onChangeMessage={onChangeMessage}
                                />
                            ) : null}

                            <div className={classNames({
                                [styles.guideBox]: !guideMenu
                            })}>
                                <div 
                                    className={classNames(styles.guideMenu, {
                                        [styles.guideVisible]: !guideMenu
                                    })}
                                    onClick={() => onChangeGuideMenu(!guideMenu)}
                                >
                                    <img className={classNames(styles.guideMenu, {
                                        [styles.guideTransform]: !guideMenu
                                    })} src={menuIcon} />
                                </div>
                            </div>
                        </>
                    ) : null}
                    <Box className={classNames(styles.stageWrapper, styles[stageSize])}>
                        <StageWrapper
                            isFullScreen={isFullScreen}
                            isRendererSupported={isRendererSupported}
                            isRtl={isRtl}
                            stageSize={stageSize}
                            isUIStyle={isUIStyle}
                            vm={vm}
                            intl={intl}
                        />
                        <Box className={classNames(styles.targetWrapper, styles.targetWrapperHeight)}>
                            <TargetPane
                                stageSize={stageSize}
                                targetPanetype= {1}
                                vm={vm}
                                selectedIndex={selected_index}
                                intl={intl}
                                dialogconfirm={dialogconfirm}
                            />
                        </Box>
                    </Box>
                    <Box className={styles.wrapper2}>
                        <Box className={styles.wrapperBody}>
                            <TargetPane
                                stageSize={stageSize}
                                targetPanetype= {2}
                                vm={vm}
                                selectedIndex={selected_index}
                                intl={intl}
                                dialogconfirm={dialogconfirm}
                            />
                            <Box id={'targe_id'} className={styles.targeTab} style={{background: "hsla(51, 41%, 97%, 1)"}}>
                                <CostumeTab 
                                    vm={vm} 
                                    istype={1} 
                                    selectedIndex={selected_index} 
                                    onActivateTab={onActivateTab}
                                    onActivateCostumesTab={onActivateCostumesTab} 
                                />
                            </Box>
                            {/* 下拉添加角色 */}
                            {roleStatus ? 
                                (<Box id={'open_targe_id'} className={styles.targeTabAddRole}>
                                    <TargetPane
                                        stageSize={stageSize}
                                        targetPanetype= {7}
                                        vm={vm}
                                        selectedIndex={selected_index}
                                        intl={intl}
                                        dialogconfirm={dialogconfirm}
                                        onShowIframe={onShowIframe}
                                        checkIframeConnect={checkIframeConnect}
                                    />
                                </Box>) : null
                            }
                        </Box>
                    </Box>
                    <Box className={ styles.editorWrapper }>
                        <Tabs
                            forceRenderTabPanel
                            className={tabClassNames.tabs1}
                            selectedIndex={activeTabIndex}
                            selectedTabClassName={tabClassNames.tabSelected}
                            selectedTabPanelClassName={tabClassNames.tabPanelSelected}
                            onSelect={onActivateTab}
                        >
                            <Box className={styles.blocksWrapper}>
                                <Blocks
                                    canUseCloud={canUseCloud}
                                    grow={1}
                                    // isVisible={blocksTabVisible}
                                    options={{
                                        media: `${basePath}static/blocks-media/`
                                    }}
                                    stageSize={stageSize}
                                    vm={vm}
                                    onSetCreateModel={onSetCreateModel}
                                />
                            </Box>
                            <Box className={styles.extensionButtonContainer} id={"extensiond-id"}>
                                <button
                                    className={styles.extensionButton}
                                    title={intl.formatMessage(messages.addExtension)}
                                    onClick={onExtensionButtonClick}
                                >
                                    <img
                                        // className={styles.extensionButtonIcon}
                                        draggable={false}
                                        src={addExtensionIcon}
                                    />
                                </button>
                            </Box>
                            <Box className={styles.watermark}>
                                <Watermark />
                            </Box>
                        </Tabs>
                    </Box>
                    {/* 作业功能 */}
                    {(workVisible) ?
                        (<Work 
                            vm={vm}
                            intl={intl}
                            workType={workType}
                            task_detail={task_detail}
                            onChangeMessage={onChangeMessage}
                        />
                        ) : null
                    }
                    
                    {/* workType */}
                    {( workType && !isWork && !isFile ) ?
                        (<div className={classNames({
                            [styles.workBox]: !workVisible
                        })}
                        >
                            <div 
                                className={classNames({
                                    [styles.workMenu]: workVisible,
                                    [styles.workMenuBlock]: !workVisible
                                })}
                                onClick={() => onChangeWork(workVisible)}
                            >
                                <img className={styles.workImage} src={menuIcon} />
                            </div>
                        </div>) : null
                    }
                </Box>
                {/* 角色/背景/声音画板功能 */}
                {costumesTabVisible ? 
                    (
                        <Box id="role-targe" className={styles.roleTarge}>
                            <Box className={styles.roleTargeBox}>
                                <CostumeTab 
                                    vm={vm} 
                                    selectedIndex={selected_index} 
                                    onActivateTab={onActivateTab}
                                    onActivateCostumesTab={onActivateCostumesTab} 
                                />
                            </Box>
                        </Box>
                    ) : null
                }
                {soundsTabVisible ?
                    (
                        <Box id="sound-targe" className={styles.roleTarge} >
                            <Box className={styles.roleTargeBox}>
                                <SoundTab vm={vm} />
                                {/* {soundsTabVisible ? <SoundTab vm={vm} /> : null} */}
                            </Box>
                        </Box>
                    ) : null
                }
                <DragLayer />
                {messageVisible ?
                    (
                        <Message />
                    ) : null
                }
                {/* 标注 */}
                {dimensionVisible ? (<Dimension vm={vm} onChangeMessage={onChangeMessage} intl={intl} />) : null}
                {/* ai教学管理保存作品弹窗 */}
                {dialogVisible ? (<Dialog vm={vm} onChangeMessage={onChangeMessage} intl={intl}></Dialog>) : null}
                {/* 智芽社区保存作品弹窗 */}
                {dialogVisibleZhiya ? (<DialogZhiya vm={vm} onChangeMessage={onChangeMessage} intl={intl}></DialogZhiya>) : null}
                {/* 素材库 */}
                {sourceMaterialStatus ? (<SourceMaterial vm={vm} costumesTabVisible={costumesTabVisible} />) : null}
                {/* 通用弹窗 */}
                {comDialog?.show ? <ComDialog intl={intl} /> : null}
                {/* blocks弹框 */}
                {blocklyDialog?.show ? (
                    <BlocklyDialog 
                        intl={intl} 
                        // token={token} 
                        cas_token={cas_token}
                        createModel={createModel} 
                        onSetCreateModel={props.onSetCreateModel} 
                        onChangeMessage={onChangeMessage}
                    />
                    // <iframe 
                    //     className={styles.comIframe}
                    //     id="com-iframe"
                    //     src="http://localhost:5173/" 
                    //     // frameborder="0"
                    // ></iframe>
                ) : null }
                {/* 创建模型 */}
                {createModel?.show ? (
                    <CreateModel 
                        vm={vm}
                        intl={intl} 
                        onSetCreateModel={props.onSetCreateModel} 
                        onSetBlocklyDialog={props.onSetBlocklyDialog}
                        createModel={createModel} 
                        onChangeMessage={onChangeMessage}
                    />
                ) : null}
                {/* 选择模型 */}
                {/* {selectModel?.show ? (
                    <SelectModel 
                        intl={intl}
                        header={'选择模型类型'}
                        selectModel={props.selectModel}
                        onSetSelectModel={props.onSetSelectModel}
                        onSetBlocklyDialog={props.onSetBlocklyDialog}
                    />
                ) : null} */}
                {/* 通用iframe模块 */}
                {iframeState ? 
                    <iframe
                        className={styles.comIframe}
                        id="com-iframe"
                        // src="http://localhost:5173/"
                        src={iframe_url + '/'}
                    ></iframe> : 
                    null 
                }
                {/* 拍照 */}
                {takePhotoState.show ? (
                    <TakePhoto 
                        vm={vm}
                        intl={intl}
                        type={takePhotoState.type}
                        ref="takePhoto"
                        changeTakePhoto={() => {
                            takePhotoState.callback(null)
                            onCloseTakePhoto()
                        }}
                        takePhotoConfirm={takePhotoConfirm}
                        onChangeMessage={onChangeMessage}
                    />
                ) : null}
            </Box>
        );
    }}</MediaQuery>);
};

GUIComponent.propTypes = {
    accountNavOpen: PropTypes.bool,
    activeTabIndex: PropTypes.number,
    authorId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    authorThumbnailUrl: PropTypes.string,
    authorUsername: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]), // can be false
    backdropLibraryVisible: PropTypes.bool,
    backpackHost: PropTypes.string,
    basePath: PropTypes.string,
    blocksTabVisible: PropTypes.bool,
    canChangeLanguage: PropTypes.bool,
    canCreateCopy: PropTypes.bool,
    canCreateNew: PropTypes.bool,
    canEditTitle: PropTypes.bool,
    canManageFiles: PropTypes.bool,
    canRemix: PropTypes.bool,
    canSave: PropTypes.bool,
    canShare: PropTypes.bool,
    canUseCloud: PropTypes.bool,
    cardsVisible: PropTypes.bool,
    children: PropTypes.node,
    costumeLibraryVisible: PropTypes.bool,
    costumesTabVisible: PropTypes.bool,
    enableCommunity: PropTypes.bool,
    intl: intlShape.isRequired,
    isCreating: PropTypes.bool,
    isFullScreen: PropTypes.bool,
    isPlayerOnly: PropTypes.bool,
    isRtl: PropTypes.bool,
    isShared: PropTypes.bool,
    isUIStyle: PropTypes.bool,
    isSKPack: PropTypes.bool,
    isSKHelp: PropTypes.bool,
    isSKSetting: PropTypes.bool,
    loading: PropTypes.bool,
    logo: PropTypes.string,
    onActivateCostumesTab: PropTypes.func,
    onActivateSoundsTab: PropTypes.func,
    onActivateTab: PropTypes.func,
    onClickAccountNav: PropTypes.func,
    onClickLogo: PropTypes.func,
    onCloseAccountNav: PropTypes.func,
    onChangeUIStyle: PropTypes.func,
    onExtensionButtonClick: PropTypes.func,
    onLogOut: PropTypes.func,
    onOpenRegistration: PropTypes.func,
    onRequestCloseBackdropLibrary: PropTypes.func,
    onRequestCloseCostumeLibrary: PropTypes.func,
    onRequestCloseTelemetryModal: PropTypes.func,
    onSeeCommunity: PropTypes.func,
    onShare: PropTypes.func,
    onShowPrivacyPolicy: PropTypes.func,
    onStartSelectingFileUpload: PropTypes.func,
    onTabSelect: PropTypes.func,
    onTelemetryModalCancel: PropTypes.func,
    onTelemetryModalOptIn: PropTypes.func,
    onTelemetryModalOptOut: PropTypes.func,
    onToggleLoginOpen: PropTypes.func,
    onRemoveToken: PropTypes.func,
    renderLogin: PropTypes.func,
    showComingSoon: PropTypes.bool,
    soundsTabVisible: PropTypes.bool,
    stageSizeMode: PropTypes.oneOf(Object.keys(STAGE_SIZE_MODES)),
    targetIsStage: PropTypes.bool,
    telemetryModalVisible: PropTypes.bool,
    tipsLibraryVisible: PropTypes.bool,
    targetPanetype: PropTypes.number,
    vm: PropTypes.instanceOf(VM).isRequired,
    roleStatus: PropTypes.bool,
    workVisible: PropTypes.bool,
    guideVisible: PropTypes.bool,
    guideMenu: PropTypes.bool,
    isWork: PropTypes.bool, // 判断是否是教学平台保存作品
    isFile: PropTypes.bool, // 判断是否是教学平台保存课程资源
    workType: PropTypes.string,
    messageVisible: PropTypes.bool,
    dimensionVisible: PropTypes.bool,
    onSetMessage: PropTypes.func,
    onChangeMessage: PropTypes.func,
    dialogvisible: PropTypes.bool,
    dialogVisibleZhiya: PropTypes.bool,
    onSetDimension: PropTypes.func,
    onSetDimensionToken: PropTypes.func,
    onSetCodeUserMessage: PropTypes.func,
    onSetGroupData: PropTypes.func,
    onSetBlocklyDialog: PropTypes.func,
    sourceMaterialStatus: PropTypes.bool
};
GUIComponent.defaultProps = {
    backpackHost: null,
    basePath: './',
    canChangeLanguage: true,
    canCreateNew: false,
    canEditTitle: true,     // 显示作品名输入框
    canManageFiles: true,
    canRemix: false,
    canSave: false,
    canCreateCopy: false,
    canShare: false,
    canUseCloud: false,
    enableCommunity: false,
    isCreating: false,
    isShared: false,
    loading: false,
    showComingSoon: false,
    stageSizeMode: STAGE_SIZE_MODES.large,
    targetPanetype: 1,   // 角色(初始化角色)
    roleStatus: false,
    workVisible: false,
    guideVisible: false,
    guideMenu: false,
    isWork: false, // 判断是否是教学平台保存作品
    isFile: false, //判断是否是教学平台保存课程资源
    sourceMaterialStatus: false
};

const mapStateToProps = state => ({
    // This is the button's mode, as opposed to the actual current state
    stageSizeMode: state.scratchGui.stageSize.stageSize,
    roleStatus: state.scratchGui.status.role,
    task_detail: state.scratchGui.status.task_detail,
    // dialogVisible: state.scratchGui.work.dialogVisible
    sourceMaterialStatus: state.scratchGui.status.source_material,
    dialogconfirm: state.scratchGui.status.dialog_confirm,
    comDialog: state.scratchGui.status.com_dialog,
    blocklyDialog: state.scratchGui.status.blockly_dialog,
    createModel: state.scratchGui.status.create_model,
    selectModel: state.scratchGui.status.select_model
});

// const mapDispatchToProps = dispatch => ({
//     onChangeWork: (status) => {
//         dispatch(changeWork(status));
//         setTimeout(() => window.dispatchEvent(new Event('resize')))
//     }
// })

export default injectIntl(connect(
    mapStateToProps,
    // mapDispatchToProps
)(GUIComponent));
