import React from 'react';
import classNames from 'classnames';
import styles from './train-model.css';
import close from '../../../../static/assets/icon_close.png';
import more from '../img/train-model-more.png'
import change from '../img/train-model-change.png'
import upBg from '../img/up-bg.png'
import cBg from '../img/c.png'
import bg from '../img/train-model-bg.gif'
// import bg from '../img/train-model-bg.png'
// import start from '../img/train-status-start.png'
// import refresh from '../img/train-status-refresh.png'
// import success from '../img/train-status-success.png'
import { Menu, Dropdown, Select } from 'antd';

const TrainModel = (props) => {
    const menu = (
        <Menu onClick={props.menuClick}>
          <Menu.Item key="ordinary">
            <div>{props.intl.formatMessage(props.LANG_MESSAGE.normalMode)}</div>
          </Menu.Item>
          <Menu.Item key="senior">
            <div>{props.intl.formatMessage(props.LANG_MESSAGE.advancedMode)}</div>
          </Menu.Item>
        </Menu>
    );

    const { Option } = Select;

    return (
        <div className={styles.box}>
            <div className={styles.header}>
                <div>{props.intl.formatMessage(props.LANG_MESSAGE.trainingModel)}</div>
                <div className={styles.rightHeader}>
                    <div className={styles.menuContent}>
                        <img src={change} onClick={() => props.changeModeType('train')} />
                        <Dropdown overlay={menu}>
                            <div className={styles.menuTitle}>
                                <span>{props.type === 'ordinary' ? props.intl.formatMessage(props.LANG_MESSAGE.normalMode) : props.intl.formatMessage(props.LANG_MESSAGE.advancedMode)}</span>
                                <img className={styles.more} src={more} />
                            </div>
                        </Dropdown>
                    </div>

                    <img className={styles.closeIcon} src={close} onClick={props.closeDialog} />
                </div>
            </div>
            <div className={styles.content}>
                <ul className={styles.config}>
                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.MODEL_NAME)}</span>
                        <Select 
                            value={props.model_list_val}
                            style={{ width: 120 }} 
                            onChange={props.changeModel}
                            onDropdownVisibleChange={props.onDropdownModelChange}
                        >
                            {props.model_list.map((item, index) => {
                                return (
                                    <Option key={index} value={item.modelVersionName}>{item.modelVersionName}</Option>
                                )
                            })}
                        </Select>

                        <input className={styles.dataSetBtn} type="button" value={props.intl.formatMessage(props.LANG_MESSAGE.trainingModel)} onClick={() => props.selectModel('train')} />
                    </li>

                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.DATA_SET)}</span>
                        <input className={styles.dataSetVal} type="text" value={props.data?.data_set?.name || ''} disabled />
                        <input className={styles.dataSetBtn} type="button" value={props.intl.formatMessage(props.LANG_MESSAGE.reSelect)} onClick={() => props.selectDataSet('train')} />
                    </li>

                    <li className={classNames(styles.round, styles.comItem)}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.trainingRounds)}</span>
                        <input type="number" value={props.round} onChange={props.setRound} />
                    </li>

                    {props.type === 'senior' ? (
                        <>
                            <li className={classNames(styles.imageSize, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.imageSize)}</span>
                                <input type="number" value={props.image_size} onChange={props.setImageSize} />
                            </li>

                            <li className={classNames(styles.batchSample, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.batchSampleSize)}</span>
                                <input type="number" value={props.batch_sample} onChange={props.setBatchSample} />
                            </li>

                            <li className={classNames(styles.learningRate, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.learningRate)}</span>
                                <input type="number" value={props.learning_rate} onChange={props.setLearningRate} />
                            </li>

                            <li className={classNames(styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.customParameters)}</span>
                                <textarea 
                                    className={styles.verification}
                                    cols="40" 
                                    rows="10" 
                                    value={props.custom_parame} 
                                    onChange={(e) => props.setCustomParame(e, 'train')}
                                ></textarea>
                            </li>

                            <li className={classNames(styles.trainResource, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.trainingComputingPower)}</span>
                                {(props.train_source.length > 0 && props.train_specs.length > 0) ? (
                                    <>
                                        <Select 
                                            value={props.train_source_val}
                                            style={{ width: 120, marginRight: '10px' }} 
                                            onChange={props.changeSource}
                                        >
                                            {props.train_source.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item}>{item}</Option>
                                                )
                                            })}
                                        </Select>

                                        <Select 
                                            value={props.train_specs_val}
                                            style={{ width: 120 }} 
                                            onChange={props.changeSpecs}
                                        >
                                            {props.train_specs.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </>
                                ) : (<span className={styles.sourceTip}>{props.intl.formatMessage(props.LANG_MESSAGE.invalidConfiguration)}</span>)}
                            </li>

                            <li className={classNames(styles.reasonResource, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.inferentialComputingPower)}</span>
                                {(props.reason_source.length > 0 && props.reason_specs.length > 0) ? (
                                    <>
                                        <Select 
                                            value={props.reason_source_val}
                                            style={{ width: 120, marginRight: '10px' }}  
                                            onChange={props.changeReasonSource}
                                        >
                                            {props.reason_source.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item}>{item}</Option>
                                                )
                                            })}
                                        </Select>

                                        <Select 
                                            value={props.reason_specs_val}
                                            style={{ width: 120 }} 
                                            onChange={props.changeReasonSpecs}
                                        >
                                            {props.reason_specs.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </>
                                ) : (<span className={styles.sourceTip}>{props.intl.formatMessage(props.LANG_MESSAGE.invalidConfiguration)}</span>)}
                            </li>
                        </>
                    ) : null}
                </ul>
                <div className={styles.operate}>
                    <div className={styles.operateLeft}>
                        <div className={classNames(styles.comBox, styles.uploadBox)}>
                            <span>{props.intl.formatMessage(props.LANG_MESSAGE.dataInput)}</span>
                            <img className={styles.uploadImg} src={props.upload_image || upBg} alt="" />
                            <input 
                                className={classNames(
                                    styles.uploadBtn,
                                    props.deployment_status === 'Available' ? '' : styles.disBtn
                                )}
                                type="button" 
                                value={props.intl.formatMessage(props.LANG_MESSAGE.uploadTest)}
                                onClick={() => {
                                    if (props.deployment_status === 'Available') {
                                        props.setUploadType('train')
                                        props.changeTakePhoto(true)
                                    }
                                }}
                            />

                            <div className={styles.reasonConf}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.confidenceLevel)}</span>
                                <input 
                                    type="number" 
                                    value={props.conf_thres}
                                    onChange={props.setConfThres}
                                />
                            </div>

                            <div className={styles.reasonConf}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.threshold)}</span>
                                <input 
                                    type="number"
                                    value={props.iou_thres}
                                    onChange={props.setIouThres}
                                />
                            </div>
                        </div>
                        <div className={classNames(styles.comBox)}>
                            <span>{props.intl.formatMessage(props.LANG_MESSAGE.neuralNetworkLearning)}</span>
                            <img className={styles.learnImg} src={cBg} alt="" />
                        </div>
                        <div className={classNames(styles.comBox, styles.resultBox)}>
                            <span>{props.intl.formatMessage(props.LANG_MESSAGE.trainingResults)}</span>
                            <img 
                                className={styles.resultImg}
                                src={props.result_image || upBg}
                                onDoubleClick={() => props.setPreview(true)}
                            />
                            <ul className={styles.resultUl}>
                                <li>{props.result_state ? props.intl.formatMessage(props.LANG_MESSAGE.Identifying) + '…' : props.intl.formatMessage(props.LANG_MESSAGE.identificationResults)}</li>
                                {props.result_list.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {item.data.map((tag_itm, tag_index) => {
                                                return (
                                                    <div key={tag_index}>{tag_itm.lable}: <span>{tag_itm.probability}</span></div>
                                                )
                                            })}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.operateRight}>
                        <ul className={styles.probability}>
                            <li>{props.intl.formatMessage(props.LANG_MESSAGE.currentRound)}：<span>{props.cur_round}</span></li>
                            <li>{props.intl.formatMessage(props.LANG_MESSAGE.accuracyRate)}：<span>{props.accuracy_rate}</span></li>
                            <li>{props.intl.formatMessage(props.LANG_MESSAGE.lossRate)}：<span>{props.loss_rate}</span></li>
                        </ul>
                        <img className={styles.probabilityImg} src={bg} alt="" />
                    </div>
                </div>

                <div className={styles.compileStatus}>
                    {props.intl.formatMessage(props.LANG_MESSAGE.taskstatus)}：
                        {props.task_type === 'train' ? (
                            <span className={classNames(
                                props.train_status === 'succeeded' ? styles.compileSuc : 
                                ['preparing', 'pending', 'running'].includes(props.train_status) ? styles.compileRun :
                                ['failed', 'stopped'].includes(props.train_status) ? styles.compileFail : ''
                            )}>{props.train_status_text ? (props.train_status_text + ': ' + props.train_time) : ''}</span>
                        ) : 
                        props.task_type === 'cloud' ? (
                            <span className={classNames(
                                props.deployment_status === 'Available' ? styles.compileSuc : 
                                ['Preparing', 'Creating'].includes(props.deployment_status) ? styles.compileRun :
                                ['Failed', 'Stopped'].includes(props.deployment_status) ? styles.compileFail : ''
                            )}>{props.deployment_status_text ? (props.deployment_status_text + '：' + props.deployment_time) : ''}</span>
                        ) : null}
                </div>
            </div>
            <div className={styles.footer}>
                <input 
                    className={classNames(
                        styles.tpu, 
                        styles.comBtn,
                    )}
                    type="button" 
                    value={props.intl.formatMessage(props.LANG_MESSAGE.TPUTesting)}
                    onClick={props.openCompileModel}
                />
                <input 
                    className={classNames(
                        styles.arrange, 
                        styles.comBtn,
                        props.train_status !== 'succeeded' ? styles.disBtn : ''
                    )}
                    type="button"
                    value={props.intl.formatMessage(props.LANG_MESSAGE.deployModel)}
                    onClick={() => {
                        if (props.train_status === 'succeeded') {
                            props.deploymentModel()
                        }
                    }}
                />
                <input 
                    className={classNames(
                        styles.start, 
                        styles.comBtn,
                        (props.is_load || ['preparing', 'pending', 'running'].includes(props.train_status)) ? styles.disBtn : ''
                    )}
                    type="button"
                    value={props.intl.formatMessage(props.LANG_MESSAGE.startTraining)}
                    onClick={() => {
                        if (!props.is_load ||  !(['preparing', 'pending', 'running'].includes(props.train_status))) {
                            props.createTrainTask()
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default TrainModel
