import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import VM from 'scratch-vm';

import {defineMessages} from 'react-intl';

import Box from '../box/box.jsx';
import {STAGE_DISPLAY_SIZES} from '../../lib/layout-constants.js';
import StageHeader from '../../containers/stage-header.jsx';
import Stage from '../../containers/stage.jsx';
import Loader from '../loader/loader.jsx';

import styles from './stage-wrapper.css';
import stageIcon from './stageIcon.png';
import shuIcon from './shu.svg';

const messages = defineMessages({
    screen: {
        id: 'gui.stageWrapper.screen',
        description: 'Label for the name of the screen',
        defaultMessage: 'screen'
    }
})

const StageWrapperComponent = function (props) {
    const {
        isFullScreen,
        isRtl,
        isRendererSupported,
        isUIStyle,
        loading,
        stageSize,
        vm
    } = props;
    // console.log('==============');
    // console.log('isFullScreen:', isFullScreen);
    // console.log('isRtl:', isRtl);
    // console.log('isRendererSupported:', isFullScreen);
    // console.log('isUIStyle:', isUIStyle);
    // console.log('loading:', loading);
    // console.log('stageSize:', stageSize);
    // console.log('vm:', vm);
    return <Box
            className={classNames(
                styles.stageWrapper, styles.stageBody, styles.stageHeight,
                {[styles.fullScreen]: isFullScreen}
            )}
            dir={isRtl ? 'rtl' : 'ltr'}
        >
            {!isFullScreen ? (
                <Box className={styles.stageItem}>
                    <div className={styles.stageItemPing}>
                        <img src={stageIcon} alt="" />
                        <span>{props.intl.formatMessage(messages.screen)}</span>
                    </div>
                    <div className={styles.stageItemShu}>
                        <img src={shuIcon} alt="" className={styles.stageItemShuImg} />
                    </div>
                    <div className={styles.stageItemType}>1</div>
                </Box>
            ): null}
            
            <Box className={styles.stageCanvasWrapper}>
                {
                    isRendererSupported ?
                        <Stage
                            stageSize={stageSize}
                            isUIStyle={isUIStyle}
                            vm={vm}
                        /> :
                        null
                }
            </Box>
            <Box className={styles.stageMenuWrapper}>
                <StageHeader
                    stageSize={stageSize}
                    isUIStyle={isUIStyle}
                    vm={vm}
                    intl={props.intl}
                />
            </Box>
            {loading ? (
                <Loader isFullScreen={isFullScreen} />
            ) : null}
        </Box>;
};

StageWrapperComponent.propTypes = {
    isFullScreen: PropTypes.bool,
    isRendererSupported: PropTypes.bool.isRequired,
    isRtl: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    isUIStyle: PropTypes.bool,
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    vm: PropTypes.instanceOf(VM).isRequired
};

export default StageWrapperComponent;
