import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Box from '../box/box.jsx';
import Label from '../forms/label.jsx';
import Input from '../forms/input.jsx';
import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';
import DirectionPicker from '../../containers/direction-picker.jsx';

import {injectIntl, intlShape, defineMessages, FormattedMessage} from 'react-intl';

import {STAGE_DISPLAY_SIZES} from '../../lib/layout-constants.js';
import {isWideLocale} from '../../lib/locale-utils.js';

import styles from './sprite-info.css';

import xIcon from '../../skimg/icon_arrow1.png';
import yIcon from '../../skimg/icon_arrow2.png';
import showIcon from '../../skimg/icon_show.png';
import hideIcon from '../../skimg/icon_conceal.png';
import biggerIcon from './biggerIcon.svg';

const BufferedInput = BufferedInputHOC(Input);

const messages = defineMessages({
    spritePlaceholder: {
        id: 'gui.SpriteInfo.spritePlaceholder',
        defaultMessage: 'Name',
        description: 'Placeholder text for sprite name'
    }
});

class SpriteInfo extends React.Component {
    shouldComponentUpdate (nextProps) {
        return (
            this.props.rotationStyle !== nextProps.rotationStyle ||
            this.props.disabled !== nextProps.disabled ||
            this.props.name !== nextProps.name ||
            this.props.stageSize !== nextProps.stageSize ||
            this.props.visible !== nextProps.visible ||
            // Only update these if rounded value has changed
            Math.round(this.props.direction) !== Math.round(nextProps.direction) ||
            Math.round(this.props.size) !== Math.round(nextProps.size) ||
            Math.round(this.props.x) !== Math.round(nextProps.x) ||
            Math.round(this.props.y) !== Math.round(nextProps.y)
        );
    }
    render () {
        const {
            stageSize
        } = this.props;

        const sprite = (
            <FormattedMessage
                defaultMessage="Sprite"
                description="Sprite info label"
                id="gui.SpriteInfo.sprite"
            />
        );
        const showLabel = (
            <FormattedMessage
                defaultMessage="Show"
                description="Sprite info show label"
                id="gui.SpriteInfo.show"
            />
        );
        const sizeLabel = (
            <FormattedMessage
                defaultMessage="Size"
                description="Sprite info size label"
                id="gui.SpriteInfo.size"
            />
        );

        const labelAbove = isWideLocale(this.props.intl.locale);

        const spriteNameInput = (
            <BufferedInput
                className={classNames(
                    styles.spriteInput,
                    {
                        [styles.columnInput]: labelAbove
                    }
                )}
                disabled={this.props.disabled}
                placeholder={this.props.intl.formatMessage(messages.spritePlaceholder)}
                tabIndex="0"
                type="text"
                value={this.props.disabled ? '' : this.props.name}
                onSubmit={this.props.onChangeName}
            />
        );

        const xPosition = (
            <div className={classNames(styles.group, styles.between)}>
                <span className={styles.spriteInfoSpan}>X</span>
                <BufferedInput
                    className={styles.spriteInfoInput}
                    small
                    disabled={this.props.disabled}
                    placeholder="x"
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.x)}
                    onSubmit={this.props.onChangeX}
                />
            </div>
        );
        const wPosition = (
            <div className={classNames(styles.group, styles.between)}>
                <span>W</span>
                <BufferedInput
                    small
                    disabled={this.props.disabled}
                    placeholder="W"
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.w)}
                    onSubmit={this.props.onChangeW}
                />
            </div>
        );
        const hPosition = (
            <div className={classNames(styles.group, styles.between)}>
                <span>H</span>
                <BufferedInput
                    small
                    disabled={this.props.disabled}
                    placeholder="H"
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.h)}
                    onSubmit={this.props.onChangeH}
                />
            </div>
        );

        const yPosition = (
            <div className={classNames(styles.group, styles.between)}>
                <span className={styles.spriteInfoSpan}>Y</span>
                <BufferedInput
                    className={styles.spriteInfoInput}
                    small
                    disabled={this.props.disabled}
                    placeholder="y"
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.y)}
                    onSubmit={this.props.onChangeY}
                />
            </div>
        );
        const inPosition = (
            <div className={classNames(styles.group, styles.between)}>
                <span>内</span>
                <BufferedInput
                    small
                    disabled={this.props.disabled}
                    placeholder="内"
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.in)}
                    onSubmit={this.props.onChangeIn}
                />
            </div>
        );
        const outPosition = (
            <div className={classNames(styles.group, styles.between)}>
                <span>外</span>
                <BufferedInput
                    small
                    disabled={this.props.disabled}
                    placeholder="外"
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.out)}
                    onSubmit={this.props.onChangeOut}
                />
            </div>
        );

        const visibleSprite = (
            <div className={labelAbove ? styles.column : styles.groupVibal}>
                {this.props.visible ? (
                    <div
                    className={classNames(
                        styles.radio,
                        styles.radioLast,
                        styles.iconWrapper,
                        {
                            [styles.isActive]: !this.props.visible && !this.props.disabled,
                            [styles.isDisabled]: this.props.disabled
                        }
                    )}
                    tabIndex="0"
                    onClick={this.props.onClickNotVisible}
                    onKeyPress={this.props.onPressNotVisible}
                >
                    <img
                        className={styles.showIcon}
                        src={hideIcon}
                    />
                </div>
                    
                ) : (
                    <div
                    className={classNames(
                        styles.radio,
                        styles.radioFirst,
                        styles.iconWrapper,
                        {
                            [styles.isActive]: this.props.visible && !this.props.disabled,
                            [styles.isDisabled]: this.props.disabled
                        }
                    )}
                    tabIndex="0"
                    onClick={this.props.onClickVisible}
                    onKeyPress={this.props.onPressVisible}
                >
                    <img
                        className={styles.showIcon}
                        src={showIcon}
                    />
                </div>
                )}
            </div>
        );
        const largerSprite = (
            <div className={classNames(styles.group, styles.largerInput, styles.between)}>
                <img src={biggerIcon} alt="" />
                <BufferedInput
                    small
                    disabled={this.props.disabled}
                    label={sizeLabel}
                    tabIndex="0"
                    type="text"
                    value={this.props.disabled ? '' : Math.round(this.props.size)}
                    onSubmit={this.props.onChangeSize}
                />
            </div>
        );
        const directionSprite = (
            <div className={classNames(styles.group, styles.largerInput)}>
                <DirectionPicker
                    direction={Math.round(this.props.direction)}
                    disabled={this.props.disabled}
                    labelAbove={labelAbove}
                    rotationStyle={this.props.rotationStyle}
                    onChangeDirection={this.props.onChangeDirection}
                    onChangeRotationStyle={this.props.onChangeRotationStyle}
                />
            </div>
        );

        if (stageSize === STAGE_DISPLAY_SIZES.small) {
            return (
                <Box className={styles.spriteInfo}>
                    <div className={classNames(styles.row, styles.rowPrimary)}>
                        <div className={styles.group}>
                            {spriteNameInput}
                        </div>
                    </div>
                    <div className={classNames(styles.row, styles.rowSecondary)}>
                        {xPosition}
                        {yPosition}
                    </div>
                </Box>
            );
        }
        return (
            <Box className={styles.spriteInfo}>
                <div className={classNames(styles.row, styles.rowPrimary)}>
                    {spriteNameInput}
                    {visibleSprite}
                </div>
                <div className={classNames(styles.row, styles.rowSecondary)}>
                    {xPosition}
                    {yPosition}
                    {largerSprite}
                    {directionSprite}
                </div>
                {/* <div className={classNames(styles.row, styles.rowSecondary, styles.rowSecondaryNal)}>
                    {wPosition}
                    {hPosition}
                    {inPosition}
                    {outPosition}
                </div> */}
            </Box>
        );
    }
}

SpriteInfo.propTypes = {
    direction: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    disabled: PropTypes.bool,
    intl: intlShape,
    name: PropTypes.string,
    onChangeDirection: PropTypes.func,
    onChangeName: PropTypes.func,
    onChangeRotationStyle: PropTypes.func,
    onChangeSize: PropTypes.func,
    onChangeX: PropTypes.func,
    onChangeY: PropTypes.func,
    onChangeW: PropTypes.func,
    onChangeH: PropTypes.func,
    onChangeIn: PropTypes.func,
    onChangeOut: PropTypes.func,
    onClickNotVisible: PropTypes.func,
    onClickVisible: PropTypes.func,
    onPressNotVisible: PropTypes.func,
    onPressVisible: PropTypes.func,
    rotationStyle: PropTypes.string,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    stageSize: PropTypes.oneOf(Object.keys(STAGE_DISPLAY_SIZES)).isRequired,
    visible: PropTypes.bool,
    x: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    y: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    w: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    h: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    in: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    out: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    targetPanetype: PropTypes.number
};
SpriteInfo.defaultProps = {
    w: 0,
    h: 0,
    in: 0,
    out: 0
}

export default injectIntl(SpriteInfo);
