const GUIDE_STATUS = 'guideStatus'
const GUIDE_MENU = 'guideMenu'
const GUIDE_TYPE = 'guideType'

// initial state
const initState = {
    state: false,
    menu: false,
    type: '', // edit preview
}

// reducer
const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch(action.type) {
        case GUIDE_STATUS:
            return Object.assign({}, state, {
                state: action.param,
                menu: action.param
            })
        case GUIDE_MENU:
            return Object.assign({}, state, {
                menu: action.param
            })
        case GUIDE_TYPE:
            return Object.assign({}, state, {
                type: action.param
            })
        default:
            return state
    }
}

// action creator
const changeGuide = (state) => ({
    type: GUIDE_STATUS,
    param: state
})
const changeGuideMenu = (state) => ({
    type: GUIDE_MENU,
    param: state
})
const changeGuideType = (state) => ({
    type: GUIDE_TYPE,
    param: state
})

export {
    reducer as default,
    initState as guideInitState,
    changeGuide,
    changeGuideMenu,
    changeGuideType
}
