import React from 'react';
import styles from './params-dialog.css';

import IMG_CONTAINER from '../img/index.js'

const ParamsDialog = props => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <span className={styles.title}>
                    {props.type === 'add' ? props.add : props.edit}
                </span>
                <img className={styles.close} src={IMG_CONTAINER.close} onClick={() => props.showParamsDialog(false, '', '', '')} />
            </div>
            <ul className={styles.content}>
                <li>
                    <span>{props.display_name}:</span>
                    <input type="text" autoFocus="true" value={props.name} onChange={props.editName} />
                </li>
                <li>
                    <span>{props.param_name}:</span>
                    <input type="text" value={props.value} onChange={props.editValue} />
                </li>
            </ul>
            {/* <input className={styles.input} type="text" autoFocus="true" value={props.value} onChange={props.editValue} /> */}
            <div className={styles.footer}>
                <input className={styles.cancle} type="button" value={props.cancel} onClick={() => props.showParamsDialog(false, '', '', '')} />
                <input className={styles.confirm} type="button" value={props.ok} onClick={props.paramsDialogConfirm} />
            </div>
        </div>
    )
}

export default ParamsDialog
