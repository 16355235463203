exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".controls_controls-container_2xinB {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    /* margin-top: 5px; */\n}\n", ""]);

// exports
exports.locals = {
	"controls-container": "controls_controls-container_2xinB",
	"controlsContainer": "controls_controls-container_2xinB"
};