import React from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import styles from '../dimension.css';

import MenuBarMenu from '../../menu-bar/menu-bar-menu.jsx';
import { MenuItem, MenuSection } from '../../menu/menu.jsx';

import { Pagination } from 'antd';

const CategorizeBox = (props) => {
    return (
        <div className={styles.uploadBox} >
            <div className={styles.uploadHeader}>
                {/* 分类 */}
                {props.categorize_list_act === 0 ? (
                    <>
                        <div onDoubleClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            props.doubleClickHandle(e.target, props.index)
                        }}>
                            {props.is_rename ? (
                                <input 
                                    className={styles.renameIpt} 
                                    autoFocus={props.is_rename}
                                    value={props.name} 
                                    onChange={(e) => props.renameInputHandle(e.target.value, props.index)}
                                    onBlur={(e) => props.renameBlurHandle(props.index)}
                                />
                            ) : <span>{props.name}</span>}
                        </div>
                        <div className={styles.uploadMore}>
                            <img 
                                src={props.IMG_CONTAINER.moreIcon} alt="" 
                                onMouseUp={() => props.showCategorizeMenu(props.index)}
                            />
                        </div>
                    </>
                ) : null}
                {/* 检测 */}
                {props.categorize_list_act === 1 ? (
                    <>
                        <span>{props.categorize_list_act === 1 ? '' :  <FormattedMessage {...props.LANG_MESSAGE.categorize} /> + `${props?.index + 1}` } </span>
                        <div className={styles.uploadMore}>
                            {/* <img src={props.IMG_CONTAINER.warnIcon} alt="" />
                            <span>已停用</span> */}
                            <img src={props.IMG_CONTAINER.moreIcon} alt="" onMouseUp={props.onOpenDimUploadMenu} />
                        </div>
                    </>
                ) : null}
            </div>
            {/* 分类 */}
            {(props.step === 1 && props.categorize_type === 'categorize') ? (
                <ul 
                    className={styles.categorizeMenu}
                    style={{
                        'visibility': props.is_menu ? 'visible' : 'hidden'
                    }}
                >
                    <li onClick={() => props.showRename('edit', props.index)}><FormattedMessage {...props.LANG_MESSAGE.renameClass} /></li>
                    <li onClick={() => props.removeCategorize(props.index)}><FormattedMessage {...props.LANG_MESSAGE.deleteThisClass} /></li>
                    <li onClick={() => props.removeData(props.index)}><FormattedMessage {...props.LANG_MESSAGE.removeAllSampleData} /></li>
                </ul>
            ) : null}
            {/* 检测 */}
            {(props.step === 1 && props.categorize_type === 'detection') ? (
                <MenuBarMenu
                    className={classNames(styles.moreBar)}
                    open={props.dimUploadMenuOpen}
                    place={'left'}
                    onRequestClose={props.onCloseDimUploadMenu}
                >
                    <MenuSection>
                        <MenuItem onClick={() => props.filterImgList(0)}>
                            <div className={styles.filterItem}>
                                <span><FormattedMessage {...props.LANG_MESSAGE.all} /></span>
                                <img src={props.filter_act === 0 ? props.IMG_CONTAINER.checkIcon1 : props.IMG_CONTAINER.noCheckIcon1} alt="" />
                            </div>
                        </MenuItem>
                        <MenuItem onClick={() => props.filterImgList(1)}>
                            <div className={styles.filterItem}>
                                <span><FormattedMessage {...props.LANG_MESSAGE.marked} /></span>
                                <img src={props.filter_act === 1 ? props.IMG_CONTAINER.checkIcon1 : props.IMG_CONTAINER.noCheckIcon1} alt="" />
                            </div>
                        </MenuItem>
                        <MenuItem onClick={() => props.filterImgList(2)}>
                            <div className={styles.filterItem}>
                                <span><FormattedMessage {...props.LANG_MESSAGE.notmarked} /></span>
                                <img src={props.filter_act === 2 ? props.IMG_CONTAINER.checkIcon1 : props.IMG_CONTAINER.noCheckIcon1} alt="" />
                            </div>
                        </MenuItem>
                    </MenuSection>
                </MenuBarMenu>
            ) : null}

            <div
                className={styles.uploadContent}
                onClick={() => props.changeScroll(props.categorize_type, props.index)}
                onDragOver={e => {e.preventDefault()}}
                onDrop={(e) => props.dragUpload(e, {
                    type: props.categorize_type,
                    name: props.name
                }, props.index)}
            >
                {props.img_list.map((item, index) => {
                    return (
                        <div 
                            key={index} 
                            className={classNames(styles.uploadImg, {
                                [styles.uploadImgAct]: (props.step === 1 && props.categorize_type === 'detection') && index === props.upload_img_act
                            })}
                            onClick={() => props.setUploadImgIndex(index)}
                            onDragStart={e => props.setDragIndex(props.index)}
                            onDragEnd={e => props.setDragIndex(-1)}
                        >
                            <img src={item.url} alt="" />
                            <img 
                                className={styles.uploadDelete}
                                src={props.IMG_CONTAINER.deleteIcon3}
                                alt="" 
                                onClick={(e) => props.openUpload(index, 'upload', e, props.name ? props.name : '')}
                            />
                            <ul 
                                className={styles.uploadDeleteTips} 
                                onClick={(e) => props.openUpload(index, 'upload', e, props.name ? props.name : '')}
                                draggable="false"
                            >
                                <div className={styles.tipTragleTop}></div>
                                <li draggable="false"><FormattedMessage {...props.LANG_MESSAGE.delete} /></li>
                            </ul>
                    </div>)
                })}
            </div>
            <div className={styles.pageContainer}>
                <Pagination
                    size="small"
                    defaultPageSize={20}
                    current={props.page}
                    total={props.total}
                    showQuickJumper
                    onChange={(page) => props.pageChange(page, props.categorize_type, props.index)}
                />
            </div>
            <div className={styles.uploadFooter}>
                <div className={styles.uploadBtnGroup}>
                    <input 
                        className={classNames(styles.uploadBtn, styles.uploadSnap)} 
                        type="button" 
                        value={props.intl.formatMessage(props.LANG_MESSAGE.getPic)}
                        onClick={() => props.setMaskType('snap', props.categorize_type === 'categorize' ? {
                            type: props.categorize_type,
                            name: props.name
                        } : null)}
                    />
                    <input 
                        className={classNames(styles.uploadBtn, styles.uploadLocal)} 
                        type="button" 
                        value={props.intl.formatMessage(props.LANG_MESSAGE.localUpload)}
                        onClick={() => props.setMaskType('upload', props.categorize_type === 'categorize' ? {
                            type: props.categorize_type,
                            name: props.name
                        } : null)}
                    />

                    {/* 分类 */}
                    {/* {(props.step === 1 && props.categorize_type === 'categorize') ? (
                        <>
                            <input 
                                className={classNames(styles.uploadBtn, styles.uploadSnap)} 
                                type="button" 
                                value={props.intl.formatMessage(props.LANG_MESSAGE.getPic)}
                                onClick={() => props.setMaskType('snap', {
                                    type: props.categorize_type,
                                    name: props.name
                                })}
                            />
                            <input 
                                className={classNames(styles.uploadBtn, styles.uploadLocal)} 
                                type="button" 
                                value={props.intl.formatMessage(props.LANG_MESSAGE.localUpload)}
                                onClick={() => props.setMaskType('upload', {
                                    type: props.categorize_type,
                                    name: props.name
                                })}
                            />
                        </>
                    ) : null} */}
                    {/* 检测 */}
                    {/* {(props.step === 1 && props.categorize_type === 'detection') ? (
                        <>
                            <input 
                                className={classNames(styles.uploadBtn, styles.uploadSnap)} 
                                type="button" 
                                value={props.intl.formatMessage(props.LANG_MESSAGE.getPic)}
                                onClick={() => props.setMaskType('snap')}
                            />
                            <input 
                                className={classNames(styles.uploadBtn, styles.uploadLocal)} 
                                type="button" 
                                value={props.intl.formatMessage(props.LANG_MESSAGE.localUpload)}
                                onClick={() => props.setMaskType('upload')}
                            />
                        </>
                    ) : null} */}
                </div>
            </div>
        </div>
    )
}

export default CategorizeBox

// const CategorizeBox = (props) => {
//     return (
//         <div className={styles.uploadBox} >
//             <div className={styles.uploadHeader}>
//                 {/* 分类 */}
//                 {props.categorize_list_act === 0 ? (
//                     <>
//                         <div onDoubleClick={(e) => {
//                             e.preventDefault()
//                             e.stopPropagation()
//                             props.doubleClickHandle(e.target, props.index)
//                         }}>
//                             {props.is_rename ? (
//                                 <input 
//                                     className={styles.renameIpt} 
//                                     autoFocus={props.is_rename}
//                                     value={props.name} 
//                                     onChange={(e) => props.renameInputHandle(e.target.value, props.index)}
//                                     onBlur={(e) => props.renameBlurHandle(props.index)}
//                                 />
//                             ) : <span>{props.name}</span>}
//                         </div>
//                         <div className={styles.uploadMore}>
//                             <img 
//                                 src={IMG_CONTAINER.moreIcon} alt="" 
//                                 onMouseUp={() => props.showCategorizeMenu(props.index)}
//                             />
//                         </div>
//                     </>
//                 ) : null}
//                 {/* 检测 */}
//                 {props.categorize_list_act === 1 ? (
//                     <>
//                         <span>{props.categorize_list_act === 1 ? '' :  <FormattedMessage {...LANG_MESSAGE.categorize} /> + `${props?.index + 1}` } </span>
//                         <div className={styles.uploadMore}>
//                             {/* <img src={IMG_CONTAINER.warnIcon} alt="" />
//                             <span>已停用</span> */}
//                             <img src={IMG_CONTAINER.moreIcon} alt="" onMouseUp={props.onOpenDimUploadMenu} />
//                         </div>
//                     </>
//                 ) : null}
//             </div>
//             {/* 分类 */}
//             {(props.step === 1 && props.categorize_type === 'categorize') ? (
//                 <ul 
//                     className={styles.categorizeMenu}
//                     style={{
//                         'visibility': props.is_menu ? 'visible' : 'hidden'
//                     }}
//                 >
//                     <li onClick={() => props.showRename('edit', props.index)}><FormattedMessage {...LANG_MESSAGE.renameClass} /></li>
//                     <li onClick={() => props.removeCategorize(props.index)}><FormattedMessage {...LANG_MESSAGE.deleteThisClass} /></li>
//                     <li onClick={() => props.removeData(props.index)}><FormattedMessage {...LANG_MESSAGE.removeAllSampleData} /></li>
//                 </ul>
//             ) : null}
//             {/* 检测 */}
//             {(props.step === 1 && props.categorize_type === 'detection') ? (
//                 <MenuBarMenu
//                     className={classNames(styles.moreBar)}
//                     open={props.dimUploadMenuOpen}
//                     place={'left'}
//                     onRequestClose={props.onCloseDimUploadMenu}
//                 >
//                     <MenuSection>
//                         <MenuItem onClick={() => props.filterImgList(0)}>
//                             <div className={styles.filterItem}>
//                                 <span><FormattedMessage {...LANG_MESSAGE.all} /></span>
//                                 <img src={props.filter_act === 0 ? IMG_CONTAINER.checkIcon1 : IMG_CONTAINER.noCheckIcon1} alt="" />
//                             </div>
//                         </MenuItem>
//                         <MenuItem onClick={() => props.filterImgList(1)}>
//                             <div className={styles.filterItem}>
//                                 <span><FormattedMessage {...LANG_MESSAGE.marked} /></span>
//                                 <img src={props.filter_act === 1 ? IMG_CONTAINER.checkIcon1 : IMG_CONTAINER.noCheckIcon1} alt="" />
//                             </div>
//                         </MenuItem>
//                         <MenuItem onClick={() => props.filterImgList(2)}>
//                             <div className={styles.filterItem}>
//                                 <span><FormattedMessage {...LANG_MESSAGE.notmarked} /></span>
//                                 <img src={props.filter_act === 2 ? IMG_CONTAINER.checkIcon1 : IMG_CONTAINER.noCheckIcon1} alt="" />
//                             </div>
//                         </MenuItem>
//                     </MenuSection>
//                 </MenuBarMenu>
//             ) : null}

//             <div
//                 className={styles.uploadContent}
//                 onClick={() => props.changeScroll(props.categorize_type, props.index)}
//                 onDragOver={e => {e.preventDefault()}}
//                 onDrop={(e) => props.dragUpload(e, {
//                     type: props.categorize_type,
//                     name: props.name
//                 }, props.index)}
//             >
//                 {props.img_list.map((item, index) => {
//                     return (
//                         <div 
//                             key={index} 
//                             className={classNames(styles.uploadImg, {
//                                 [styles.uploadImgAct]: (props.step === 1 && props.categorize_type === 'detection') && index === props.upload_img_act
//                             })}
//                             onClick={() => props.setUploadImgIndex(index)}
//                             onDragStart={e => props.setDragIndex(props.index)}
//                             onDragEnd={e => props.setDragIndex(-1)}
//                         >
//                             <img src={item.url} alt="" />
//                             <img 
//                                 className={styles.uploadDelete}
//                                 src={IMG_CONTAINER.deleteIcon3}
//                                 alt="" 
//                                 onClick={(e) => props.openUpload(index, 'upload', e, props.name ? props.name : '')}
//                             />
//                             <ul 
//                                 className={styles.uploadDeleteTips} 
//                                 onClick={(e) => props.openUpload(index, 'upload', e, props.name ? props.name : '')}
//                                 draggable="false"
//                             >
//                                 <div className={styles.tipTragleTop}></div>
//                                 <li draggable="false"><FormattedMessage {...LANG_MESSAGE.delete} /></li>
//                             </ul>
//                     </div>)
//                 })}
//             </div>
//             <div className={styles.uploadFooter}>
//                 <div className={styles.uploadBtnGroup}>
//                     {/* 分类 */}
//                     {(props.step === 1 && props.categorize_type === 'categorize') ? (
//                         <>
//                             <input 
//                                 className={classNames(styles.uploadBtn, styles.uploadSnap)} 
//                                 type="button" 
//                                 value={props.intl.formatMessage(LANG_MESSAGE.getPic)}
//                                 onClick={() => props.setMaskType('snap', {
//                                     type: props.categorize_type,
//                                     name: props.name
//                                 })}
//                             />
//                             <input 
//                                 className={classNames(styles.uploadBtn, styles.uploadLocal)} 
//                                 type="button" 
//                                 value={props.intl.formatMessage(LANG_MESSAGE.localUpload)}
//                                 onClick={() => props.setMaskType('upload', {
//                                     type: props.categorize_type,
//                                     name: props.name
//                                 })}
//                             />
//                         </>
//                     ) : null}
//                     {/* 检测 */}
//                     {(props.step === 1 && props.categorize_type === 'detection') ? (
//                         <>
//                             <input 
//                                 className={classNames(styles.uploadBtn, styles.uploadSnap)} 
//                                 type="button" 
//                                 value={props.intl.formatMessage(LANG_MESSAGE.getPic)}
//                                 onClick={() => props.setMaskType('snap')}
//                             />
//                             <input 
//                                 className={classNames(styles.uploadBtn, styles.uploadLocal)} 
//                                 type="button" 
//                                 value={props.intl.formatMessage(LANG_MESSAGE.localUpload)}
//                                 onClick={() => props.setMaskType('upload')}
//                             />
//                         </>
//                     ) : null}
//                 </div>
//             </div>
//         </div>
//     )
// }
