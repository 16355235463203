let categorize = [
    'model',
    'data',
    'epochs',
    'batch-size',
    'imgsz',
    'nosave',
    'cache',
    'device',
    'workers',
    'project',
    'name',
    'exist-ok',
    'pretrained',
    'optimizer',
    'lr0',
    'decay',
    'label-smoothing',
    'cutoff',
    'dropout',
    'verbose',
    'seed',
    'local_rank',
]

let detection = [
    'weights',
    'cfg',
    'data',
    'hyp',
    'epochs',
    'batch-size',
    'imgsz',
    'rect',
    'resume',
    'nosave',
    'noval',
    'noautoanchor',
    'noplots',
    'evolve',
    'bucket',
    'cache',
    'image-weights',
    'device',
    'multi-scale',
    'single-cls',
    'optimizer',
    'sync-bn',
    'workers',
    'project',
    'name',
    'exist-ok',
    'quad',
    'cos-lr',
    'label-smoothing',
    'patience',
    'freeze',
    'save-period',
    'seed',
    'local_rank',
    'entity',
    'upload_dataset',
    'bbox_interval',
    'artifact_alias',
]

export {categorize, detection}