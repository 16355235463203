import { loginOUT, userManage } from "../api/login"
import { getManageUser, asignmentDetails } from "../api/manage"

// action type
const LOGIN_OPEN = 'login_open'
const LOGIN_CLOSE = 'login_close'
const LOGIN_OUT = 'login_out'
const SET_TOKEN = 'set_token'
const SET_MANAGE_TOKEN = 'set_manage_token'
const SET_DIMENSION_TOKEN = 'set_dimension_token'
const SET_MANAGE_USER_MESSAGE = 'set_manag_user_message'
const REMOVE_TOKEN = 'remove_token'
const EDIT_MENU = 'edit_menu'
const SET_CODE_USER_MESSAGE = 'set_code_user_message'
const SET_ZHIYA_TOKEN = 'set_zhiya_token'
// 群组
const EDIT_GROUP_MENU = 'edit_group_menu'
const SET_GROUP_DATA = 'set_group_data'
const SET_GROUP_NAME = 'set_group_name'

let clearHandler = () => {
    sessionStorage.removeItem("space")
    // localStorage.removeItem('manage_message')
    let train_data = localStorage.getItem('train_parameters')
    let tpu_data = localStorage.getItem('tpu_parameters')
    let cloud_data = localStorage.getItem('cloud_parameters')
    let model_extends = localStorage.getItem('mode_extend_variable')
    // let data_set = localStorage.getItem('DATASET')
    // let create_model = localStorage.getItem('CREATEMODEL')
    // let create_cloud_model = localStorage.getItem('CREATECLOUDMODEL')
    let camera_device = localStorage.getItem('camera_device')
    localStorage.clear()
    // localStorage.setItem('DATASET', data_set)    
    // localStorage.setItem('CREATEMODEL', create_model)
    // localStorage.setItem('CREATECLOUDMODEL', create_cloud_model)    
    localStorage.setItem('train_parameters', train_data)
    localStorage.setItem('tpu_parameters', tpu_data)
    localStorage.setItem('cloud_parameters', cloud_data)
    localStorage.setItem('mode_extend_variable', model_extends)
    localStorage.setItem('camera_device', camera_device)
}

// reducer
const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch(action.type) {
        case LOGIN_OPEN:
            if (state.token) {
                return Object.assign({}, state, {
                    is_show: false,
                    is_menu: true
                })
            } else {
                return Object.assign({}, state, {
                    is_show: true
                });
            }
        case LOGIN_CLOSE:
            return Object.assign({}, state, {
                is_show: false
            })
        case SET_TOKEN:
            // localStorage.setItem('token', action.params.token)
            // localStorage.setItem('cas_token', action.params.casToken)
            // getCodeUser(action.params.token)
            let token = state.token
            let cas_token = state.cas_token
            localStorage.setItem('token', action.params.token || token)
            localStorage.setItem('cas_token', action.params.cas_token || cas_token)

            return Object.assign({}, state, {
                is_show: false,
                token: action.params.token || token,
                cas_token: action.params.cas_token || cas_token
            })
        case SET_MANAGE_TOKEN:
            localStorage.setItem('manage_token', action.params)
            manageUser(action.callback)

            return Object.assign({}, state, {
                manage_token: action.params
            })
        case SET_DIMENSION_TOKEN:
            localStorage.setItem('dimension_token', action.params)

            return Object.assign({}, state, {
                dimension_token: action.params
            })
        case SET_MANAGE_USER_MESSAGE:
            if (!action.params) {
                return Object.assign({}, state, {
                    manage_user_message: {
                        dictId: '',
                        dictName: '',
                        courseId: '',
                        roles: [],
                        user: {}
                    }
                })
            }
            let obj = JSON.parse(JSON.stringify(state.manage_user_message))
            obj = {
                dictId: action.params.manage_user_message.hasOwnProperty('dictId') ? action.params.manage_user_message.dictId : obj.dictId,
                dictName: action.params.manage_user_message.hasOwnProperty('dictName') ? action.params.manage_user_message.dictName : obj.dictName,
                courseId:action.params.manage_user_message.hasOwnProperty('courseId') ?  action.params.manage_user_message.courseId : obj.courseId,
                roles: action.params.manage_user_message.hasOwnProperty('roles') ? action.params.manage_user_message.roles : obj.roles,
                user: action.params.manage_user_message.hasOwnProperty('user') ? action.params.manage_user_message.user : obj.user,
            }

            localStorage.setItem('manage_message', JSON.stringify(obj))
            return Object.assign({}, state, {
                manage_user_message: obj
            })
        case REMOVE_TOKEN:
            clearHandler()
            return Object.assign({}, state, {
                token: '',
                cas_token: '',
                manage_token: '',
                dimension_token: '',
                is_menu: false,
            })
        case LOGIN_OUT:
            new Promise((resolve, reject) => {
                let res = loginOUT(action.params)
                resolve(res)
            })
                .then((response) => {})
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    clearHandler()
                })
            return Object.assign({}, state, {
                token: '',
                cas_token: '',
                manage_token: '',
                dimension_token: '',
                is_menu: false
            })
        case EDIT_MENU:
            return Object.assign({}, state, {
                is_menu: action.params
            })
        case SET_CODE_USER_MESSAGE:
            return Object.assign({}, state, {
                user_code_message: action.params
            })
        case SET_ZHIYA_TOKEN:
            localStorage.setItem('zhiya_token', action.params)
            //TODo 可在这里获取用户信息

            return Object.assign({}, state, {
              zhiya_token: action.params
            })
        // 群组
        case EDIT_GROUP_MENU:
            return Object.assign({}, state, {
                group_menu: action.params
            })
        case SET_GROUP_DATA:
            let name = ''
            if (JSON.parse(sessionStorage.getItem('space'))) {
                name = JSON.parse(sessionStorage.getItem('space')).workspaceName
            } else {
                if (action?.params?.length > 0) {
                    name = action.params[0]['name']
                    let data = {
                        workspaceName: action.params[0]['name'],
                        workspaceId: action.params[0]['id'],
                        resourcePools: action.params[0]['resourcePools']
                    }
                    data = JSON.stringify(data)
                    sessionStorage.setItem('space', data)
                }
            }
            return Object.assign({}, state, {
                group_data: action.params,
                group_name: name
            })
        case SET_GROUP_NAME:
            return Object.assign({}, state, {
                group_name: action.params
            })
        default:
            return state
    }
}

// action creator
const loginOpen = () => ({ type: LOGIN_OPEN })
const loginClose = () => ({ type: LOGIN_CLOSE })
const removeToken = () => ({ type: REMOVE_TOKEN })
const loginOut = (params) => ({ 
    type: LOGIN_OUT,
    params
})
const loginMenuStatus = state => state.scratchGui.login.is_menu

const setToken = (params) => ({
    type: SET_TOKEN,
    params
})
const setManageToken = (params, callback) => ({
    type: SET_MANAGE_TOKEN,
    params,
    callback
})
const setDimensionToken = (params) => ({
    type: SET_DIMENSION_TOKEN,
    params
})
const setManageUserMessage = (params) => ({
    type: SET_MANAGE_USER_MESSAGE,
    params
})
const setCodeUserMessage = (params) => ({
    type: SET_CODE_USER_MESSAGE,
    params
})
const editMenu = (params) => ({
    type: EDIT_MENU,
    params
})
const setZhiyaToken = (params) => ({
  type: SET_ZHIYA_TOKEN,
  params
})
const groupMenuStatus = state => state.scratchGui.login.group_menu
const editGroupMenu = (params) => ({
    type: EDIT_GROUP_MENU,
    params
})
const setGroupData = (params) => ({
    type: SET_GROUP_DATA,
    params
})
const setGroupName = (params) => ({
    type: SET_GROUP_NAME,
    params
})

// const checkToken = async function(type) {
//     let response;
//     if (type === 'token') {
//         let token = localStorage.getItem('token')
//         response = await userManage(token)
//         if (response.success === true) {
//             localStorage.setItem('userId', response.payload.user.id)
//             window.globalMsg.code_user_message = {
//                 data: new Date(),
//                 message: response.payload.user
//             }
//             return token
//         }
//     } else if (type === 'cas_token') {

//     } else if (type === 'manage_token') {
//         // // response = await getManageUser()
//         // response = await manageLogin()
//         // console.log(response.token)
//         // setManageUserMessage(response.token)
//         // let res1 = await getManageUser()
//         // // setManageUserMessage()
//     } else if (type === 'dimension_token') {

//     }
// }

// 编程平台用户信息
// const getCodeUser = async function(token) {
//     let response = await userManage(token)
//     if (response.success) {
//         localStorage.setItem('userId', response.payload.user.id)
//         window.globalMsg.code_user_message = {
//             data: new Date(),
//             message: response.payload.user
//         }
//     }
// }

// 人员管理用户信息
const manageUser = async function(callback) {
    let response = await getManageUser()
    if (response.msg === '操作成功') {
        let temp_v = {}
        temp_v['roles'] = response.roles
        temp_v['user'] = response.user 

        callback({manage_user_message: temp_v}, response.roles[0])
    }
}

// initial state
const initState = {
    // token: localStorage.getItem('token') ? checkToken('token') : '', // 训练平台token
    // manage_token: localStorage.getItem('manage_token') ? checkToken('manage_token') : '', // 人员管理token
    // type: '', // 人员管理management 编程平台code
    is_show: false, // 登录弹框
    is_menu: false, // 退出登录
    token: localStorage.getItem('token') || '', // 训练平台token
    cas_token: localStorage.getItem('cas_token') || '', // 单点登录token
    manage_token: localStorage.getItem('manage_token') || '', // 人员管理token
    dimension_token: localStorage.getItem('dimension_token') || '', // 标注平台token
    manage_user_message: localStorage.getItem('manage_message') ?
    JSON.parse(localStorage.getItem('manage_message')) :
    {
        dictId: '', // 目录id 
        dictName: '', // 目录名称
        courseId: '', // 课程id
        roles: [], // 身份
        user: {} // 信息
    }, // 人员管理用户信息
    user_code_message: null, // 旧：编程平台用户信息 新：单点登录用户信息
    // 群组
    group_menu: false, // 群组下拉
    group_data: null, // 群组数据
    group_name: '', // 驱逐名称
}

export {
    reducer as default,
    initState as loginInitState,
    loginOpen,
    loginClose,
    loginOut,
    loginMenuStatus,
    setToken,
    setManageToken,
    setDimensionToken,
    setManageUserMessage,
    setCodeUserMessage,
    removeToken,
    editMenu,
    setZhiyaToken,
    // 群组
    groupMenuStatus,
    editGroupMenu,
    setGroupData,
    setGroupName
}