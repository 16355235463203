/* 标注平台接口 */
const dimensionService = require('../utils/dimension-request');

// 登录
export function getDimToken(data) {
    return dimensionService({
        url: (localStorage.getItem('demiension_url') || '' ) + '/api/current-user/token',
        method: 'get',
        params: data
    })
}

// 创建项目
export function createDimProject(data) {
    return dimensionService({
        url: (localStorage.getItem('demiension_url') || '' ) +  '/api/projects/',
        method: 'post',
        data
    })
}

// 文件上传
export function uploadFile(id, data) {
    return dimensionService({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/projects/${id}/import?commit_to_project=false&return_task_ids=false`,
        method: 'post',
        data
    })
}

// 导入文件
export function importFile(id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/projects/${id}/reimport`,
        method: 'post',
        data
    })
}

// 任务列表
export function getTaskList(data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  '/api/tasks/',
        method: 'get',
        params: data
    })
}

// 删除文件
export function deleteTask(id) {
    return dimensionService({
        timeout: '',
        url:  (localStorage.getItem('demiension_url') || '' ) + `/api/tasks/${id}/`,
        method: 'delete'
    })
}

// 删除上传的图片
export function deleteUploadImage(project, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) + `/api/projects/${project}/file-uploads`,
        method: 'delete',
        data
    })
}

// 删除图片
export function deleteImageTask(id, project, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) + `/api/dm/actions?id=${id}&project=${project}`,
        method: 'post',
        data
    })
}

// 获取项目
export function getProject(id) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  '/api/projects/' + id,
        method: 'get'
    })
}

// 设置变量
export function setValidate(id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/projects/${id}/validate`,
        method: 'post',
        data
    })
}

// 设置示例任务
export function setSampleTask(id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/projects/${id}/sample-task`,
        method: 'post',
        data
    })
}

// 保存项目
export function saveProject(id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/projects/${id}`,
        method: 'patch',
        data
    })
}

// 获取任务
export function getTask(id) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  '/api/tasks/' + id,
        method: 'get'
    })
}

// 提交标注
export function submitDimension(taskid, id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/tasks/${taskid}/annotations?project=${id}`,
        method: 'post',
        data
    })
}

// 更新标注
export function updateDimension(annotationsid, taskid, id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/annotations/${annotationsid}?taskID=${taskid}&project=${id}`,
        method: 'patch',
        data
    })
}

// 下载文件
export function donwloadFile(id, is_download, exportType) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/projects/${id}/export?is_download=${is_download}&exportType=${exportType}`,
        method: 'get',
        responseType: 'blob',
    })
}

// 本地下载文件列表
export function downloadLocalFile(id) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/projects/${id}/export/formats`,
        method: 'get'
    })
}

// 获取views
export function getViews(id) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/dm/views?project=${id}`,
        method: 'get'
    })
}

// 创建views
export function createViews(tab_id, project, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/dm/views?tabID=${tab_id}&project=${project}`,
        method: 'post',
        data
    })
}

// 获取分类任务列表
export function getCategorizeTaskList(page, page_size, view, project) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/tasks?page=${page}&page_size=${page_size}&view=${view}&project=${project}`,
        method: 'get'
    })
}

// 删除任务
export function removeTask(id, project, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '' ) +  `/api/dm/actions?id=${id}&project=${project}`,
        method: 'post',
        data
    })
}

// 重命名
export function renameCategorize(id, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/projects/${id}/validate/`,
        method: 'patch',
        timeout: '',
        data
    })
}

// view过滤器
export function createViewsFilter(id, project, data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/dm/views/${id}?interaction=filter&project=${project}`,
        method: 'patch',
        data
    })
}

// 过滤器获取分类任务列表
export function getFilterCategorizeTaskList(page, page_size, view, project) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/tasks?page=${page}&page_size=${page_size}&view=${view}&interaction=filter&project=${project}`,
        method: 'get'
    })
}

// 批量标注
export function batchAnnotation(data) {
    return dimensionService({
        timeout: '',
        url: (localStorage.getItem('demiension_url') || '') + `/api/annotations/batch`,
        method: 'post',
        data
    })
}
