import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import bindAll from 'lodash.bindall';
import classNames from 'classnames';
import VM from 'scratch-vm';
import styles from './work.css';
import {defineMessages} from 'react-intl';

import peopleIcon from './people.png'
import timeIcon from './time.png'
import reduceIcon from './reduce.png'
import addIcon from './add.png'
// import uploadIcon from './upload.svg'

import { setTaskRemark, setTask, setCorrectInfo, setRichTextContent } from '../../reducers/status'
import { uploadWork, homeworkCorrecting, deleteFiles } from '../../api/manage'
import { setDescription, setWorkCover, getConfigKey } from '../../reducers/work';

import QuillContainer from '../quill-container/quill-container.jsx'

const messages = defineMessages({
    task1: {
        id: 'gui.work.task1',
        description: 'Label for the name of the task1',
        defaultMessage: 'task1'
    },
    taskRequirements: {
        id: 'gui.work.taskRequirements',
        description: 'Label for the name of the taskRequirements',
        defaultMessage: 'taskRequirements'
    },
    workDescription: {
        id: 'gui.workDialog.workDescription',
        description: 'Label for the name of the workDescription',
        defaultMessage: 'workDescription'
    },
    workCover: {
        id: 'gui.workDialog.workCover',
        description: 'Label for the name of the workCover',
        defaultMessage: 'workCover'
    },
    creative: {
        id: 'gui.work.creative',
        description: 'Label for the name of the creative',
        defaultMessage: 'creative'
    },
    clearLogic: {
        id: 'gui.work.clearLogic',
        description: 'Label for the name of the clearLogic',
        defaultMessage: 'clearLogic'
    },
    goodCodeQuality: {
        id: 'gui.work.goodCodeQuality',
        description: 'Label for the name of the goodCodeQuality',
        defaultMessage: 'goodCodeQuality'
    },
    smoothExperience: {
        id: 'gui.work.smoothExperience',
        description: 'Label for the name of the smoothExperience',
        defaultMessage: 'smoothExperience'
    },
    makeProgress: {
        id: 'gui.work.makeProgress',
        description: 'Label for the name of the makeProgress',
        defaultMessage: 'makeProgress'
    },
    harder: {
        id: 'gui.work.harder',
        description: 'Label for the name of the harder',
        defaultMessage: 'harder'
    },
    logicalReinforcement: {
        id: 'gui.work.logicalReinforcement',
        description: 'Label for the name of the logicalReinforcement',
        defaultMessage: 'logicalReinforcement'
    },
    needsImprovement: {
        id: 'gui.work.needsImprovement',
        description: 'Label for the name of the needsImprovement',
        defaultMessage: 'needsImprovement'
    },
    toBeDeveloped: {
        id: 'gui.work.toBeDeveloped',
        description: 'Label for the name of the toBeDeveloped',
        defaultMessage: 'toBeDeveloped'
    },
    inProgress: {
        id: 'gui.work.inProgress',
        description: 'Label for the name of the inProgress',
        defaultMessage: 'inProgress'
    },
    toBeCorrected: {
        id: 'gui.work.toBeCorrected',
        description: 'Label for the name of the toBeCorrected',
        defaultMessage: 'toBeCorrected'
    },
    canceled: {
        id: 'gui.work.canceled',
        description: 'Label for the name of the canceled',
        defaultMessage: 'canceled'
    },
    completed: {
        id: 'gui.work.completed',
        description: 'Label for the name of the completed',
        defaultMessage: 'completed'
    },
    jobSavedSuccessfully: {
        id: 'gui.menuBar.jobSavedSuccessfully',
        description: 'Label for the name of the jobSavedSuccessfully',
        defaultMessage: 'jobSavedSuccessfully'
    },
    homeworkCorrectionFailed: {
        id: 'gui.work.homeworkCorrectionFailed',
        description: 'Label for the name of the homeworkCorrectionFailed',
        defaultMessage: 'homeworkCorrectionFailed'
    },
    changeHomework: {
        id: 'gui.work.changeHomework',
        description: 'Label for the name of the changeHomework',
        defaultMessage: 'changeHomework'
    },
    viewProfile: {
        id: 'gui.work.viewProfile',
        description: 'Label for the name of the viewProfile',
        defaultMessage: 'viewProfile'
    },
    class: {
        id: 'gui.work.class',
        description: 'Label for the name of the class',
        defaultMessage: 'class'
    },
    interestClass: {
        id: 'gui.work.interestClass',
        description: 'Label for the name of the interestClass',
        defaultMessage: 'interestClass'
    },
    jobType: {
        id: 'gui.work.jobType',
        description: 'Label for the name of the jobType',
        defaultMessage: 'jobType'
    },
    programmingHomework: {
        id: 'gui.work.programmingHomework',
        description: 'Label for the name of the programmingHomework',
        defaultMessage: 'programmingHomework'
    },
    jobStatus: {
        id: 'gui.work.jobStatus',
        description: 'Label for the name of the jobStatus',
        defaultMessage: 'jobStatus'
    },
    score: {
        id: 'gui.work.score',
        description: 'Label for the name of the score',
        defaultMessage: 'score'
    },
    comment: {
        id: 'gui.work.comment',
        description: 'Label for the name of the comment',
        defaultMessage: 'comment'
    },
    enter: {
        id: 'gui.work.enter',
        description: 'Label for the name of the enter',
        defaultMessage: 'enter'
    },
    quickComments: {
        id: 'gui.work.quickComments',
        description: 'Label for the name of the quickComments',
        defaultMessage: 'quickComments'
    },
    save: {
        id: 'gui.menuBar.save',
        description: 'Label for the name of the save',
        defaultMessage: 'save'
    },
    last: {
        id: 'gui.work.last',
        description: 'Label for the name of the last',
        defaultMessage: 'last'
    },
    nextCopy: {
        id: 'gui.work.nextCopy',
        description: 'Label for the name of the nextCopy',
        defaultMessage: 'nextCopy'
    },
    One: {
        id: 'gui.work.One',
        description: 'Label for the name of the One',
        defaultMessage: 'One'     
    },
    two: {
        id: 'gui.work.two',
        description: 'Label for the name of the two',
        defaultMessage: 'two'      
    },
    three: {
        id: 'gui.work.three',
        description: 'Label for the name of the three',
        defaultMessage: 'three'    
    },
})

const TaskDetail = (props) => {
    return (
        <div className={styles.workStudent}>
            <h3 className={styles.task}>{props.intl.formatMessage(messages.task1)}</h3>
            {/* <p><strong>{ props.detail.description }</strong></p> */}
            {/* <textarea value='对话内容可复制这段文字：
            草船借箭这个故事告诉我们可以
            借助他人的力量帮助我们完成任
            务。' disabled cols="30" rows="10" style={{width: '100%', border: 'none', background: '#F5F4ED'}}>
            </textarea> */}

            <QuillContainer 
                type="work"
                remark={props.detail.description}
                readOnly={true}
                nav={false}
                vm={props.vm}
                onChangeMessage={props.onChangeMessage}
                intl={props.intl}
            />
        </div>
    )
}
const CreateTemplate = (props) => {
    let step = [props.intl.formatMessage(messages.One), props.intl.formatMessage(messages.two), props.intl.formatMessage(messages.three)][props.index]
    return (
        <div className={styles.adminContent}>
            <h3>{props.intl.formatMessage(messages.taskRequirements, {type: step})}</h3>
            {/* <textarea 
                id="textareaInput"
                className={styles.adminTextarea}
                defaultValue={props.remark}
                value={props.remark}
                cols="30" 
                rows="10"
                placeholder='请输入任务要求'
                onChange={props.adminRemark}
            ></textarea> */}
            <QuillContainer 
                type="work"
                remark={props.remark}
                readOnly={false}
                nav={true}
                vm={props.vm}
                onGetContext={props.onGetContext}
                onChangeMessage={props.onChangeMessage}
                intl={props.intl}
            />
        </div>
    )
}
const CreateDescription = (props) => {
    return (
        <div className={styles.adminContent}>
            <h3>{props.intl.formatMessage(messages.workDescription)}：</h3>
            <textarea 
                id="textareaInput"
                className={styles.adminTextarea}
                defaultValue={props.remark}
                value={props.remark}
                cols="30" 
                rows="10"
                placeholder={props.intl.formatMessage(messages.workDescription)}
                onChange={props.setDescriptionHandle}
            ></textarea>
        </div>
    )
}
const CreateWorkCover = (props) => {
    return (
        <div className={styles.workImage}>
            <h3>{props.intl.formatMessage(messages.workCover)}：</h3>
            <input type="file" accept="image/*" onChange={props.handleImageChange} />
            <img src={props.imageBaseUrl + props.workCover} alt="" className={styles.workImageIcon}/>
        </div>
    )
}

class Work extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active: 0, // 0 改作业 1 查看资料
            score_value: -1,
            score_list: [0, 20, 40, 60, 80, 100],
            drag_status: false,
            comment_list: [
                {
                    content: props.intl.formatMessage(messages.creative),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.clearLogic),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.goodCodeQuality),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.smoothExperience),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.makeProgress),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.harder),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.logicalReinforcement),
                    active: false
                },
                {
                    content: props.intl.formatMessage(messages.needsImprovement),
                    active: false
                }
            ],
            multiple: 0,
            group_active: 'next', // lase next
            remark: this.props.correct_info.correctRemark, // 备注
            status: {
                '0': props.intl.formatMessage(messages.toBeDeveloped),
                '1': props.intl.formatMessage(messages.inProgress),
                '2': props.intl.formatMessage(messages.toBeCorrected),
                '3': props.intl.formatMessage(messages.canceled),
                '4': props.intl.formatMessage(messages.completed)
            }
        }
        bindAll(this, [
            'onChangeNav',
            'onInputScore',
            'onChangeScore',
            'onReduceScore',
            'onAddScore',
            'onHandleProcess',
            'onChangeComment',
            'onTextRemark',
            'onWorkSave',
            'onLastWork',
            'onNextWork',
            'onAdminRemark',
            'onInitializeProcess',
            'onSetDescriptionHandle',
            'onHandleImageChange',
            'onDeleteFiles',
            'onGetContext'
            // 'onUpload',
            // 'handleInput'
        ]);
    }
    componentWillMount() {
    }
    componentDidMount() {
        this.onInitializeProcess()
        // if (this.props.workType === 'correct') {
        //     setTimeout(() => {
        //         let multiple = (+getComputedStyle(this.refs.WorkProcess).width.replace('px', '') - +getComputedStyle(this.refs.ProcessBtn).width.replace('px', '')) / 100
        //         this.refs.ProcessBtn.style.left = this.props.correct_info.correctGrade * multiple + 'px'
        //         this.setState({
        //             multiple: multiple
        //         })

        //         let barleft = 0
        //         let that = this
        //         this.refs.ProcessBtn.onmousedown = function(e) {
        //             e.stopPropagation();
        //             e.preventDefault();
        //             that.setState({
        //                 drag_status: true
        //             })
        //             let leftVal = e.clientX - that.refs.ProcessBtn.offsetLeft

        //             document.onmousemove = function(e) {
        //                 barleft = e.clientX - leftVal

        //                 if (barleft < 0) {
        //                     barleft = 0;
        //                 } else if (barleft > 140)
        //                     barleft = 140;
                        
        //                 let value = Math.floor(barleft / that.state.multiple)
        //                 that.refs.ScoreValue.value = value
        //                 that.refs.ProcessBtn.style.left = barleft +'px'
        //                 that.setState({
        //                     score_value: value
        //                 })
        //                 that.props.onSetCorrectInfo({
        //                     correctGrade: value
        //                 })
        //             }
        //             document.onmouseup = function() {
        //                 document.onmousemove = null
        //                 document.onmouseup = null
        //                 setTimeout(() => {
        //                     that.setState({
        //                         drag_status: false
        //                     })
        //                 })
        //             }
        //         }
        //     })
        // }
    }
    componentDidUpdate(prevProps) {
        if (this.state.score_value != prevProps.correct_info.correctGrade) {
            this.setState({
                score_value: prevProps.correct_info.correctGrade
            })
            if (this.refs.WorkProcess) {
                let multiple = (+getComputedStyle(this.refs.WorkProcess).width.replace('px', '') - +getComputedStyle(this.refs.ProcessBtn).width.replace('px', '')) / 100
                this.refs.ProcessBtn.style.left = this.props.correct_info.correctGrade * multiple + 'px'
            }
        }
    }
    onInitializeProcess() {
        if (this.props.workType === 'correct') {
            setTimeout(() => {
                let multiple = (+getComputedStyle(this.refs.WorkProcess).width.replace('px', '') - +getComputedStyle(this.refs.ProcessBtn).width.replace('px', '')) / 100
                this.refs.ProcessBtn.style.left = this.props.correct_info.correctGrade * multiple + 'px'
                this.setState({
                    multiple: multiple
                })

                let barleft = 0
                let that = this
                this.refs.ProcessBtn.onmousedown = function(e) {
                    e.stopPropagation();
                    e.preventDefault();
                    that.setState({
                        drag_status: true
                    })
                    let leftVal = e.clientX - that.refs.ProcessBtn.offsetLeft

                    document.onmousemove = function(e) {
                        barleft = e.clientX - leftVal

                        if (barleft < 0) {
                            barleft = 0;
                        } else if (barleft > 140)
                            barleft = 140;
                        
                        let value = Math.floor(barleft / that.state.multiple)
                        that.refs.ScoreValue.value = value
                        that.refs.ProcessBtn.style.left = barleft +'px'
                        that.setState({
                            score_value: value
                        })
                        that.props.onSetCorrectInfo({
                            correctGrade: value
                        })
                    }
                    document.onmouseup = function() {
                        document.onmousemove = null
                        document.onmouseup = null
                        setTimeout(() => {
                            that.setState({
                                drag_status: false
                            })
                        })
                    }
                }
            })
        }
    }
    onChangeNav(type) {
        this.setState({
            active: type
        })
        if (type === 0) this.onInitializeProcess()
    }
    onInputScore(e) {
        let value = Math.floor(e.target.value)
        if (value > 100) {
            e.target.defaultValue = 100
            e.target.value = 100
            value = 100
        } else if(value < 0)  {
            e.target.defaultValue = 0
            e.target.value = 0
            value = 0
        } else {
            e.target.defaultValue = value
            e.target.value = value
        }
        this.setState({
            score_value: value
        })
        this.props.onSetCorrectInfo({
            correctGrade: value
        })
        
        this.refs.ProcessBtn.style.left = value * this.state.multiple + 'px'
    }
    onChangeScore(e) {
        if (e.target.nodeName === 'UL') return
        this.setState({
            score_value: e.target.innerText
        })
        this.props.onSetCorrectInfo({
            correctGrade: e.target.innerText
        })
        this.refs.ScoreValue.value = e.target.innerText
        this.refs.ProcessBtn.style.left = e.target.innerText * this.state.multiple + 'px'
    }
    onReduceScore() {
        let value = (+this.props.correct_info.correctGrade - 1) < 0 ? 0 : +this.props.correct_info.correctGrade - 1
        this.setState({
            score_value: value
        })
        this.props.onSetCorrectInfo({
            correctGrade: value
        })
        this.refs.ScoreValue.value = value
        this.refs.ProcessBtn.style.left = value * this.state.multiple + 'px'
    }
    onAddScore() {
        let value = (+this.props.correct_info.correctGrade + 1) > 100 ? 100 : +this.props.correct_info.correctGrade + 1
        this.setState({
            score_value: value
        })
        this.props.onSetCorrectInfo({
            correctGrade: value
        })
        this.refs.ScoreValue.value = value
        this.refs.ProcessBtn.style.left = value * this.state.multiple + 'px'
    }
    onHandleProcess(e) {
        if (this.state.drag_status) return
        e.stopPropagation()
        e.preventDefault()
        let value = e.clientX- e.target.offsetLeft
        if (value > 140) value = 140
        
        this.refs.ScoreValue.value = Math.floor(value / this.state.multiple)
        this.refs.ProcessBtn.style.left = value +'px'
        this.setState({
            score_value: Math.floor(value / this.state.multiple)
        })
        this.props.onSetCorrectInfo({
            correctGrade: Math.floor(value / this.state.multiple)
        })
    }
    onChangeComment(index) {
        let temp_v = this.state.comment_list
        temp_v[index].active = !temp_v[index].active
        this.setState({
            comment_list: temp_v
        }, () => {
            console.log(this.state)
        })
    }
    onTextRemark(e) {
        this.setState({
            remark: this.refs.workRemark.value
        })
        this.props.onSetCorrectInfo({
            correctRemark: this.refs.workRemark.value
        })
    }
    onWorkSave() {
        let old_file = this.props.correct_info.correctFile
        this.props.saveProjectSb3()
            .then(async connect => {
                let formData = new FormData()
                const file = new File([connect], '1.sk', { type: 'text/plain', lastModified: Date.now() })
                formData.append('file', file)
                formData.append('path', '/task')
                let response1 = await uploadWork(formData)

                if (response1.msg === '操作成功') {
                    let obj = {...this.props.correct_info}
                    obj['correctFile'] = response1.path
                    homeworkCorrecting(obj)
                        .then((response) => {
                            if (response.msg === '操作成功')  {
                                this.props.onChangeMessage({
                                    visible: true,
                                    content: this.props.intl.formatMessage(messages.jobSavedSuccessfully),
                                    type: 'success'
                                })
                                // 删除旧文件
                                let formData = new FormData()
                                formData.append('path', old_file)
                                deleteFiles(formData)
                                    .then((response) => {
                                        console.log(response, '删除文件')
                                    })
                                    .catch(() => {})
                            } else {
                                this.props.onChangeMessage({
                                    visible: true,
                                    content: this.props.intl.formatMessage(messages.homeworkCorrectionFailed),
                                    type: 'fail'
                                })
                            }
                        })
                        .catch(() => {})
                }
            })
    }
    onLastWork() {

    }
    onNextWork() {

    }
    // readyWork(data) {
    //     let fileReader = new FileReader()
    //     fileReader.addEventListener('load', function(e) {
    //         console.log(e)
    //         this.props.vm.loadProject(data)
    //             .then(() => {

    //             })
    //             .catch(error => {
    //                 log.warm(error)
    //                 alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
    //             })
    //     })
    //     fileReader.readAsArrayBuffer(data)
    // }
    // 任务备注
    onAdminRemark(e) {
        this.props.onSetTask({remark: e.target.value}, this.props.taskIndex - 1)
    }
    // 作品描述
    onSetDescriptionHandle(e) {
        this.props.onSetDescription(e.target.value)
    }

    // 选择文件上传
    async onHandleImageChange(event) {
        const old_workCover = this.props.workCover
        const file = event.target.files[0];
        if (file) {
            let formData = new FormData()
            const newFile = new File([file], file.name, {type: file.type})
            formData.append('file', newFile)
            formData.append('path', '/work')
            let response1 = await uploadWork(formData)
            if (response1.msg === '操作成功') {
                this.props.onSetWorkCover(response1.path)
                this.onDeleteFiles(old_workCover)
                console.log("地址：", response1.path)
            }
        }
    }
    // 上传作品-触发handleInput
    // onUpload() {
    //     this.refs.fileUpload.click()
    // }
    // 上传作品
    // handleInput(e) {
    //     let data = e.target.files[0]
    //     let fileReader = new FileReader()
    //     let that = this
    //     fileReader.addEventListener('load', async function(e) {
    //         let formData = new FormData()
    //         formData.append('file', data)
    //         formData.append('path', '/task')
    //         let response = await uploadWork(formData)
    //         if (response.msg === '操作成功') {
    //             that.props.onSetTask({file_path: response.path}, that.props.taskIndex - 1)
    //             alert('上传成功')
    //             that.props.vm.loadProject(fileReader.result)
    //                 .then((res) => {})
    //         }
    //     })
    //     fileReader.readAsArrayBuffer(data)
    // }

    onDeleteFiles(path) {
        let formData = new FormData()
        formData.append('path', path)
        deleteFiles(formData)
            .then((response) => {
                console.log(response, '删除文件')
            })
            .catch(() => {})
    }
    onGetContext(data) {
        this.props.onSetRichTextContent(JSON.stringify(data))
    }

    render() {
        return (
            <div className={styles.workWrapper}>
                {/* 学生 */}
                {(this.props.manage_user_message.roles[0] === 'student' && this.props.type !== 'work_detail' && this.props.type !== 'work_create') ? 
                    (
                        <TaskDetail 
                            detail={this.props.task_detail} 
                            vm={this.props.vm}
                            onChangeMessage={this.props.onChangeMessage}
                            intl={this.props.intl}
                        />
                    ) : null
                }
                {/* 老师 */}
                {(this.props.workType === 'correct' && this.props.manage_user_message.roles[0] === 'teacher')  ?
                    (<div className={styles.workTeacher}>
                        <div className={styles.workNav}>
                            <span
                                className={classNames({
                                    [styles.workEdit]: this.state.active === 0,
                                    [styles.workCheck]: this.state.active === 1
                                })}
                                onClick={() => this.onChangeNav(0)}
                            >{this.props.intl.formatMessage(messages.changeHomework)}</span>
                            <span
                                className={classNames({
                                    [styles.workEdit]: this.state.active === 1,
                                    [styles.workCheck]: this.state.active === 0
                                })}
                                onClick={() => this.onChangeNav(1)}
                            >{this.props.intl.formatMessage(messages.viewProfile)}</span>
                        </div>
                        {this.state.active === 0 ?
                            (
                                <div >
                                    <p className={styles.workTitle}>{this.props.student_homework?.course?.courseName || ''}</p>
                                    <div className={styles.workClassMessage}>
                                        <div className={styles.workPeople}>
                                            <img src={peopleIcon} />
                                            <span>{this.props.student_homework?.studentInfo?.nickName || ''}</span>
                                        </div>
                                        <div className={styles.workTime}>
                                            <img src={timeIcon} />
                                            <span>{this.props.student_homework?.correctTime || ''}</span>
                                        </div>
                                    </div>
                                    <p className={styles.workMessage}>{this.props.intl.formatMessage(messages.class)}: { this.props.student_homework?.classes?.enrollmentYear ? this.props.student_homework?.classes?.enrollmentYear + this.props.student_homework?.classes?.className : this.props.intl.formatMessage(messages.interestClass) + this.props.student_homework?.classes?.className }</p>
                                    <p className={styles.workMessage}>{this.props.intl.formatMessage(messages.jobType)}: {this.props.intl.formatMessage(messages.programmingHomework)}</p>
                                    <p className={styles.workMessage}>{this.props.intl.formatMessage(messages.jobStatus)}: { this.state.status[this.props.student_homework?.status] }</p>
                                    <h3>{this.props.intl.formatMessage(messages.score)}</h3>
                                    <input 
                                        type="number"
                                        className={styles.workScore} 
                                        value={this.props.correct_info.correctGrade}
                                        defaultValue={this.props.correct_info.correctGrade} 
                                        onChange={this.onInputScore}
                                        ref="ScoreValue"
                                    />
                                    <div className={styles.workScoreBox}>
                                        <img className={styles.workProcessImg} src={reduceIcon} onClick={this.onReduceScore} />
                                        <div className={styles.workProcess} ref="WorkProcess" onClick={this.onHandleProcess}>
                                            <div className={styles.workProcessBtn} ref="ProcessBtn"></div>
                                        </div>
                                        <img className={styles.workProcessImg} src={addIcon} onClick={this.onAddScore} />
                                    </div>
                                    <ul className={styles.workProcessDivide} onClick={this.onChangeScore}>
                                        {this.state.score_list.map((item,index) => {
                                            return <li style={{width: '24px'}} key={index}>{item}</li>
                                        })}
                                    </ul>
                                    {/* <h3>{this.props.intl.formatMessage(messages.quickComments)}</h3>
                                    <div className={styles.workComment}>
                                        {this.state.comment_list.map((item, index) => {
                                            return <div 
                                                key={index} 
                                                className={styles.workCommentItem}
                                                style={{
                                                    background: item.active ? 'rgba(206, 129, 55, 0.10)' : '#ffffff'
                                                }}
                                                onClick={() => this.onChangeComment(index)}
                                            >{item.content}</div>
                                        })}
                                    </div> */}
                                    <h3>{this.props.intl.formatMessage(messages.comment)}: </h3>
                                    <textarea
                                        ref="workRemark"
                                        className={styles.workRemark} 
                                        value={this.props.correct_info.correctRemark}
                                        defaultValue={this.props.correct_info.correctRemark}
                                        placeholder={this.props.intl.formatMessage(messages.enter)}
                                        cols="30" 
                                        rows="5"
                                        onChange={this.onTextRemark}
                                    ></textarea>
                                    <input className={styles.workSave} type="button" value={this.props.intl.formatMessage(messages.save)} onClick={this.onWorkSave} />
                                    {/* <div className={styles.workBtnGroup}>
                                        <input 
                                            className={classNames(styles.btnGroup, {
                                                [styles.btnGroupActive]: this.state.group_active === 'last'
                                            })}
                                            type="button" 
                                            value={this.props.intl.formatMessage(messages.last)}
                                            onClick={this.onLastWork}
                                        />
                                        <input 
                                            className={classNames(styles.btnGroup, {
                                                [styles.btnGroupActive]: this.state.group_active === 'next'
                                            })}
                                            type="button" 
                                            value={this.props.intl.formatMessage(messages.nextCopy)}
                                            onClick={this.onNextWork}
                                        />
                                    </div> */}
                                </div>
                            ) :
                            (
                                <TaskDetail 
                                    detail={this.props.task_detail} 
                                    vm={this.props.vm} 
                                    onChangeMessage={this.props.onChangeMessage}
                                    intl={this.props.intl}
                                />
                            )
                        }
                    </div>) : null
                }
                {/* 管理 */}
                {(this.props.workType === 'create' || this.props.workType === 'detail') && this.props.manage_user_message.roles[0] === 'teacher' ?
                    (<CreateTemplate 
                        index={this.props.taskIndex - 1} 
                        remark={this.props.task[this.props.taskIndex - 1].remark || ''}
                        vm={this.props.vm}
                        adminRemark={this.onAdminRemark}
                        onGetContext={this.onGetContext}
                        onChangeMessage={this.props.onChangeMessage}
                        intl={this.props.intl}
                    />) : null
                }
                {/* 作品描述 */}
                {/* {(this.props.isWork === true) ? 
                (<CreateDescription
                    remark={this.props.description}
                    setDescriptionHandle={this.onSetDescriptionHandle}
                    />
                ): null} */}
                {/* 作品封面 */}
                {/* {(this.props.isWork === true) ? (
                    <CreateWorkCover 
                    handleImageChange = {this.onHandleImageChange}
                    workCover = {this.props.workCover}
                    imageBaseUrl= {this.props.imageBaseUrl}
                    />
                ): null} */}
                {/* 上传 */}
                {/* <div className={styles.uploadWork}>
                    <img src={uploadIcon} onClick={this.onUpload} />
                    <div className={styles.uploadTips}>本地上传</div>
                </div>
                <input 
                    ref="fileUpload"
                    className={styles.workUpload}
                    id="work-upload" 
                    type="file" 
                    accept=".sb,.sb2,sb3,.sk" 
                    onChange={this.handleInput} 
                /> */}
            </div>
        )
    }
}

Work.propTypes = {
    workType: PropTypes.string,
    vm: PropTypes.instanceOf(VM),
    taskIndex: PropTypes.number,
    onChangeMessage: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        task: state.scratchGui.status.task,
        taskIndex: state.scratchGui.status.task_index,
        taskRemark: state.scratchGui.status.task_remark,
        manage_user_message: state.scratchGui.login.manage_user_message,
        saveProjectSb3: state.scratchGui.vm.saveProjectSb3.bind(state.scratchGui.vm),
        correct_info: state.scratchGui.status.correct_info,
        student_homework: state.scratchGui.status.student_homework,
        type: state.scratchGui.work.type,
        description: state.scratchGui.work.description,
        // isWork: state.scratchGui.work.isWork,
        workCover: state.scratchGui.work.workCover,
        imageBaseUrl: state.scratchGui.work.imageBaseUrl
        // dialogVisible: state.scratchGui.work.dialogVisible
        // task_detail: state.scratchGui.status.task_detail
    }
}

const mapDispatchToProps = dispatch => ({
    onSetTaskRemark: (params) => dispatch(setTaskRemark(params)),
    onSetTask: (params, index) => dispatch(setTask(params, index)),
    onSetCorrectInfo: (params) => dispatch(setCorrectInfo(params)),
    onSetDescription: (params) => dispatch(setDescription(params)),
    onSetWorkCover: (params) => dispatch(setWorkCover(params)),
    onSetImageBaseUrl: (params) => dispatch(setImageBaseUrl(params)),
    onSetRichTextContent: (params) => dispatch(setRichTextContent(params))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Work)