const SET_TRAIN = 'train'
const SET_COMPILE = 'compile'
const SET_TPU = 'tpu'
const SET_CLOUND = 'cloud'
const RESET_ALL = 'reset-all'

// initial state
const initState ={
    train: null,
    compile: null,
    tpu: null,
    cloud: null
}

// reducer
const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch(action.type) {
        case SET_TRAIN:
        case SET_COMPILE:
        case SET_TPU:
        case SET_CLOUND:
            return Object.assign({}, state, {
                [action.type]: action.param
            })
        case RESET_ALL:
            return Object.assign({}, state, {
                train: null,
                compile: null,
                tpu: null,
                cloud: null
            })
        default:
            return state
    }
}

const setCodeStatus = (type, param) => ({
    type,
    param
})

const resetAll = (type) => ({
    type: RESET_ALL
})

export {
    reducer as default,
    setCodeStatus,
    resetAll,
    initState as codeStatusInitState,
}