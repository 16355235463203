import React from 'react';
import classNames from 'classnames';
// import bindAll from 'lodash.bindall';
import styles from './add-camera.css';
import closeIcon from '../img/close.svg'

import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    addDeviceCamera: {
        id: 'gui.takePhoto.addDeviceCamera',
        description: '添加设备摄像头',
        defaultMessage: '添加设备摄像头'
    },
    deviceIP: {
        id: 'gui.takePhoto.deviceIP',
        description: '设备ip',
        defaultMessage: '设备ip'
    },
    example: {
        id: 'gui.takePhoto.example',
        description: '例如：192.168.1.1',
        defaultMessage: '例如：192.168.1.1'
    },
    cancel: {
        id: 'gui.prompt.cancel',
        description: '取消',
        defaultMessage: '取消'
    },
    connect: {
        id: 'gui.connection.connect',
        description: '连接',
        defaultMessage: '连接'
    },
})

class AddCamera extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {}
    componentDidUpdate() {}
    render() {
        return (
            <>
                <div className={styles.mask}></div>
                <div className={classNames(styles.maskBox, styles.addBox)}>
                    <div className={styles.addHeader}>
                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.addDeviceCamera)}</span>
                        <img 
                            className={styles.closeIcon} 
                            src={closeIcon} 
                            alt="" 
                            onClick={
                                () => this.props.changeAddCamera(false)
                            }
                        />
                    </div>

                    <div className={styles.addInput}>
                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.deviceIP)}：</span>
                        <input 
                            type="text" 
                            placeholder={this.props.intl.formatMessage(LANG_MESSAGE.example)}
                            value={this.props.add_camera_ip}
                            onChange={this.props.changeCameraIp}
                        />
                    </div>
                    <div className={styles.addFooter}>
                        <input 
                            className={styles.addCancle} 
                            type="button" 
                            value={this.props.intl.formatMessage(LANG_MESSAGE.cancel)}
                            onClick={
                                () => this.props.changeAddCamera(false)
                            }
                        />
                        <input 
                            className={styles.addComfirm} 
                            type="button" 
                            value={this.props.intl.formatMessage(LANG_MESSAGE.connect)}
                            onClick={this.props.connectCamera} 
                        />
                    </div>
                </div>
            </>
        )
    }
}
export default AddCamera