/* 单点登录接口 */
const casService = require('../utils/cas-request.js')

export function getCastoken(data) {
    return casService({
        url: '/api/login/oauth/access_token',
        method: 'post',
        data
    })
}

export function checkToken(data) {
    return casService({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: '',
        url: '/api/login/oauth/introspect',
        method: 'post',
        data
    })
}
