/* canvas画板 */
class Drawer {
    constructor(id, config) {
        this.canvas = document.getElementById(id)
        this.ctx = null
        this.operation = null // 操作 0绘制 1拖拽 2伸缩，旋转
        this.position = { // 坐标(当前操作的坐标)
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0
        }
        this.startWidth = 0
        this.startHeight = 0
        this.is_draw = false // 绘画状态
        this.borderLineWidth = 2

        this.offset = { // 偏移量
            x: 0,
            y: 0
        }

        // 拖拽
        this.is_select = false // 是否选中
        this.flag = false // 点击鼠标
        this.drag_index = null // 拖拽下标
        this.pre_drag_index = null // 上一次拖拽
        this.drag_position = { // 拖拽坐标
            x: 0,
            y: 0
        }

        // 伸缩
        this.scale = true // 伸缩状态
        this.scale_type = null // 伸缩状态

        // 功能配置
        this.must = 'color' // 必须条件，符合开启功能
        this.color = null // 绘制颜色
        this.label_name = '' // 颜色标签名称
        this.transparent_color = null // 透明颜色
        this.bg = null // 背景图
        this.text = config?.text || '删除'

        // 撤销/重做
        this.layers = [] // 图层记录
        this.undo_list = [] // 用于保存所有操作，用于撤销和重做
        this.redo_list = [] // 用于保存所有撤销的操作，用于重做
        this.id = 0

        // 事件传递参数
        this.event = {
            type: null,
            id: null
        }

        this.canvas.onmousedown = this.onmousedown
        this.canvas.onmousemove = this.onmousemove
        this.canvas.onmouseup = this.onmouseup

        // 判断浏览器是否支持
        if (this.canvas.getContext) {
            this.ctx = this.canvas.getContext('2d')
        } else {
            throw new Error('浏览器不支持')
        }
    }

    /**
     * 参数读取/配置
     */
    // 设置背景图
    setBackgroundImage = (image) => {
        this.bg = new Image()
        this.bg.src = image
        this.bg.onload = (e) => {
            this.drawAll()
        }
    }
    // 设置颜色
    setColor = (type) => {
        if (type === this.color) {
            this.color = null
        } else {
            this.color = type
        }
    }
    // 设置标签名称
    setLabelName = (type) => {
        this.label_name = type
    }
    // 获取图层数据
    getLayers = () => {
        return this.layers
    }
    // 设置数据
    setData = (data, color) => {
        this.layers = []
        let proportion_width = this.canvas.width / 100
        let proportion_height = this.canvas.height / 100
        console.log(data, color)
        let default_color = [color[0]]
        data.forEach((item, index) => {
            if (item.result != null && item.result.length > 0) {
                item.result.forEach((t, i) => {
                    let x = t.value.x * proportion_width
                    let y = t.value.y * proportion_height
                    let width = t.value.width * proportion_width
                    let height = t.value.height * proportion_height
                    let cur_color;

                    let temp = {}
                    temp.x = x
                    temp.y = y
                    temp.width = width
                    temp.height = height
                    temp.id = t.id
                    temp.angle = t.value.rotation || 0
                    temp.translateX = 0
                    temp.translateY = 0

                    // 处理标签被删除
                    if (t.value?.rectanglelabels == null || t.value?.rectanglelabels[0] == null) {
                        cur_color = default_color
                    } else {
                        cur_color = color.filter((item) => item.value === t.value.rectanglelabels[0])
                        if (cur_color.length === 0) {
                            cur_color = default_color
                        }
                        temp.label_name = t.value.rectanglelabels[0]
                    }
                    temp.color = cur_color[0].background_opacity.replace('.2', '.3')
                    // 创建id
                    temp.create_id = item.id
                    
                    this.layers.push(temp)
                    this.id++
                })
            }
        })
        // console.log(this.layers, '图层')
        setTimeout(() => {
            this.drawAll()
        }, 100)
    }

    // 设置鼠标样式（旋转过后的鼠标指针全乱了，这里重新计算）
    changeMouseCursor(setting) {
        const { mouseX, mouseY, type } = setting;
        // let {x, y, width, height} = this.layers[this.drag_index]
        // const centerX = x + width / 2;
        // const centerY = y + height / 2;
        // if (mouseX < centerX && mouseY < centerY) {
        //     this.canvas.style.cursor = "nw-resize";
        // } else if (mouseX > centerX && mouseY < centerY) {
        //     this.canvas.style.cursor = "ne-resize";
        // } else if (mouseX < centerX && mouseY > centerY) {
        //     this.canvas.style.cursor = "sw-resize";
        // } else if (mouseX > centerX && mouseY > centerY) {
        //     this.canvas.style.cursor = "se-resize";
        // } else if (mouseX === centerX && mouseY < centerY) {
        //     this.canvas.style.cursor = "n-resize";
        // } else if (mouseX === centerX && mouseY > centerY) {
        //     this.canvas.style.cursor = "s-resize";
        // } else if (mouseX < centerX && mouseY === centerY) {
        //     this.canvas.style.cursor = "w-resize";
        // } else if (mouseX > centerX && mouseY === centerY) {
        //     this.canvas.style.cursor = "e-resize";
        // } else if (mouseX === centerX && mouseY === centerY) {
        //     this.canvas.style.cursor = "move";
        // }
        if (type === 'top') {
            this.canvas.style.cursor = 'n-resize'
        } else if (type === 'bottom') {
            this.canvas.style.cursor = 's-resize'
        } else if (type === 'left') {
            this.canvas.style.cursor = 'w-resize'
        } else if (type === 'right') {
            this.canvas.style.cursor = 'e-resize'
        } else if (type === 'left-top') {
            this.canvas.style.cursor = 'nw-resize'
        } else if (type === 'right-top') {
            this.canvas.style.cursor = 'ne-resize'
        } else if (type === 'left-bottom') {
            this.canvas.style.cursor = 'sw-resize'
        } else if (type === 'right-bottom') {
            this.canvas.style.cursor = 'se-resize'
        }
    }
    // 重置默认值
    resizeDefault = () => {
        this.flag = false
        this.is_draw = false
        this.operation = null
        this.scale_type = null
        this.offset = {
            x: 0,
            y: 0
        }
        this.position = {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0
        }
        this.drag_position = {
            x: 0,
            y: 0
        }
        this.canvas.style.cursor = 'default'
    }
    // 重置配置
    resetConfig = () => {
        this.operation = null
        this.color = null
        this.layers = []
        
        this.is_draw = false
        this.flag = false
        this.is_select = false
        this.drag_index = null
        this.pre_drag_index = null
        this.scale_type = null
    }
    // 清空画布
    clearAll = () => {
        this.ctx.clearRect(-this.canvas.width, -this.canvas.height, this.canvas.width * 2, this.canvas.height * 2)
    }

    /**
     * 撤销，重做功能
     */
    // 获取撤销
    getUndo = () => {
        return this.undo_list.length
    }
    // 获取重做
    getRedo = () => {
        return this.redo_list.length
    }
    // 撤销
    undoHandle = () => {
        if (this.undo_list.length === 0) return false
        // 关闭辅助线
        this.pre_drag_index = null 
        this.is_select = false

        let cur = this.undo_list.pop()
        let arr = this.undo_list.filter((item) => item.id === cur.id)
        if (arr.length > 0) {
            let prev = this.undo_list[this._undo_list.length - 1]
            if (cur.id === prev.id) {
                let index = this.layers.findIndex((item) => item.id === prev.id)
                this.layers[index] = {...prev}
            } else {
                let index = this.layers.findIndex((item) => item.id === cur.id)
                this.layers[index] = {...arr[arr.length -1]}
            }
        } else {
            this.layers.pop()
        }
        this.redo_list.push(cur)
        this.drawAll()
    }
    // 重做
    redoHandle = () => {
        if (this.redo_list.length === 0) return false
        // 关闭辅助线
        this.pre_drag_index = null 
        this.is_select = false

        let cur = this.redo_list.pop()
        let index = this.layers.findIndex((item) => item.id === cur.id)
        if (this.layers.length === 0 || index === -1) {
            this.layers.push({...cur})
        } else {
            this.layers[index] = {...cur}
        }

        this.undo_list.push(cur)
        this.drawAll()
    }

    /**
     * 删除功能
     */
    onDelete = (e) => {
        const mouseX = e.offsetX
        const mouseY = e.offsetY
        let {x, y, width, height, angle} = this.layers[this.drag_index]
        return this.#isMouseInSquare(mouseX, mouseY, {
            x: x + width - 25,
            y: y,
            width: 20,
            height: 15
        }, {x, y, width, height, angle})
    }
    removeSelectRect = () => {
        if (this.is_select) {
            this.layers.splice(this.drag_index, 1)
            this.drag_index = null
            this.is_select = false
            this.drawAll()
        }
    }

    /**
     * 坐标获取
     */
    // 获取坐标位置
    #getPosition = (e) => {
        let rect = this.canvas.getBoundingClientRect() // 返回元素的大小及其相对于视口的位置
        let x = Math.floor((e.clientX - rect.left) * this.canvas.width / rect.width)
        let y = Math.floor((e.clientY - rect.top) * this.canvas.height / rect.height)
        return {x, y}
    }

    /* 
     * 坐标位置判断 
     */
    // 鼠标是否落在矩形图层上
    #isPointInRect = (x, y) => {
        // 从后面搜索，依据最后画的图层优先级高
        for (let i = this.layers.length - 1; i >= 0; i--) {
            let data = this.layers[i]
            // 处理不同方向绘制图形
            let minX = Math.min(data.x, data.x + data.width)
            let maxX = Math.max(data.x, data.x + data.width)
            let minY = Math.min(data.y, data.y + data.height)
            let maxY = Math.max(data.y, data.y + data.height)

            if (x >= minX && x <= maxX && y >= minY && y<= maxY) {
                return {
                    i,
                    ...data
                }
            }
        }
        return null
    }
    #isScaleing = () => {
        if (
            this.canvas.style.cursor === 'n-resize' || 
            this.canvas.style.cursor === 'e-resize' || 
            this.canvas.style.cursor === 's-resize' || 
            this.canvas.style.cursor === 'w-resize' || 
            this.canvas.style.cursor === 'ew-resize' || 
            this.canvas.style.cursor === 'ns-resize' || 
            this.canvas.style.cursor === 'nesw-resize' || 
            this.canvas.style.cursor === 'nwse-resize' || 
            this.canvas.style.cursor === 'col-resize' || 
            this.canvas.style.cursor === 'all-scroll' || 
            this.canvas.style.cursor === 'move' || 
            this.canvas.style.cursor === 'nw-resize' || 
            this.canvas.style.cursor === 'ne-resize' || 
            this.canvas.style.cursor === 'ne-resize' || 
            this.canvas.style.cursor === 'nesw-resize' || 
            this.canvas.style.cursor === 'ns-resize' || 
            this.canvas.style.cursor === 'nwse-resize'
        ) {
            return true;
        } else {
            return false;
        }
    }
    // 判断鼠标是否在方块内
    #isMouseInSquare = (mouseX, mouseY, squareSettings, Rect) => {
        const { newMouseX, newMouseY } = this.changeMouseCoordinate(mouseX, mouseY, Rect);
        return (
          newMouseX > squareSettings.x &&
          newMouseX < squareSettings.x + squareSettings.width &&
          newMouseY > squareSettings.y &&
          newMouseY < squareSettings.y + squareSettings.height
        );
    }
    // 判断鼠标是否在边框内
    #isMouseInBorder = (mouseX, mouseY, position) => {
        let {x, y, width, height, angle} = this.layers[this.drag_index]
        if (position === 'left') {
            return this.#isMouseInSquare(mouseX, mouseY, {
              x: x - this.borderLineWidth - 5,
              y: y + 5,
              width: this.borderLineWidth + 5,
              height: height - 10
            }, {x, y, width, height, angle})
        } else if (position === 'right') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth + width,
                y: y + 5,
                width: this.borderLineWidth + 5,
                height: height - 10,
            }, {x, y, width, height, angle})
        } else if (position === 'top') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth + 5,
                y: y - 5,
                width: width - 10,
                height: this.borderLineWidth + 5
            }, {x, y, width, height, angle})
        } else if (position === 'bottom') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth + 5,
                y: y + height - this.borderLineWidth,
                width: width - 10,
                height: this.borderLineWidth + 5,
            }, {x, y, width, height, angle})
        } else if (position === 'left-top') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth - 5,
                y: y - this.borderLineWidth - 5,
                width: this.borderLineWidth + 10,
                height: this.borderLineWidth + 10
            }, {x, y, width, height, angle})
        } else if (position === 'left-bottom') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth - 5,
                y: y + height - this.borderLineWidth,
                width: this.borderLineWidth + 10,
                height: this.borderLineWidth + 10
            }, {x, y, width, height, angle})
        } else if (position === 'right-top') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth + width,
                y: y - 5,
                width: this.borderLineWidth + 10,
                height: this.borderLineWidth + 10
            }, {x, y, width, height, angle})
        } else if (position === 'right-bottom') {
            return this.#isMouseInSquare(mouseX, mouseY, {
                x: x - this.borderLineWidth + width,
                y: y + height - this.borderLineWidth,
                width: this.borderLineWidth + 10,
                height: this.borderLineWidth + 10
            }, {x, y, width, height, angle})
        } 
        // else if (position === 'center-top') {
        //     return this.#isMouseInSquare(mouseX, mouseY, {
        //         x: x + width / 2 - 10,
        //         y: y - 20,
        //         width: 20,
        //         height: 20,
        //     }, {x, y, width, height, angle})
        // }
    }
    /**
     * 将鼠标坐标点转换为旋转后的坐标点
     * @param {Number} mouseX 鼠标x坐标
     * @param {Number} mouseY 鼠标y坐标
     * @param {Object} squareSettings 方块设置
     * @param {Number} squareSettings.x 方块x坐标
     * @param {Number} squareSettings.y 方块y坐标
     * @param {Number} squareSettings.width 方块宽度
     * @param {Number} squareSettings.height 方块高度
     * @param {Number} squareSettings.angle 方块旋转角度（单位：度）
     */
    changeMouseCoordinate = (mouseX, mouseY, squareSettings) => {
        const { x, y, width, height, angle } = squareSettings;
        // 方块中心点的坐标
        const centerX = x + width / 2;
        const centerY = y + height / 2;

        // 将角度转换为弧度
        const radian = (360 - angle) * (Math.PI / 180);

        // 将鼠标坐标转换为以中心点为原点的坐标
        const relativeX = mouseX - centerX;
        const relativeY = mouseY - centerY;

        // 计算旋转后的坐标
        const newRelativeX = relativeX * Math.cos(radian) - relativeY * Math.sin(radian);
        const newRelativeY = relativeX * Math.sin(radian) + relativeY * Math.cos(radian);

        // 将旋转后的坐标转换回原始坐标系
        const newMouseX = newRelativeX + centerX;
        const newMouseY = newRelativeY + centerY;

        return { newMouseX, newMouseY };
    }

    // 缩放的重复代码太多，抽离出来
    scaleHelpFunc = (obj) => {
        const tmpInfo = this.scaleRect({
            rectX: obj.startRectX,
            rectY: obj.startRectY,
            width: obj.startWidth,
            height: obj.startHeight,
            scaleX: obj.scaleX,
            scaleY: obj.scaleY,
            scaleMode: obj.scaleMode
        })
        if (tmpInfo[obj.limitName] >= 20) {
            this.layers[this.drag_index].x = tmpInfo.rectX;
            this.layers[this.drag_index].y = tmpInfo.rectY;
            this.layers[this.drag_index].width = tmpInfo.width;
            this.layers[this.drag_index].height = tmpInfo.height;

            let beforeX = obj.startRectX;
            let beforeY = obj.startRectY;
            let afterX = this.layers[this.drag_index].x;
            let afterY = this.layers[this.drag_index].y;

            if (obj.scaleMode === 'left-top') {
                // 左上角点不动
                beforeX = obj.startRectX;
                beforeY = obj.startRectY;
                afterX = this.layers[this.drag_index].x;
                afterY = this.layers[this.drag_index].y;
            } else if (obj.scaleMode === 'right-top') {
                // 右上角点不动
                beforeX = obj.startRectX + obj.startWidth;
                beforeY = obj.startRectY;
                afterX = this.layers[this.drag_index].x + this.layers[this.drag_index].width;
                afterY = this.layers[this.drag_index].y;
            } else if (obj.scaleMode === 'left-bottom') {
                // 左下角点不动
                beforeX = obj.startRectX;
                beforeY = obj.startRectY + obj.startHeight;
                afterX = this.layers[this.drag_index].x;
                afterY = this.layers[this.drag_index].y + this.layers[this.drag_index].height;
            } else if (obj.scaleMode === 'right-bottom') {
                // 右下角点不动
                beforeX = obj.startRectX + obj.startWidth;
                beforeY = obj.startRectY + obj.startHeight;
                afterX = this.layers[this.drag_index].x + this.layers[this.drag_index].width;
                afterY = this.layers[this.drag_index].y + this.layers[this.drag_index].height;
            }

            // 新增：记录拉伸前旋转后的x坐标和y坐标
            let beforeObj = this.changeMouseCoordinate(beforeX, beforeY, {
                x: obj.startRectX,
                y: obj.startRectY,
                width: obj.startWidth,
                height: obj.startHeight,
                angle: 360 - this.layers[this.drag_index].angle, // 注意鼠标转化方法这个是反的，所以得再反一下
            })
            // 新增：记录拉伸后旋转后的x坐标和y坐标
            let afterObj = this.changeMouseCoordinate(afterX, afterY, {
                x: this.layers[this.drag_index].x,
                y: this.layers[this.drag_index].y,
                width: this.layers[this.drag_index].width,
                height: this.layers[this.drag_index].height,
                angle: 360 - this.layers[this.drag_index].angle, // 注意鼠标转化方法这个是反的，所以得再反一下
            })
            // 新增：记录偏移量
            const translateX = afterObj.newMouseX - beforeObj.newMouseX;
            const translateY = afterObj.newMouseY - beforeObj.newMouseY;

            // 将偏移量加给坐标
            // this.x = this.x - translateX;
            // this.y = this.y - translateY;
            this.layers[this.drag_index].x -= translateX;
            this.layers[this.drag_index].y -= translateY;

            this.drawAll()
        }
    }
    /**
     * 缩放功能
     * callback 绘制旋转矩形的函数
     * mode 从什么地方缩放
     * setting.rectX 矩形 x 坐标
     * setting.rectY 矩形 y 坐标
     * setting.width 矩形宽度
     * setting.height 矩形高度
     * setting.scaleX 缩放比例 x
     * setting.scaleY 缩放比例 y
     */
    scaleRect = (setting, callback) => {
        const { rectX, rectY, width, height, scaleX, scaleY, scaleMode } = setting;
        this.ctx.save();
        this.ctx.translate(rectX, rectY); // 平移到 (0, 0)
        this.ctx.scale(scaleX, scaleY);
        let translateX = 0;
        let translateY = 0;
        if (scaleMode === 'left-top') {
            // 左上角点固定的缩放
        } else if (scaleMode == 'right-top') {
            // 右上角点固定的缩放
            translateX = (width - width * scaleX) / scaleX; // 补偿偏移量
        } else if (scaleMode == 'left-bottom') {
            // 左下角点固定的缩放
            translateY = (height - height * scaleY) / scaleY; // 补偿偏移量
        } else if (scaleMode == 'right-bottom') {
            // 右下角点固定的缩放
            translateX = (width - width * scaleX) / scaleX; // 补偿偏移量
            translateY = (height - height * scaleY) / scaleY; // 补偿偏移量
        }
        this.ctx.translate(translateX, translateY);
        this.ctx.translate(-rectX, -rectY); // 平移回到原点
        if (callback) {
            callback();
        }
        this.ctx.restore(); // 恢复原始状态
        return {
            rectX: rectX + translateX * scaleX,
            rectY: rectY + translateY * scaleY,
            width: width * scaleX,
            height: height * scaleY,
        }
    }

    /**
     * 画框
     */
    // 绘制图片
    drawImage = () => {
        if (this.bg) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.ctx.drawImage(this.bg, 0, 0, this.canvas.width, this.canvas.height)
        }
    }
    // 绘制矩形
    drawRect = () => {
        this.drawAll()
        let {x1, y1, x2, y2} = this.position
        this.ctx.beginPath()
        this.ctx.moveTo(x1, y1)
        this.ctx.fillStyle = this.color
        this.ctx.fillRect(x1, y1, x2 - x1, y2 - y1)
        this.ctx.closePath()

        // 画内边距透明矩形
        this.ctx.beginPath()
        let cur_color = this.color
        this.ctx.strokeStyle = cur_color.substring(0, cur_color.indexOf('.')) + '1)'
        this.ctx.lineWidth = this.borderLineWidth
        this.ctx.strokeRect(
            x1 - Math.floor(this.borderLineWidth / 2),
            y1 - Math.floor(this.borderLineWidth / 2),
            x2 - x1 + this.borderLineWidth,
            y2 - y1 + this.borderLineWidth
        )
        this.ctx.closePath()

        this.is_draw = true
    }
    // 绘制文本
    drawText = (x, y, size) => {
        this.ctx.beginPath()
        this.ctx.fillStyle = 'red'
        this.ctx.font = `${12}px serif`
        this.ctx.fillText(
            this.text?.delete || '删除',
            x - 25,
            y + 13
        )
        this.ctx.closePath()
    }
    // 绘制方块
    drawSquare = (data, index) => {
        let {x, y, width, height, color} = data
        this.ctx.save()
        this.ctx.fillStyle = data.color
        this.ctx.fillRect(data.x, data.y, data.width, data.height)
        this.ctx.restore()

        // 当被选中后画边框
        if (this.is_select && this.drag_index === index) {
            // 绘制文本
            this.drawText(x + width, y, width, height)
            this.drawBorderSquare()
        } else {
            this.ctx.save()
            this.ctx.strokeStyle = color.replace('.3', '1')
            this.ctx.lineWidth = this.borderLineWidth
            this.ctx.strokeRect(
                x - Math.floor(this.borderLineWidth / 2),
                y - Math.floor(this.borderLineWidth / 2),
                width + this.borderLineWidth,
                height + this.borderLineWidth
            )
            // 恢复到之前的状态
            this.ctx.restore()
        }
    }
    // 画选中后的框框
    drawBorderSquare = (setting) => {
        let {x, y, width, height} = this.layers[this.drag_index]
        this.ctx.save()
        this.ctx.strokeStyle = '#51B9F9'
        this.ctx.lineWidth = this.borderLineWidth
        this.ctx.strokeRect(
            x - Math.floor(this.borderLineWidth / 2),
            y - Math.floor(this.borderLineWidth / 2),
            width + this.borderLineWidth,
            height + this.borderLineWidth
        )
        // 恢复到之前的状态
        this.ctx.restore()

        // 画边框的5个圆
        this.drawCircle(x, y);
        this.drawCircle(x + width, y);
        this.drawCircle(x, y + height);
        this.drawCircle(x + width, y + height);
        // this.drawCircle(x + width / 2, y - 15);
    }
    // 画边框的5个圆
    drawCircle = (x, y, color) => {
        this.ctx.save()
        this.ctx.shadowColor = 'rgba(0, 0, 0, 0.5)'; // 阴影颜色
        this.ctx.shadowBlur = 5; // 阴影模糊级别
        this.ctx.shadowOffsetX = 0; // 阴影的水平偏移
        this.ctx.shadowOffsetY = 0; // 阴影的垂直偏移
        this.ctx.beginPath();
        this.ctx.fillStyle = 'white';
        if (color) {
           this.ctx.fillStyle = color;
        }
        this.ctx.arc(x, y, 6, 0, 2 * Math.PI);
        this.ctx.fill();
        this.ctx.restore();
    }
    /**
     * 中心点旋转
     * @param {Function} callback 绘制旋转矩形的回调函数
     * @param {Object} setting 旋转设置
     * @param {Number} setting.angle 旋转角度，弧度制
     */
    rotateCenterPoint = (setting, callback) => {
        const { rectX, rectY, width, height, angle = 0, translateX = 0, translateY = 0} = setting;
        this.ctx.save()
        this.ctx.translate(rectX + width / 2, rectY + height / 2)
        this.ctx.rotate(angle)
        this.ctx.translate(-(rectX + width / 2), -(rectY + height / 2))
        if (callback) callback()
            
        this.ctx.restore() // 恢复原始状态
    }
    drawAll() {
        this.ctx.clearRect(-this.canvas.width, -this.canvas.height, this.canvas.width * 2, this.canvas.height * 2)
        if (this.bg) this.drawImage()
        
        this.layers.forEach((item, index) => {
            // 旋转功能
            this.rotateCenterPoint({
                rectX: item.x,
                rectY: item.y,
                width: item.width,
                height: item.height,
                translateX: item.translateX,
                translateY: item.translateY,
                angle: item.angle * Math.PI / 180
            }, () => this.drawSquare(item, index))
        })
    }

    onmousedown = (e) => {
        let pos = this.#getPosition(e)

        if (this.is_select) {
            const mouseX = e.offsetX
            const mouseY = e.offsetY
            if (this.#isMouseInBorder(mouseX, mouseY, 'left')) {
                // 检测是否在左边框内
                this.scale_type = 'left';
                this.changeMouseCursor({ mouseX, mouseY, type: 'left' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'right')) {
                // 检测是否在右边框内
                this.scale_type = 'right';
                this.changeMouseCursor({ mouseX, mouseY, type: 'right' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'top')) {
                // 检测是否在上边框内
                this.scale_type = 'top';
                this.changeMouseCursor({ mouseX, mouseY, type: 'top' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'bottom')) {
                // 检测是否在上边框内
                this.scale_type = 'bottom';
                this.changeMouseCursor({ mouseX, mouseY, type: 'bottom' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'left-top')) {
                 // 检测是否在左上角
                this.scale_type = 'left-top'
                this.changeMouseCursor({ mouseX, mouseY, type: 'left-top' })
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'left-bottom')) {
                // 检测是否在左下角
                this.scale_type = 'left-bottom';
                this.changeMouseCursor({ mouseX, mouseY, type: 'left-bottom' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'right-top')) {
                // 检测是否在右上角
                this.scale_type = 'right-top';
                this.changeMouseCursor({ mouseX, mouseY, type: 'right-top' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'right-bottom')) {
                // 检测是否在右下角
                this.scale_type = 'right-bottom';
                this.changeMouseCursor({ mouseX, mouseY, type: 'right-bottom' });
            } 
            // else if (this.#isMouseInBorder(mouseX, mouseY, 'center-top')) {
            //     this.scale_type = 'center-top';
            //     this.canvas.style.cursor = "all-scroll";
            // }
            
            if (this.scale_type) {
                this.operation = 2
                this.offset = {
                    x: 0,
                    y: 0
                }
                this.drag_position = {
                    x: e.clientX - this.offset.x,
                    y: e.clientY - this.offset.y
                }
                this.position.x1 = this.layers[this.drag_index].x
                this.position.y1 = this.layers[this.drag_index].y
                this.startWidth = this.layers[this.drag_index].width
                this.startHeight = this.layers[this.drag_index].height
                return
            }
        }

        let is_point = this.#isPointInRect(pos.x, pos.y)
        if (is_point) { // 拖拽
            this.flag = true
            this.operation = 1
            this.drag_index = is_point.i
            this.position.x1 = this.layers[this.drag_index].x
            this.position.y1 = this.layers[this.drag_index].y
            this.drag_position = {
                x: e.clientX - this.offset.x,
                y: e.clientY - this.offset.y
            }
            this.canvas.style.cursor = "grabbing"
        } else { // 绘制
            if (this.must && this[this.must]) {
                this.operation = 0
                this.position.x1 = pos.x
                this.position.y1 = pos.y
            } else {
                return false
            }
        }
    }
    onmousemove = (e) => {
        let pos = this.#getPosition(e)
        // 绘制
        if (this.operation === 0) {
            this.position.x2 = pos.x
            this.position.y2 = pos.y
            this.drawRect()
        }

        // 拖拽
        if (this.operation === 1) {
            this.canvas.style.cursor = "grabbing"
            this.offset.x = e.clientX - this.drag_position.x
            this.offset.y = e.clientY - this.drag_position.y

            this.layers[this.drag_index].x = this.position.x1 + this.offset.x
            this.layers[this.drag_index].y = this.position.y1 + this.offset.y

            this.flag = false
            this.drawAll()
        }

        const mouseX = e.offsetX
        const mouseY = e.offsetY
        // 是否选中
        if (this.is_select) {
            if (this.#isMouseInBorder(mouseX, mouseY, 'left') && !this.scale_type) {
                // 检测是否在左边框内
                this.changeMouseCursor({ mouseX, mouseY, type: 'left' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'right') && !this.scale_type) {
                // 检测是否在右边框内
                this.changeMouseCursor({ mouseX, mouseY, type: 'right' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'top') && !this.scale_type) {
                // 检测是否在上边框内
                this.changeMouseCursor({ mouseX, mouseY, type: 'top' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'bottom') && !this.scale_type) {
                // 检测是否在下边框内
                this.changeMouseCursor({ mouseX, mouseY, type: 'bottom' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'left-top') && !this.scale_type) {
                // 检测是否在左上角
                this.changeMouseCursor({ mouseX, mouseY, type: 'left-top' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'left-bottom') && !this.scale_type) {
                // 检测是否在左下角
                this.changeMouseCursor({ mouseX, mouseY, type: 'left-bottom' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'right-top') && !this.scale_type) {
                // 检测是否在右上角
                this.changeMouseCursor({ mouseX, mouseY, type: 'right-top' });
            } else if (this.#isMouseInBorder(mouseX, mouseY, 'right-bottom') && !this.scale_type) {
                // 检测是否在右下角
                this.changeMouseCursor({ mouseX, mouseY, type: 'right-bottom' });
            } 
            // else if (this.#isMouseInBorder(mouseX, mouseY, 'center-top') && !this.scale_type) {
            //     // 检测是否在右下角
            //     this.canvas.style.cursor = "all-scroll";
            // } 
            else if (!this.scale_type ) {
                // 不在边框内时，且不在拉伸和拖拽状态时，回复鼠标指针
                this.canvas.style.cursor = "default";
            }
        }
        
        // 伸缩
        if (this.operation === 2 && this.is_select && this.scale_type) {
            // 边框拉伸功能
            if (this.layers[this.drag_index].angle >= 90 && this.layers[this.drag_index].angle <= 270) {
                this.offset.y = -e.clientY + this.drag_position.y;
                this.offset.x = -e.clientX + this.drag_position.x;
            } else {
                this.offset.y = e.clientY - this.drag_position.y;
                this.offset.x = e.clientX - this.drag_position.x;
            }

            const helpObj = {
                startRectX: this.position.x1,
                startRectY: this.position.y1,
                startWidth: this.startWidth,
                startHeight: this.startHeight,
                scaleX: 1,
                scaleY: 1,
                scaleMode: 'left-top',
                limitName: 'width'
            }

            if (this.scale_type === 'left') {
                // 左边框拉伸
                helpObj.scaleMode = 'right-top';
                helpObj.scaleX = (this.startWidth - this.offset.x) / this.startWidth;
                this.scaleHelpFunc(helpObj);
            } else if (this.scale_type === 'right') {
                // 右边框拉伸
                helpObj.scaleMode = 'left-top';
                helpObj.scaleX = (this.startWidth + this.offset.x) / this.startWidth;
                this.scaleHelpFunc(helpObj);
            } else if (this.scale_type === 'top') {
                // 上边框拉伸
                helpObj.limitName = 'height';
                helpObj.scaleMode = 'left-bottom';
                helpObj.scaleY = (this.startHeight - this.offset.y) / this.startHeight;
                this.scaleHelpFunc(helpObj);
            } else if (this.scale_type === 'bottom') {
                // 下边框拉伸
                helpObj.limitName = 'height';
                helpObj.scaleMode = 'left-top';
                helpObj.scaleY = (this.startHeight + this.offset.y) / this.startHeight;
                this.scaleHelpFunc(helpObj)
            } else if (this.scale_type === 'left-top') {
                const tempScale = (this.startWidth - this.offset.x) / this.startWidth > (this.startHeight - this.offset.y) / this.startHeight ? (this.startWidth - this.offset.x) / this.startWidth : (this.startHeight - this.offset.y) / this.startHeight;
                // 左上角拉伸
                helpObj.scaleMode = 'right-bottom';
                helpObj.scaleX = tempScale;
                helpObj.scaleY = tempScale;
                this.scaleHelpFunc(helpObj);
            } else if (this.scale_type === 'left-bottom') {
                const tempScale = (this.startWidth - this.offset.x) / this.startWidth > (this.startHeight + this.offset.y) / this.startHeight ? (this.startWidth - this.offset.x) / this.startWidth : (this.startHeight + this.offset.y) / this.startHeight;
                // 左下角拉伸
                helpObj.limitName = 'height';
                helpObj.scaleMode = 'right-top';
                helpObj.scaleX = tempScale;
                helpObj.scaleY = tempScale;
                this.scaleHelpFunc(helpObj);
            } else if (this.scale_type === 'right-top') {
                const tempScale = (this.startWidth + this.offset.x) / this.startWidth > (this.startHeight - this.offset.y) / this.startHeight ? (this.startWidth + this.offset.x) / this.startWidth : (this.startHeight - this.offset.y) / this.startHeight;
                // 右上角拉伸
                helpObj.limitName = 'height';
                helpObj.scaleMode = 'left-bottom';
                helpObj.scaleX = tempScale;
                helpObj.scaleY = tempScale;
                this.scaleHelpFunc(helpObj);
            } else if (this.scale_type === 'right-bottom') {
                const tempScale = (this.startWidth + this.offset.x) / this.startWidth > (this.startHeight + this.offset.y) / this.startHeight ? (this.startWidth + this.offset.x) / this.startWidth : (this.startHeight + this.offset.y) / this.startHeight;
                // 右下角拉伸
                helpObj.limitName = 'height';
                helpObj.scaleMode = 'left-top';
                helpObj.scaleX = tempScale;
                helpObj.scaleY = tempScale;
                this.scaleHelpFunc(helpObj);
            } 
            // else if (this.scale_type === 'center-top') {
            //     // 中心点的坐标离鼠标的距离
            //     const tempX = mouseX - (this.layers[this.drag_index].x + this.layers[this.drag_index].width / 2)
            //     const tempY = mouseY - (this.layers[this.drag_index].y + this.layers[this.drag_index].height / 2)
            //     // 旋转的角度
            //     this.layers[this.drag_index].angle = Math.atan2(tempY, tempX) * 180 / Math.PI + 90
            //     if (this.layers[this.drag_index].angle < 0) {
            //         this.layers[this.drag_index].angle += 360
            //     }
            //     // this.layers[this.drag_index].angle = Math.floor(this.layers[this.drag_index].angle)
            //     this.drawAll()
            // }
        }
    }
    onmouseup = (e) => {
        // 删除
        if (this.drag_index !== null && this.is_select) {
            let is_delete = this.onDelete(e)
            if (is_delete) {
                const cur_del = this.layers.splice(this.drag_index, 1)
                this.drag_index = null
                this.is_select = false

                this.event = {
                    type: 'delete',
                    id: cur_del[0].create_id
                }
                document.dispatchEvent(window.myCustomEvent)
                return
            }
        }

        // 绘制
        if (this.operation === 0 && this.is_draw) {
            let {x1, y1, x2, y2} = this.position
            let minX = Math.min(x1, x2)
			let maxX = Math.max(x1, x2)
			let minY = Math.min(y1, y2)
			let maxY = Math.max(y1, y2)
            // 当宽高大于20，进行绘制图形
            if (maxX - minX > 20 && maxY - minY > 20) {
                let obj = {
                    x: minX,
                    y: minY,
                    width: maxX - minX,
                    height: maxY - minY,
                    angle: 0,
                    translateX: 0,
                    translateY: 0,
                    id: this.id,
                    color: this.color,
                    label_name: this.label_name,
                    create_id: ''
                }
                this.undo_list.push(obj)
                this.layers.push(obj)
                this.id++
            }
            this.drawAll()
            this.event = {
                type: 'draw',
                id: ''
            }
            document.dispatchEvent(window.myCustomEvent)
        }

        // 拖拽
        if (this.operation === 1) {
            if (typeof this.drag_index === 'number' && this.flag) {
                if (this.drag_index === this.pre_drag_index) {
                    this.is_select = false
                    this.pre_drag_index = null
                } else {
                    this.is_select = true
                    this.pre_drag_index = this.drag_index
                }
                this.drawAll()
            } else {
                this.undo_list.push({...this.layers[this.drag_index]})
                this.event = {
                    type: 'drag',
                    id: this.layers[this.drag_index].create_id
                }
                document.dispatchEvent(window.myCustomEvent)
            }
        }

        // 伸缩/旋转
        if (this.operation === 2) {
            this.undo_list.push({...this.layers[this.drag_index]})
            this.drawAll()
            this.event = {
                type: 'scale',
                id: this.layers[this.drag_index].create_id
            }
            document.dispatchEvent(window.myCustomEvent)
        }

        // 本次操作结束，还原默认值
        this.resizeDefault()
    }
}

export default Drawer