import React from "react";
import styles from './download-model.css';

const PreviewBox = (props) => {
    let img = new Image()
    img.src=props.result_image

    return (
        <div className={styles.comModelContainer} onClick={() => {props.setPreview(false)}}>
            <img style={{
                width: img.width,
                height: img.height,
                margin: '3.75rem'
            }} src={props.result_image} alt="" />
        </div>
    )
}

export default PreviewBox
