const zhiyaService = require('../utils/zhiya-request.js');

// 获取用户详细信息
export function getInfoZhiya () {
  return zhiyaService({
    url: '/getInfo',
    method: 'get'
  })
}

// 单点cas_token换取智芽社区token
export function getZhiyaTokenByCasToken(data) {
  return zhiyaService({
      url: '/getTokenByCasToken',
      method: 'post',
      data
  })
}

// 创建作品
export function saveWork(data) {
  return zhiyaService({
      url: '/works',
      method: 'post',
      data
  })
}

// 修改作品
export function updateWork(data) {
  return zhiyaService({
      url: '/works',
      method: 'put',
      data
  })
}

// 上传作品
export function uploadWork(data) {
  return zhiyaService({
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      timeout: '',
      url: '/api/files/uploads',
      method: 'post',
      data
  })
}

// 删除文件
export function deleteFiles(data) {
  return zhiyaService({
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      url: '/api/files/delete',
      method: 'delete',
      data
  })
}

// 获取作品
export function getFilePathZhiya(data) {
  return zhiyaService({
      url: data,
      method: 'get',
      responseType: 'blob',
  })
}

// 作品详情
export function getWorkDetail (workIds) {
  return zhiyaService({
    url: '/works/' + workIds,
    method: 'get'
  })
}

// 已发布作品详情
export function publishWorkDetail (workId) {
  return zhiyaService({
    url: `/home/release/${workId}`,
    method: 'get'
  })
}
