import React from 'react';
import PropTypes from 'prop-types';
import LanguageSelector from '../../containers/language-selector.jsx'
import rightIcon from './right.svg';
import styles from './right-menu.css';
class RightMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          showSubMenu: false
        };
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
    }
    toggleSubMenu() {
        this.setState(state => ({
          showSubMenu: !state.showSubMenu
        }));
    }
    
    handleMouseLeave() {
        this.timer = setTimeout(() => {
            this.setState({
            showSubMenu: false
            });
        }, 300);
    }
    
    handleMouseEnter() {
        clearTimeout(this.timer);
    }
    render() {
        return (
            <div>
                <div
                className={styles.item}
                onMouseEnter={() => this.toggleSubMenu()}
                onMouseLeave={() => this.handleMouseLeave()}
                >
                    <div
                        className={styles.title}>{this.props.label}
                    </div>
                    <img
                        src={rightIcon}
                    />
                </div>
                {this.state.showSubMenu && (
                    <div onMouseEnter={() => this.handleMouseEnter()}>
                        <LanguageSelector onMounthLanguage={this.props.onMounthLanguage}></LanguageSelector>
                    </div>
                )}
            </div>
        );
    }
}
RightMenu.propTypes = {
    showSubMenu: PropTypes.bool,
    onMounthLanguage: PropTypes.func,
}

export default RightMenu;