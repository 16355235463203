const OPEN_MENU = 'scratch-gui/menus/OPEN_MENU';
const CLOSE_MENU = 'scratch-gui/menus/CLOSE_MENU';

const MENU_ABOUT = 'aboutMenu';
const MENU_ACCOUNT = 'accountMenu';
const MENU_FILE = 'fileMenu';
const MENU_EDIT = 'editMenu';
const MENU_PACK = 'packMenu';
const MENU_HELP = 'helpMenu';
const MENU_SETUP = 'setupMenu';
const MENU_RESOURCE = 'resourceMenu';
const MENU_LANGUAGE = 'languageMenu';
const MENU_LOGIN = 'loginMenu';
const IMAGE_TRAIN_MENU = 'imageTrainMenu';
const DIM_UPLOAD_MENU = 'uploadMenu'

const initialState = {
    [MENU_ABOUT]: false,
    [MENU_ACCOUNT]: false,
    [MENU_FILE]: false,
    [MENU_EDIT]: false,
    [MENU_PACK]: false,
    [MENU_HELP]: false,
    [MENU_SETUP]: false,
    [MENU_RESOURCE]: false,
    [MENU_LANGUAGE]: false,
    [MENU_LOGIN]: false,
    [IMAGE_TRAIN_MENU]: false,
    [DIM_UPLOAD_MENU]: false
};

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case OPEN_MENU:
        return Object.assign({}, state, {
            [action.menu]: true
        });
    case CLOSE_MENU:
        return Object.assign({}, state, {
            [action.menu]: false
        });
    default:
        return state;
    }
};
const openMenu = menu => ({
    type: OPEN_MENU,
    menu: menu
});
const closeMenu = menu => ({
    type: CLOSE_MENU,
    menu: menu
});
const openAboutMenu = () => openMenu(MENU_ABOUT);
const closeAboutMenu = () => closeMenu(MENU_ABOUT);
const aboutMenuOpen = state => state.scratchGui.menus[MENU_ABOUT];
const openAccountMenu = () => openMenu(MENU_ACCOUNT);
const closeAccountMenu = () => closeMenu(MENU_ACCOUNT);
const accountMenuOpen = state => state.scratchGui.menus[MENU_ACCOUNT];
const openFileMenu = () => openMenu(MENU_FILE);
const closeFileMenu = () => closeMenu(MENU_FILE);
const fileMenuOpen = state => state.scratchGui.menus[MENU_FILE];
const openEditMenu = () => openMenu(MENU_EDIT);
const closeEditMenu = () => closeMenu(MENU_EDIT);
const editMenuOpen = state => state.scratchGui.menus[MENU_EDIT];
const openPackMenu = () => openMenu(MENU_PACK);
const closePackMenu = () => closeMenu(MENU_PACK);
const filePackOpen = state => state.scratchGui.menus[MENU_PACK];
const openHelpMenu = () => openMenu(MENU_HELP);
const closeHelpMenu = () => closeMenu(MENU_HELP);
const helpMenuOpen = state => state.scratchGui.menus[MENU_HELP];
const openSetupMenu = () => openMenu(MENU_SETUP);
const closeSetupMenu = () => closeMenu(MENU_SETUP);
const setupMenuOpen = state => state.scratchGui.menus[MENU_SETUP];
const openLanguageMenu = () => openMenu(MENU_LANGUAGE);
const closeLanguageMenu = () => closeMenu(MENU_LANGUAGE);
const languageMenuOpen = state => state.scratchGui.menus[MENU_LANGUAGE];
const openLoginMenu = () => openMenu(MENU_LOGIN);
const closeLoginMenu = () => closeMenu(MENU_LOGIN);
const loginMenuOpen = state => state.scratchGui.menus[MENU_LOGIN];
const imageTrainMenuOpen = state => state.scratchGui.menus[IMAGE_TRAIN_MENU];
const openImageTrainMenu = () => openMenu(IMAGE_TRAIN_MENU);
const closeImageTrainmenu = () => closeMenu(IMAGE_TRAIN_MENU)
const dimUploadMenuOpen = state => state.scratchGui.menus[DIM_UPLOAD_MENU]
const openDimUploadMenu = () => openMenu(DIM_UPLOAD_MENU)
const closeDimUploadMenu = () => closeMenu(DIM_UPLOAD_MENU)

/* 资源空间 */
const resourceMenuOpen = state => state.scratchGui.menus[MENU_RESOURCE];
const openResourceMenu = () => openMenu(MENU_RESOURCE);
const closeResourceMenu = () => closeMenu(MENU_RESOURCE);

export {
    reducer as default,
    initialState as menuInitialState,
    openAboutMenu,
    closeAboutMenu,
    aboutMenuOpen,
    openAccountMenu,
    closeAccountMenu,
    accountMenuOpen,
    openFileMenu,
    closeFileMenu,
    fileMenuOpen,
    openEditMenu,
    closeEditMenu,
    editMenuOpen,
    openPackMenu,
    closePackMenu,
    filePackOpen,
    openHelpMenu,
    closeHelpMenu,
    helpMenuOpen,
    openSetupMenu,
    closeSetupMenu,
    setupMenuOpen,
    openLanguageMenu,
    closeLanguageMenu,
    languageMenuOpen,
    openLoginMenu,
    closeLoginMenu,
    loginMenuOpen,
    imageTrainMenuOpen,
    openImageTrainMenu,
    closeImageTrainmenu,
    dimUploadMenuOpen,
    openDimUploadMenu,
    closeDimUploadMenu,

    openResourceMenu,
    closeResourceMenu,
    resourceMenuOpen,
};
