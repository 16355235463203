/* 人员管理接口 */
const manageService = require('../utils/manage-request.js');

// 用户信息
export function getManageUser() {
    return manageService({
        url: '/getInfo',
        method: 'get'
    })
}

// 创建作业
export function createWrok(data) {
    return manageService({
        url: '/tanqi/tasks',
        method: 'post',
        data
    })
}

// 修改作业
export function editWrok(data) {
    return manageService({
        url: '/tanqi/tasks',
        method: 'put',
        data
    })
}

// 获取作品
export function getFilePath(data) {
    return manageService({
        url: data,
        method: 'get',
        responseType: 'blob',
    })
}

// 上传作品
export function uploadWork(data) {
    return manageService({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: '',
        url: '/tanqi/files/uploads',
        method: 'post',
        // responseType: 'blob',
        data
    })
}

// 课程作业列表
export function getCourseWork(data) {
    return manageService({
      url: '/tanqi/tasks/list',
      method: 'get',
      params: data
    })
}

// 老师批改作业
export function homeworkCorrecting(data) {
    return manageService({
        url: '/tanqi/tasksDistribute/teacher/correct',
        method: 'post',
        data
    })
}

// 学生提交作业
export function submitJob(data) {
    return manageService({
        url: '/tanqi/tasksDistribute/student/submit',
        method: 'post',
        data
    })
}

// 学生保存作业
export function saveJob(data) {
    return manageService({
        url: '/tanqi/tasksDistribute/student/save',
        method: 'post',
        data
    })
}

// 学生重置作业
export function resizeJob(data) {
    return manageService({
        url: '/tanqi/tasksDistribute/student/reset',
        method: 'post',
        data
    })
}

// 老师下发作业详情
export function asignmentDetails(data) {
    return manageService({
        url: '/tanqi/tasks/' + data,
        method: 'get',
    })
}

// 学生作业详情
export function studentAsignmentDetails(data) {
    return manageService({
        url: '/tanqi/tasksDistribute/' + data,
        method: 'get',
    })
}

// 单点cas_token换取人员管理token
export function getManageToken(data) {
    return manageService({
        url: '/getTokenByCasToken',
        method: 'post',
        data
    })
}

// 删除文件
export function deleteFiles(data) {
    return manageService({
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        url: '/tanqi/files/delete',
        method: 'delete',
        data
    })
}

// 作品详情
export function getWorkDetails(data) {
    return manageService({
        url: '/tanqi/works/' + data,
        method: 'get',
    })
}

// 修改作品
export function updateWork(data) {
    return manageService({
        url: '/tanqi/works/updateWork',
        method: 'put',
        data
    })
}

// 创建作品
export function saveWork(data) {
    return manageService({
        url: '/tanqi/works/saveWork',
        method: 'post',
        data
    })
}

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
    return manageService({
      url: '/system/config/configKey/' + configKey,
      method: 'get'
    })
}

// 资源详情
export function getDictFile(data) {
    return manageService({
        url: '/tanqi/dict/files/' + data,
        method: 'get',
    })
}

// 修改资源
export function updateDictFile(data) {
    return manageService({
        url: '/tanqi/dict/files',
        method: 'put',
        data
    })
}

// tpu插件下载链接
export function getTpuUrl(configKey) {
    return manageService({
        url: '/system/config/configKey/' + configKey,
        method: 'get'
    })
}
