import React from 'react';
import classNames from 'classnames';
import styles from './train-model.css';
import close from '../../../../static/assets/icon_close.png';
import more from '../img/train-model-more.png'
import change from '../img/train-model-change.png'
import upBg from '../img/up-bg.png'
import cBg from '../img/c.png'
// import bg from '../img/train-model-bg.png'
// import bg from '../img/train-model-bg.gif'
import { Menu, Dropdown, Select, Progress } from 'antd';

const CompileModel = (props) => {
    const {
        type,
        width,
        height,
        model_list,
        source,
        specs,
        tpu_list,
        compile_status,
        compile_status_text,
        compile_similarity,
        tpu_test_list,
        tpu_test_val,
        conf_thres,
        iou_thres,
        tpu_state,
        upload_image,
        result_list,
        result_image,
        train_status,
        result_state,

        menuClick,
        closeDialog,
        changeModeType,
        changeModel,
        setWidth,
        setHeight,
        changeSource,
        changeSpecs,
        createCompileTask,
        setTpuType,
        setDownModelShow,
        setConfThres,
        setIouThres,

        setUploadType,
        setPreview,
        changeTpuTest,
        changeTakePhoto,
        onDropdownVisibleChange
    } = props

    const menu = (
        <Menu onClick={menuClick}>
          <Menu.Item key="ordinary">
            <div>{props.intl.formatMessage(props.LANG_MESSAGE.normalMode)}</div>
          </Menu.Item>
          <Menu.Item key="senior">
            <div>{props.intl.formatMessage(props.LANG_MESSAGE.advancedMode)}</div>
          </Menu.Item>
        </Menu>
    );

    const { Option } = Select;

    return (
        <div className={styles.box}>
            <div className={styles.header}>
                <div>{props.intl.formatMessage(props.LANG_MESSAGE.TPUTesting)}</div>
                <div className={styles.rightHeader}>
                    <div className={styles.menuContent}>
                        <img src={change} onClick={() => changeModeType('compile')} />
                        <Dropdown overlay={menu}>
                            <div className={styles.menuTitle}>
                                <span>{type === 'ordinary' ? props.intl.formatMessage(props.LANG_MESSAGE.normalMode) : props.intl.formatMessage(props.LANG_MESSAGE.advancedMode)}</span>
                                <img className={styles.more} src={more} />
                            </div>
                        </Dropdown>
                    </div>

                    <img className={styles.closeIcon} src={close} onClick={closeDialog} />
                </div>
            </div>
            <div className={styles.content}>
                <ul className={styles.config}>
                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.MODEL_NAME)}</span>
                        <Select 
                            value={props.model_list_val}
                            style={{ width: 120 }} 
                            onChange={changeModel}
                            onDropdownVisibleChange={props.onDropdownModelChange}
                        >
                            {model_list.map((item, index) => {
                                return (
                                    <Option key={index} value={item.modelVersionName}>{item.modelVersionName}</Option>
                                )
                            })}
                        </Select>
                        <input className={styles.dataSetBtn} type="button" value={props.intl.formatMessage(props.LANG_MESSAGE.compilationModel)} onClick={() => props.selectModel('compile')} />
                    </li>

                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.DATA_SET)}</span>
                        <input className={styles.dataSetVal} type="text" value={props.data?.data_set?.name || ''} disabled />
                        <input className={styles.dataSetBtn} type="button" value={props.intl.formatMessage(props.LANG_MESSAGE.reSelect)} onClick={() => props.selectDataSet('compile')} />
                    </li>

                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.TPUType)}</span>
                        <Select 
                            value={props.tpu_type_val}
                            style={{ width: 120 }} 
                            onChange={setTpuType}
                        >
                            {tpu_list.map((item, index) => {
                                return (
                                    <Option key={index} value={item.value}>{item.value}</Option>
                                )
                            })}
                        </Select>
                    </li>

                    {type === 'senior' ? (
                        <>
                            <li className={classNames(styles.imageSize1, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.imageSize)}</span>
                                <input type="number" value={width} onChange={setWidth} />
                                <input type="number" value={height} onChange={setHeight}/>
                            </li>

                            <li className={classNames(styles.trainResource, styles.comItem)}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.compilationComputingPower)}</span>
                                {(source.length > 0 && specs.length > 0) ? (
                                    <>
                                        <Select 
                                            value={props.compile_source_val}
                                            style={{ width: 120, marginRight: '10px' }} 
                                            onChange={changeSource}
                                        >
                                            {source.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item}>{item}</Option>
                                                )
                                            })}
                                        </Select>

                                        <Select 
                                            value={props.compile_specs_val}
                                            style={{ width: 120 }} 
                                            onChange={changeSpecs}
                                        >
                                            {specs.map((item, index) => {
                                                return (
                                                    <Option key={index} value={item.id}>{item.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </>
                                ) : (<span className={styles.sourceTip}>{props.intl.formatMessage(props.LANG_MESSAGE.invalidConfiguration)}</span>)}
                            </li>
                        </>
                    ) : null}
                </ul>
                <div className={styles.operate}>
                    <div className={styles.operateLeft}>
                        <div className={classNames(styles.comBox, styles.uploadBox)}>
                            <span>{props.intl.formatMessage(props.LANG_MESSAGE.dataInput)}</span>
                            <img className={styles.uploadImg} src={upload_image || upBg} alt="" />
                            <input 
                                className={classNames(
                                    styles.uploadBtn,
                                    // 0 检测中 1 待重启 2 未连接 3 启动中 4 已停止 6已启动 7 请重启 default 检测中
                                    (tpu_test_val && (tpu_state === 6)) ? '' : styles.disBtn
                                )}
                                type="button" 
                                value={props.intl.formatMessage(props.LANG_MESSAGE.uploadTest)}
                                onClick={() => {
                                    if (tpu_test_val && (tpu_state === 6)) {
                                        setUploadType('compile')
                                        changeTakePhoto(true)
                                    }
                                }}
                            />

                            <div className={styles.reasonConf}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.confidenceLevel)}</span>
                                <input 
                                    type="number" 
                                    value={conf_thres}
                                    onChange={setConfThres}
                                />
                            </div>

                            <div className={styles.reasonConf}>
                                <span>{props.intl.formatMessage(props.LANG_MESSAGE.threshold)}</span>
                                <input 
                                    type="number"
                                    value={iou_thres}
                                    onChange={setIouThres}
                                />
                            </div>
                        </div>
                        <div className={classNames(styles.comBox)}>
                            <span>{props.intl.formatMessage(props.LANG_MESSAGE.neuralNetworkLearning)}</span>
                            <img className={styles.learnImg} src={cBg} alt="" />
                        </div>
                        <div className={classNames(styles.comBox, styles.resultBox)}>
                            <span>{props.intl.formatMessage(props.LANG_MESSAGE.trainingResults)}</span>
                            <img 
                                className={styles.resultImg} 
                                src={result_image || upBg} 
                                onDoubleClick={() => setPreview(true)}
                            />
                            <ul className={styles.resultUl}>
                                <li>{result_state ? props.intl.formatMessage(props.LANG_MESSAGE.Identifying) + '…' : props.intl.formatMessage(props.LANG_MESSAGE.identificationResults)}</li>
                                {result_list.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            {item.data.map((tag_itm, tag_index) => {
                                                return (
                                                    <div key={tag_index}>{tag_itm.lable}: <span>{tag_itm.probability}</span></div>
                                                )
                                            })}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className={classNames(styles.operateRight, styles.compileRight)}>
                        <div className={styles.operateTitle}>{props.intl.formatMessage(props.LANG_MESSAGE.modelCompilationSimilarity)}</div>
                        {/* <Progress type="circle" status="active" percent={75} /> */}
                        <Progress percent={compile_similarity} strokeColor="#FADF75" strokeWidth={20} />
                    </div>
                </div>
                
                <div className={styles.compileStatus}>
                    {props.intl.formatMessage(props.LANG_MESSAGE.taskstatus)}：<span className={classNames(
                        compile_status === 'succeeded' ? styles.compileSuc : 
                        ['preparing', 'pending', 'running'].includes(compile_status) ? styles.compileRun :
                        ['failed', 'stopped'].includes(compile_status) ? styles.compileFail : ''
                    )}>{compile_status_text ? (compile_status_text + '：' + props.compile_time) : ''}</span>
                </div>
            </div>
            <div className={styles.compileFooter}>
                <ul className={styles.footerLeft}>
                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.testModel)}</span>
                        <Select 
                            value={props.tpu_test_val}
                            style={{ width: 120 }} 
                            onChange={changeTpuTest}
                            onDropdownVisibleChange={onDropdownVisibleChange}
                        >
                            {tpu_test_list.map((item, index) => {
                                return (
                                    <Option key={index} value={item.model_name}>{item.model_name}</Option>
                                )
                            })}
                        </Select>
                    </li>
                </ul>

                <ul className={styles.footerRight}>
                    <li className={styles.comItem}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.TPUStatus)}</span>
                        <div className={
                            classNames(
                                styles.tpuState,
                                tpu_state === 6 ? styles.tpuStateOn : styles.tpuStateOff
                            )}
                        >
                            <div></div>
                            <span>
                                {[
                                    props.intl.formatMessage(props.LANG_MESSAGE.check), 
                                    props.intl.formatMessage(props.LANG_MESSAGE.restart), 
                                    props.intl.formatMessage(props.LANG_MESSAGE.disconnect), 
                                    props.intl.formatMessage(props.LANG_MESSAGE.starting), 
                                    props.intl.formatMessage(props.LANG_MESSAGE.stop), 
                                    '', 
                                    props.intl.formatMessage(props.LANG_MESSAGE.run), 
                                    props.intl.formatMessage(props.LANG_MESSAGE.rerun), 
                                    props.intl.formatMessage(props.LANG_MESSAGE.check)
                                ][tpu_state]}
                            </span>
                        </div>
                    </li>
                    <li className={styles.comItem}>
                        <input 
                            className={classNames(
                                styles.comBtn,
                                styles.startCompile,
                                train_status !== 'succeeded' ? styles.disBtn : ''
                            )}
                            type="button"
                            value={props.intl.formatMessage(props.LANG_MESSAGE.startCompiling)}
                            onClick={() => {
                                if (train_status === 'succeeded') createCompileTask(true)
                            }}
                        />
                    </li>
                    <li className={styles.comItem}>
                        <input 
                            className={classNames(
                                styles.comBtn,
                                styles.download,
                                compile_status !== 'succeeded' ? styles.disBtn : ''
                            )}
                            type="button"
                            value={props.intl.formatMessage(props.LANG_MESSAGE.downloadTPU)}
                            onClick={() => {
                                if (compile_status === 'succeeded') setDownModelShow(true)
                            }}
                        />
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default CompileModel
