/**
 * 动态处理扩展在积木列表位置  
 */
export const resizeExtensiond = function() {
    let extensiond; //扩展
    let scratchCategoryMenu; // 积木列表
    let flexWrapper; // 内容容器
    let category_height; // 积木列表总高度
    let flex_warpper_height; // 内容容器高度
    let default_height = 60; // 扩展默认高度
    if (
        document.getElementById('extensiond-id') && 
        document.getElementsByClassName('scratchCategoryMenu')[0] &&
        document.getElementById('flexWrapper')
    ) {
        setTimeout(() => {
            extensiond = document.getElementById('extensiond-id')
            scratchCategoryMenu = document.getElementsByClassName('scratchCategoryMenu')[0]
            flexWrapper = document.getElementById('flexWrapper')

            category_height = +scratchCategoryMenu.children.length * 60
            flex_warpper_height = +(getComputedStyle(flexWrapper).height).replace('px', '')
            // console.log(category_height + default_height > flex_warpper_height)
            if (category_height + default_height > flex_warpper_height) {
                Object.assign(extensiond.style, {
                    top: 'auto',
                    bottom: 0,
                })
            } else {
                Object.assign(extensiond.style, {
                    top: category_height + 'px'
                })
            }
        }, 100)
    }
}

/* 获取地址栏参数 */
export const getUrlParams = function(params) {
    let GET = {};
    let querystr = params.split("?");
    let queryText = ''
    if (querystr.length > 2) {
      for (let i = 0; i < querystr.length; i++) {
        if (i > 1) {
          queryText = querystr[1] += `?${querystr[i]}`
        }
      }
    } else {
      queryText = querystr[1]
    }
  
    if (queryText) {
      let GETs = queryText.split("&");
      for (let i = 0; i < GETs.length; i++) {
        let tmp_arr = GETs[i].split("=");
        let key = tmp_arr[0]
        if (tmp_arr.length > 2) {
          for (let i = 0; i < tmp_arr.length; i++) {
            if (i > 1) {
              GET[key] = tmp_arr[1] += `=${tmp_arr[i]}`;
            }
          }
        } else {
          GET[key] = tmp_arr[1]
        }
      }
    }
    return GET;
}

/* 时间转换 */
export const parseTime = function(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/* 颜色转换 */
export const colorToHex = function(color) {
  let temp = document.createElement('div');
  temp.style.color = color;
  document.body.appendChild(temp);
  let style = getComputedStyle(temp);
  let rgb = style.color;
  document.body.removeChild(temp);
  let hex = rgbToHex(rgb);
  return hex;
}

export const rgbToHex = function(rgb) {
  let rgbValues = rgb.match(/\d+/g);
  let hex = "#";
  for (let i = 0; i < rgbValues.length; i++) {
    let hexValue = parseInt(rgbValues[i]).toString(16).padStart(2, '0');
    hex += hexValue;
  }
  return hex;
}

// 16进制转rgba
export const hexToRgba = function(hex, opacity) {  
  var r = parseInt(hex.slice(1, 3), 16);  
  var g = parseInt(hex.slice(3, 5), 16);  
  var b = parseInt(hex.slice(5, 7), 16);  
  var a = parseInt(hex.slice(7, 9), 16) / 255;  
  return "rgba(" + r + ", " + g + ", " + b + ", " + opacity + ")";  
} 

// 随机生成16进制颜色码
export const generateRandomColor = function() {  
  var letters = '0123456789ABCDEF';  
  var color = '#';  
  for (var i = 0; i < 6; i++) {  
      color += letters[Math.floor(Math.random() * 16)];  
  }  
  return color;  
}

// base64转blob
export const dataUrlToBlob = function(dataUrl) {  
  const parts = dataUrl.split(',');  
  const contentType = parts[0].split(':')[1];  
  const byteCharacters = atob(parts[1]);  
  const byteArrays = [];  
  for (let i = 0; i < byteCharacters.length; i++) {  
    byteArrays.push(byteCharacters.charCodeAt(i));  
  }  
  return new Blob([new Uint8Array(byteArrays)], { type: contentType });  
}

// 运行时间格式化
export const formatDuring = function(mss) {
  mss = mss * 1000
  var days = parseInt(mss / (1000 * 60 * 60 * 24))
  days = days === 0 ? '' : days + 'd'
  var hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  hours = hours === 0 ? '' : hours + 'h'
  var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60))
  minutes = minutes === 0 ? '' : minutes + 'm'
  var seconds = Math.round((mss % (1000 * 60)) / 1000) + 's'
  seconds = seconds === 0 ? '' : seconds
  return days + hours + minutes + seconds
}

/* 随机名称 */
export const randomStr = function(len = 30) {
  let t = "QWERTYUIOPASDFGHJKLZXCVBNMqwertyuiopasdfghjklzxcvbnm1234567890";
  let val = "";
  for (let i = 0; i < len; i++) {
    val += t.charAt(Math.floor(Math.random() * t.length));
  }
  return val;
}

/* 随机颜色 */
export const getRandomColor = function() {  
  var letters = '0123456789ABCDEF';  
  var color = '#';  
  for (var i = 0; i < 6; i++) {  
      color += letters[Math.floor(Math.random() * 16)];  
  }  
  return color;  
}