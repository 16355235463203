import React from 'react';
import styles from './rerun.css';
import {FormattedMessage, defineMessages, injectIntl, intlShape} from 'react-intl';
import xhr from 'xhr';
import bindAll from 'lodash.bindall';
import unlinkIcon from './unlink.png';
import runIcon from './run.png';
import rerunIcon from './rerun.svg';

const messages = defineMessages({
    isStop: {
        id: 'gui.tpu.isStop',
        defaultMessage: 'Whether to stop the plug-in',
        description: 'Whether to stop the plug-in'
    },
    isResart: {
        id: 'gui.tpu.isResart',
        defaultMessage: 'Are you sure to restart',
        description: 'Are you sure to restart'
    }
});
// 旧，暂无用到，注释
// import classNames from 'classnames';
// import PropTypes from 'prop-types';
// import locales, {localeData, isRtl} from 'scratch-l10n';
// import editorMessages from 'scratch-l10n/locales/editor-messages';
// import api from '../../../skconfig.js';
// import sokeTpuwhite from '../../../skimg/tpu.png';

class ReRunComponent extends React.Component {
    constructor(props) {
        super(props);
        // this.status=0;
        this.state = {
            versionDiv: '',
            statusNum: 8, // 0 检测中 1 待重启 2 未连接 3 启动中 4 已停止 5 6已启动 7 请重启 default 检测中
            isresart: false, // true 页面不可修改 false 页面可修改
            isstatis: 0, // 初始状态
            timer: null, // 定时器
            isCheckApp: true // 是否开启检测
        }
        bindAll(this, [
            'changeStatus',
            'resTpu',
            'stopTpu',
            'chenckTpu',
            'stopAndRestartTpu'
        ]);
    }
    componentWillMount() {
        this.chenckTpu()
        this.changeStatus();
            if (this.state.isCheckApp) {
                this.state.timer = setInterval(() => {
                this.chenckTpu()
            }, 60000)
        }
    }
    stopAndRestartTpu () {
        var isstop = confirm(this.props.intl.formatMessage(messages.isResart));
        if (isstop == true) {
            this.setState ({
                statusNum: 3,
                isresart: false
            }, () => {
                this.changeStatus();
                // this.changeStatus();
                if (document.getElementById('startTelMedicine')) {
                    document.getElementById('startTelMedicine').remove();
                }
                var href = 'tpu-dev://';
                if (BASE_URL == 'http://localhost:5500') {
                    href = 'tpu://';
                } else {
                    href = 'tpu-dev://';
                }
                var a = document.createElement("a");
                a.setAttribute('href', href);
                a.setAttribute('id', 'startTelMedicine');
                // 防止反复添加
                document.body.appendChild(a);
                a.click();
                // var url = `/stop`;
                // xhr({
                //     method: 'post',
                //     url: api.baseURL + url
                // }, (error, response) => {
                //     if (error || response.statusCode !== 200) {
                //         this.setState ({
                //             statusNum: 7,
                //             isresart: false
                //         }, () => {
                //             this.changeStatus();
                //         })
                //     } else {
                        
                //     }
                // })
                // this.setState ({
                //     statusNum: 3,
                //     isresart: false
                // }, () => {
                    
                // })
            })
            
        }
    }
    changeStatus () {
        if (this.state.isresart) {
            return;
        }
        this.props.onSetTpuState(this.state.statusNum)
        switch(this.state.statusNum) {
            case 0:
                this.setState({
                    isresart: false,
                    versionDiv: (
                        <div onClick={this.stopAndRestartTpu} className={styles.item}>
                            <img src={unlinkIcon} className={styles.img} />
                            <FormattedMessage
                                defaultMessage="检测中"
                                description="Tpu service detection"
                                id="gui.tpu.check"
                            />
                        </div>
                    )
                });
                break;
            case 1: 
                this.setState({
                    isresart: true,
                    versionDiv: (    
                        <div onClick={this.stopAndRestartTpu} className={styles.item}>
                            <img src={rerunIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="待重启"
                                description="Tpu service is restart"
                                id="gui.tpu.restart"
                            />
                        </div>
                    )
                });
                break;
            case 2: 
                this.setState({
                    isresart: true,
                    versionDiv: (
                        <div onClick={this.stopAndRestartTpu} className={styles.item}>
                            <img src={unlinkIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="未连接"
                                description="Tpu service is disconnect"
                                id="gui.tpu.disconnect"
                            />
                        </div>
                    )
                });
                break;
            case 3: 
                this.setState({
                    isresart: true,
                    versionDiv: (
                        <div onClick={this.stopAndRestartTpu} className={styles.item}>
                            <img src={rerunIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="启动中"
                                description="Tpu service detection"
                                id="gui.rerun.starting"
                            />
                        </div>
                    )
                });
                break;
            case 4: 
                this.setState({
                    isresart: true,
                    versionDiv: (
                        <div onClick={this.stopAndRestartTpu} className={styles.item}>
                            <img src={rerunIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="已停止"
                                description="Tpu service stopped."
                                id="gui.tpu.stop"
                            />
                        </div>
                    )
                });
                break;
            case 6: 
                this.setState({
                    isresart: true,
                    versionDiv: (
                        <div onClick={this.stopTpu} className={styles.item}>
                            <img src={runIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="已启动"
                                description="Tpu service is running."
                                id="gui.tpu.run"
                            />
                        </div>
                    )
                });
                break;
            case 7: 
                this.setState({
                    isresart: true,
                    versionDiv: (
                        <div onClick= {this.stopAndRestartTpu} className={styles.item}>
                            <img src={rerunIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="请重启"
                                description="Please restart the tpu service"
                                id="gui.tpu.rerun"
                            />
                        </div>
                    )
                });
                break;

            default:
                this.setState({
                    isresart: true,
                    versionDiv:(
                        <div onClick={this.stopAndRestartTpu} className={styles.item}>
                            <img src={unlinkIcon} className={styles.img}/>
                            <FormattedMessage
                                defaultMessage="检测中"
                                description="Tpu service detection"
                                id="gui.tpu.check"
                            />
                        </div>
                    )
                });
        }
    }
    chenckTpu() {
        xhr({
            method: 'get',
            url: BASE_URL + `/checkApp`
        }, (error, response) => {
            if (error || response.statusCode !== 200) {           
                if (this.state.isstatis == 0) {
                    this.setState ({
                        isstatis: 1,
                        statusNum: 7,
                        isresart: false,
                    }, () => {
                        this.changeStatus();
                    })
                }
                return;
            }
            var data = JSON.parse(response.body);
            if (data.code === 200) {
                data = data.data;
                if (data.tpu === "false") {
                    this.setState ({
                        statusNum: 2,
                        isresart: false
                    }, () => {
                        this.changeStatus();
                    })
                    return;
                }
                if (data.tpu === "true") {
                    this.setState ({
                        statusNum: 1,
                        isresart: false
                    }, () => {
                        this.changeStatus()
                    })
                }
                if (data.app === "false") {
                    this.setState ({
                        statusNum: 7,
                        isresart: false
                    }, () => {
                        this.changeStatus()
                        return;
                    })
                }
                if (data.app === "true") {
                    this.setState ({
                        statusNum: 6,
                        isresart: false
                    }, () => {
                        this.changeStatus()
                    })
                }
            }
        });

        
    }
    stopTpu() {
        var isstop = confirm(this.props.intl.formatMessage(messages.isStop));
        if( isstop == true ){
            clearInterval(this.state.timer);
            xhr({
                method: 'post',
                url: BASE_URL + `/stop`
            }, (error, response) => {
                this.setState ({
                    statusNum: 7,
                    isresart: false
                }, () => {
                    this.changeStatus();
                    if (this.state.timer) {
                        setTimeout(() => {
                            this.state.timer = setInterval(() => {
                                this.chenckTpu()
                            }, 2000)
                        }, 10000);
                    } 

                })
                // if (error || response.statusCode !== 200) {
                //     this.setState ({
                //         statusNum: 7,
                //         isresart: false
                //     }, () => {
                //         this.changeStatus();
                //     })
                // } else {
                //     this.setState ({
                //         statusNum: 4,
                //         isresart: false
                //     }, () =>{
                //         this.changeStatus()

                //     } )
                // }
            })
            
        }  
    }
    resTpu() {
        this.setState({
            statusNum: 3,
            isresart: false
        }, () => {
            this.changeStatus(); // 启动中
            if (document.getElementById('startTelMedicine')) {
                document.getElementById('startTelMedicine').remove();
            }
            var href = 'tpu-dev://';
            if (BASE_URL == 'http://localhost:5500') {
                href = 'tpu://';
            } else {
                href = 'tpu-dev://';
            }
            var a = document.createElement("a");
            a.setAttribute('href', href);
            a.setAttribute('id', 'startTelMedicine');
            // 防止反复添加
            document.body.appendChild(a);
            a.click();
        });
        
    }
    render(){
        return(
            <div className={styles.rerun}>
                <button className={styles.button}>
                    {this.state.versionDiv}
                </button>
            </div>
        );
    }
}

export default injectIntl(ReRunComponent);
