exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".message_message-container_31Fwd {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    -webkit-box-pack: center;\n    -webkit-justify-content: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    position: absolute;\n    left: 50%;\n    top: 56px;\n    -webkit-transform: translateX(-50%);\n        -ms-transform: translateX(-50%);\n            transform: translateX(-50%);\n    width: auto;\n    max-width: calc(100% - 100px);\n    min-height: 48px;\n    border-radius: 4px;\n    padding: 0 18px;\n    font-size: 14px;\n    font-family: Source Han Sans CN-Regular;\n    color: #333333;\n    background: #FFFFFF;\n    -webkit-box-shadow: 0px 3px 8px 1px rgba(117, 117, 117, 0.35);\n            box-shadow: 0px 3px 8px 1px rgba(117, 117, 117, 0.35);\n    z-index: 2000;\n    overflow: hidden;\n}\n.message_messageImg_JJNbp {\n    width: 22px;\n    height: 22px;\n    padding: 0 0 1px 0;\n}\n.message_messageContent_2enWK {\n    overflow: hidden;\n}", ""]);

// exports
exports.locals = {
	"message-container": "message_message-container_31Fwd",
	"messageContainer": "message_message-container_31Fwd",
	"messageImg": "message_messageImg_JJNbp",
	"messageContent": "message_messageContent_2enWK"
};