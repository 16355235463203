exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".data_set_list-item_2lsdx {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    width: 100%;\n    height: 3rem;\n    margin: 0 0 1rem 0;\n    border-radius: .375rem;\n}\n.data_set_list-item_2lsdx>.data_set_empty_3yzDQ {\n    width: 1.25rem;\n    height: 1.25rem;\n    margin: 0 .625rem 0 1.5rem;\n}\n.data_set_list-item_2lsdx>.data_set_image_2O3KN {\n    width: 1.25rem;\n    height: 1.25rem;\n    margin: 0 .625rem 0 1.5rem;\n}\n.data_set_list-item_2lsdx>.data_set_name_3fnh_ {\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n    font-size: .875rem;\n    color: #333333;\n}\n.data_set_list-item_2lsdx>.data_set_desc_3IAHR {\n    -webkit-box-flex: 1;\n    -webkit-flex: 1;\n        -ms-flex: 1;\n            flex: 1;\n    font-size: .875rem;\n    color: #333333;\n}\n.data_set_list-item_2lsdx>.data_set_date_2d9A7 {\n    width: 7.5rem;\n    margin: 0 1.5rem 0 0;\n    font-size: .75rem;\n    color: #999999;\n    overflow: hidden;\n}\n.data_set_list-item_2lsdx:nth-child(1)>.data_set_name_3fnh_ {\n    margin-left: 3.375rem;\n}\n.data_set_list-item_2lsdx:nth-child(1)>div {\n    color: black\n}", ""]);

// exports
exports.locals = {
	"list-item": "data_set_list-item_2lsdx",
	"listItem": "data_set_list-item_2lsdx",
	"empty": "data_set_empty_3yzDQ",
	"image": "data_set_image_2O3KN",
	"name": "data_set_name_3fnh_",
	"desc": "data_set_desc_3IAHR",
	"date": "data_set_date_2d9A7"
};