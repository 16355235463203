import addIcon from './add.png'
import audioIcon from './audio.png'
import addTagIcon from './add-tag.svg'
import amplifyIcon from './amplify.png'
import attitudeIcon from './attitude.png'
import algorithmIcon from './algorithm.svg'
import algorithmActIcon from './algorithm-act.svg'
import addTagDeleteIcon from './add-tag-delete.svg'
import closeIcon from './close.svg'
import checkIcon1 from './check.png'
import circleIcon from './circle.svg'
import closeIcon1 from './close1.png'
import contractIcon from './contract.svg'
import dragIcon from './drag.png'
import deleteIcon2 from './delete2.png'
import deleteIcon3 from './delete3.png'
import deleteIcon1 from './delete_icon.png'
import dimensionIcon from './dimension.svg'
import dimensionActIcon from './dimension-act.svg'
import backIcon from './back.svg'
import snapIcon from './snap.svg'
import searchIcon from './search.svg'
import leftIcon from './left.svg'
import imageIcon from './image.png'
import uploadImgIcon from './upload-img.svg'
import navIcon from './nav.svg'
import noCheckIcon1 from './no-check.png'
import moreIcon from './more.svg'
import moveIcon from './move.png'
import proportionIcon from './proportion.png'
import reduceIcon from './reduce.png'
import refreshIcon from './refresh.svg'
import rotateIcon from './rotate_icon.png'
import tagDeleteIcon from './tag-delete.svg'
import uploadIcon from './upload.svg'
import uploadActIcon from './upload-act.svg'
import warnIcon from './warn.svg'
import newBack from './new-back.svg'
import newCloseProject from './new-close-project.svg'
import exportSuccessIcon from './export-success-icon.svg'

import noCheckIcon from '../../../../static/assets/icon_no_check.png'
import checkIcon from '../../../../static/assets/icon_check.png'

const IMG_CONTAINER = {
    addIcon,
    audioIcon,
    addTagIcon,
    amplifyIcon,
    attitudeIcon,
    algorithmIcon,
    algorithmActIcon,
    addTagDeleteIcon,
    closeIcon,
    checkIcon1,
    circleIcon,
    closeIcon1,
    contractIcon,
    dragIcon,
    deleteIcon2,
    deleteIcon3,
    deleteIcon1,
    dimensionIcon,
    dimensionActIcon,
    backIcon,
    snapIcon,
    searchIcon,
    leftIcon,
    imageIcon,
    uploadImgIcon,
    navIcon,
    noCheckIcon1,
    moreIcon,
    moveIcon,
    proportionIcon,
    rotateIcon,
    reduceIcon,
    refreshIcon,
    tagDeleteIcon,
    uploadIcon,
    uploadActIcon,
    warnIcon,
    noCheckIcon,
    checkIcon,
    newBack,
    newCloseProject,
    exportSuccessIcon
}

export default IMG_CONTAINER