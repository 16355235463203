import React from 'react';
import classNames from 'classnames';
import bindAll from 'lodash.bindall';
import styles from './delete-camera.css';
import closeIcon from '../img/close.svg'
import check from '../../../../static/assets/icon_check.png'
import noCheck from '../../../../static/assets/icon_no_check.png'
import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    prohibit: {
        id: 'gui.takePhoto.prohibit',
        description: '无法删除正在使用的设备',
        defaultMessage: '无法删除正在使用的设备'
    },
    deleteCamera: {
        id: 'gui.takePhoto.deleteCamera',
        description: '删除摄像头',
        defaultMessage: '删除摄像头'
    },
    confirmDelete: {
        id: 'gui.takePhoto.confirmDelete',
        description: '确认删除',
        defaultMessage: '确认删除'
    },
})

class DeleteCamera extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            check: []
        }
        bindAll(this, [
            'selectHandler',
            'confirmHandler'
        ])
    }
    componentDidMount() {
        let list = []
        try {
            list = JSON.parse(localStorage.getItem('camera_device')) || []
        } catch (error) {
            list = []
        }
        this.setState({
            list
        })
    }
    selectHandler(index, item) {
        if (this.props.camera_val === item) {
            this.props.onChangeMessage({
                visible: true,
                content: this.props.intl.formatMessage(LANG_MESSAGE.prohibit),
                type: 'fail'
            })
            return 
        }
        let arr = this.state.check
        let idx = this.state.check.findIndex(item => item === index)
        if (idx !== -1) {
            arr.splice(idx, 1)
        } else {
            arr.push(index)
        }
        this.setState({
            check: arr
        })
    }
    confirmHandler() {
        let arr = this.state.list
        let n_arr = arr.filter((item, index) => !this.state.check.includes(index))
        localStorage.setItem('camera_device', JSON.stringify(n_arr))
        this.props.changeDeleteCamera(false)
    }
    
    render() {
        return (
            <>
                <div className={styles.mask}></div>
                <div className={classNames(styles.maskBox, styles.deleteBox)}>
                    <div className={styles.deleteHeader}>
                        <span>{this.props.intl.formatMessage(LANG_MESSAGE.deleteCamera)}</span>
                        <img 
                            className={styles.closeIcon} 
                            src={closeIcon} 
                            alt="" 
                            onClick={
                                () => this.props.changeDeleteCamera(false)
                            }
                        />
                    </div>

                    <ul className={styles.deleteContent}>
                        {this.state.list.map((item, index) => {
                            return (
                                <li 
                                    key={index} 
                                    className={styles.listItem} 
                                    style={{background: this.state.check.includes(index)  ? 'rgb(253, 246, 220)' : null}}
                                    onClick={() => this.selectHandler(index, item.label)}
                                >
                                    <img src={this.state.check.includes(index) ? check : noCheck} alt="" />
                                    <span>{item.label}</span>
                                </li>
                            )
                        })}
                    </ul>
                    
                    {this.state.check.length > 0 ? (
                        <div  className={styles.footer}>
                            <input type="button" value={this.props.intl.formatMessage(LANG_MESSAGE.confirmDelete)} onClick={this.confirmHandler} />
                        </div>
                    ) : null}
                </div>
            </>
        )
    }
}
export default DeleteCamera