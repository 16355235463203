import React from "react";
import classNames from 'classnames';
import styles from './download-model.css';
import close from '../../../../static/assets/icon_close.png';
import { Radio, Button } from 'antd'

const DownloadModel = (props) => {
    const {
        name,
        width,
        height,
        confThres,
        iouThres,
        is_cover,
        is_down,
        setDownModelShow,
        setDownModelName,
        setDownModelWidth,
        setDownModelHeight,
        setDownModelTh,
        setDownModelIo,
        setCover,
        downloadModelConfirm
    } = props
    return (
        <div className={styles.comModelContainer}>
            <div className={styles.downloadBox}>
                <div className={styles.header}>
                    <img className={styles.close} src={close} onClick={() => setDownModelShow(false)} />
                </div>

                <div className={styles.content}>
                    <h3>{props.intl.formatMessage(props.LANG_MESSAGE.modelSave)}</h3>
                    <div>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.MODEL_NAME)}</span>
                        <input type="text" value={name} onChange={setDownModelName} />
                    </div>

                    <div className={styles.downSize}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.imageSize)}</span>
                        <input type="number" value={width} onChange={setDownModelWidth} />
                        <input type="number" value={height} onChange={setDownModelHeight} />
                    </div>

                    <div className={styles.downSize}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.confidenceLevel)}</span>
                        <input type="number" value={confThres} onChange={setDownModelTh} />
                    </div>

                    <div className={styles.downSize}>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.threshold)}</span>
                        <input type="number" value={iouThres} onChange={setDownModelIo} />
                    </div>

                    <div>
                        <span>{props.intl.formatMessage(props.LANG_MESSAGE.isCover)}</span>
                        <Radio.Group onChange={setCover} value={is_cover}>
                            <Radio value={true}>{props.intl.formatMessage(props.LANG_MESSAGE.yes)}</Radio>
                            <Radio value={false}>{props.intl.formatMessage(props.LANG_MESSAGE.no)}</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className={styles.footer}>
                    <input 
                        className={classNames(styles.comBtn, styles.cancle)}
                        type="button"
                        value={props.intl.formatMessage(props.LANG_MESSAGE.cancel)}
                        onClick={() => setDownModelShow(false)}
                    />
                    <Button 
                        className={classNames(styles.comBtn, styles.confirm)} 
                        type="primary" 
                        loading={is_down}
                        onClick={downloadModelConfirm}
                    >
                        {is_down ? props.intl.formatMessage(props.LANG_MESSAGE.curing) : props.intl.formatMessage(props.LANG_MESSAGE.ok)}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DownloadModel