import React from 'react';
import { connect } from 'react-redux';
import bindAll from 'lodash.bindall';
import {defineMessages, intlShape} from 'react-intl';

import {login, userManage} from '../../api/login.js'
import { loginClose, setToken } from '../../reducers/login.js'

import closeIcon from './icon--close.png'
import closeIcon1 from './icon--close1.png'
import phoneIcon from './icon--phone.png'
import logoIcon from './icon--logo.svg'
import pwdIcon from './icon--pwd.png'

import styles from './login.css';

const messages = defineMessages({
    signIn: {
        id: 'gui.menuBar.signIn',
        description: 'Label for the name of the signIn',
        defaultMessage: 'signIn'
    },
    enterAccount: {
        id: 'gui.login.enterAccount',
        description: 'Label for the name of the enterAccount',
        defaultMessage: 'enterAccount'
    },
    enterPassword: {
        id: 'gui.login.enterPassword',
        description: 'Label for the name of the enterPassword',
        defaultMessage: 'enterPassword'
    },
    incorrect: {
        id: 'gui.login.incorrect',
        description: 'Label for the name of the incorrect',
        defaultMessage: 'incorrect'
    }
})

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            pwd: '',
            is_true: false,
            is_error: false
        }
        bindAll(this, [
            'handleLogin',
            'handlePhone',
            'handlePwd',
            'handleStatus',
            'handleClearInput',
            'getUserManage'
        ]);
    }
    componentWillMount() {

    }
    componentDidMount() {
        this.handleStatus()
        document.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
                if (document.getElementById('login-btn')) {
                    this.handleLogin()   
                }
            }
        })
    }
    componentWillUnmount() {

    }
    async handleLogin() {
        if (this.state.is_true) {
            let res = await login({
                username: this.state.phone,
                password: this.state.pwd
            })
            if (res.success === true) {
                localStorage.setItem('token', res.payload.token)
                this.props.onSetToken(res.payload)
                // this.getUserManage(res.payload.token)
            } else {
                this.setState({
                    is_error: true
                })
            }
        }
    }
    handlePhone(e) {
        this.setState(
            {
                phone: e.target.value,
                is_error: false
            },
            () => this.handleStatus()
        )
    }
    handlePwd(e) {
        this.setState(
            {
                pwd: e.target.value,
                is_error: false
            },
            () => this.handleStatus()
        )
    }
    handleStatus() {
        // let reg = /^1[3,4,5,6,7,8,9][0-9]{9}$/
        this.setState({
            is_true: this.state.phone && this.state.pwd ? true : false
        })
    }
    handleClearInput(type) {
        if(type === 'phone') {
            this.setState({
                phone: '',
                is_true: false
            })
        } else if (type === 'pwd') {
            this.setState({
                pwd: '',
                is_true: false
            })
        }
    }
    async getUserManage(token) {
        let res = await userManage(token)
        if (res.success) {
            localStorage.setItem('userId', res.payload.user.id)
        }
    }
    render() {
        return (
            <div className={styles.loginWrapper}>
                <div className={styles.loginMask}></div>
                <div className={styles.signIn}>
                    <img 
                        className={styles.signClose}
                        src={closeIcon}
                        onClick={this.props.onCloseLogin}
                    />
                
                    <div className={styles.signContainer}>
                        <img  className={styles.signLogo} src={logoIcon}/>
                        <p className={styles.signText}>{this.props.intl.formatMessage(messages.signIn)}</p>

                        <div className={styles.signPhone}>
                            <img className={styles.phoneIcon} src={phoneIcon}/>
                            <input
                                className={styles.phoneTip}
                                placeholder={this.props.intl.formatMessage(messages.enterAccount)}
                                value={this.state.phone}
                                onChange={this.handlePhone}
                                onBlur={this.handleStatus}
                            />
                            <img className={styles.phoneClose} src={closeIcon1} onClick={ () => this.handleClearInput('phone')} />
                        </div>

                        <div className={styles.signPwd}>
                            <img className={styles.pwdIcon} src={pwdIcon}/>
                            <input
                                className={styles.pwdTip}
                                placeholder={this.props.intl.formatMessage(messages.enterPassword)}
                                value={this.state.pwd}
                                onChange={this.handlePwd}
                                onBlur={this.handleStatus}
                            />
                            <img className={styles.phoneClose} src={closeIcon1} onClick={() => this.handleClearInput('pwd')} />
                        </div>

                        {/* <div class="sign-fun">
                            <div>
                            <div class="icon_nocheck"></div>
                            <span style="font-size: 12px; color: #BBBBBB">七天内免登录</span>
                            </div>

                            <div>
                            <span style="font-size: 14px; color: #CE8137">注册账号</span>
                            <div class="icon_line"></div>
                            <span style="font-size: 14px; color: #CE8137">忘记密码</span>
                            </div>
                        </div> */}

                        <div className={styles.signPwd}>
                            {this.state.is_error ? (<span className={styles.signError}>{this.props.intl.formatMessage(messages.incorrect)}</span>) : null}
                            <input
                                id="login-btn"
                                className={styles.logOn}
                                type="button"
                                value={this.props.intl.formatMessage(messages.signIn)}
                                style={{
                                    background: this.state.is_true ? '#FADF76' : '#F5F5F5',
                                    color: this.state.is_true ? '#CE8137' : '#D9D9D9'
                                }}
                                onClick={this.handleLogin}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Login.propTypes = {
    intl: intlShape.isRequired,
}

const mapStateToProps = (state, ownProps) => {
    return {}
}

const mapDispatchToProps = dispatch => ({
    onCloseLogin: () => dispatch(loginClose()),
    onSetToken: (param) => dispatch(setToken(param))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)
