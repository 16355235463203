import React from 'react';

import Box from '../box/box.jsx';
import Selector from './selector.jsx';
import styles from './asset-panel.css';
import closeIcon from './close.png';

const AssetPanel = props => {
    const {
        onCloseRole,
        istype
    } = props;
    if (istype == 1) {
        return (
            <Box className={styles.wrapper}>
                <Selector
                    style={{background: "hsla(51, 41%, 97%, 1)"}}
                    className={styles.selector}
                    {...props}
                />
                {props.istype !== 1 ? (<Box className={styles.detailArea}>
                    {props.children}
                </Box>) : null}
            </Box>
            )
    }
    return (
    <Box className={styles.wrapper} style={{position: 'relative' , maxWidth: '1448px', minWidth: '996px', maxHeight: '908px', minHeight: '580px', background: '#fff', marginTop: '20px', borderRadius: '10px',padding: '10px'}}>
        <Selector
            style={{background: "hsla(51, 41%, 97%, 1)"}}
            className={styles.selector}
            {...props}
        />
        {props.istype !== 1 ? (<Box className={styles.detailArea}>
            {props.children}
        </Box>) : null}
        <Box style={{position: 'absolute', right: '25px', top: '20px', cursor: 'pointer'}} onClick={onCloseRole}>
            <img src={closeIcon} alt="" style={{width: '24px'}}/>
        </Box>
    </Box>
    )
};

AssetPanel.propTypes = {
    ...Selector.propTypes
};

export default AssetPanel;
