const SET_IFRAME_SHOW = 'set_show';
const SET_IFRAME_PARAMS = 'set_params'
const SET_CONNECT = 'set_connect'

function emitIframe(data) {
    if (data) {
        let { route, language, query, space = '', connect = true, theme = 'light' } = data;
        const send = () => {
            const iframe = document.getElementById('com-iframe');
            if (iframe) {
                iframe.contentWindow.postMessage({ 
                    connect,
                    language,
                    cas_token: localStorage.getItem('cas_token'),
                    theme,
                    route,
                    query,
                    space
                    // token: localStorage.getItem('token'),
                    // type: 'train-report',
                    // base_url: ON_LINE_URL,
                    // space: sessionStorage.getItem('space'),
                    // data: {
                        // project: newV.project,
                        // type: newV.type
                    // }
                }, '*');
            }
        }
        send()
    }
}

// initial state
const initState = {
    show: false,
    params: {},
    connect: false
}

// reducer
const reducer = function(state = initState, action) {
    if (typeof state === 'undefined') state = initState;
    switch (action.type) {
        case SET_IFRAME_SHOW:
            return Object.assign({}, state, {
                show: action.params,
            })
        case SET_IFRAME_PARAMS:
            emitIframe(action.params)
            return Object.assign({}, state, {
                params: action.params,
            })
        case SET_CONNECT:
            return Object.assign({}, state, {
                connect: action.params
            })
        default: 
            return state;
    }
}

// action creator
const setIframeState = (params) => ({
    type: SET_IFRAME_SHOW,
    params
})
const setIframeParams = (params) => ({
    type: SET_IFRAME_PARAMS,
    params
})
const setConnect = (params) => ({
    type: SET_CONNECT,
    params
})

export {
    reducer as default,
    initState as iframeInitState,
    setIframeState,
    setIframeParams,
    setConnect
}
