
const jsonStrToMap = (jsonStr) => {
    const jsonObj = JSON.parse(jsonStr)
        const map = new Map()
        for (const k of Object.keys(jsonObj)) {
            var type = typeof jsonObj[k];
            // console.log(type);
            switch (type) {
                case 'number':
                    map.set(k, jsonObj[k]);
                    break;
                case 'object':
                    map.set(k, JSON.stringify(jsonObj[k]));
                    break;
                case 'string':
                    map.set(k, jsonObj[k].replace(/^\"|\"$/g,'')); // 去掉首尾的双引号
                    break;
                default:
                    map.set(k, JSON.stringify(jsonObj[k]));
            }
          
        }
        return map
};
export {
    jsonStrToMap
};