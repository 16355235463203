const axios = require('axios').default;
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const casService = axios.create({
    // axios 请求中 URL 的公共部分
    baseURL: '/casapi',
    // 设置请求超时时间
    timeout: 10 * 1000
})

// 请求拦截器
casService.interceptors.request.use(config => {
    return config
})

// 响应拦截器
casService.interceptors.response.use(res => {
    return res
}, error => {
    console.error(error.message)
    return Promise.reject(error);
})

module.exports = casService
