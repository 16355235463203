exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FeF */ /* #E9F1FC */ /* #D9E3F2 */ /* $ui-modal-overlay: hsla(215, 100%, 65%, 0.9);  */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* 主题颜色 */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* #0FBD8C */ /* #0B8E69 */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .stop-all_button_3CKh4 {\n    width: 70px;\n    height: 28px;\n    background-color:hsla(29, 61%, 51%, 1);\n    border-radius: 6px;\n    padding: 0 11px;\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: justify;\n    -webkit-justify-content: space-between;\n        -ms-flex-pack: justify;\n            justify-content: space-between;\n    -webkit-box-align: center;\n    -webkit-align-items: center;\n        -ms-flex-align: center;\n            align-items: center;\n    cursor: pointer;\n} .stop-all_stop-all_1Y8P9 {\n    height: 14px;\n    border-radius: 0.25rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n} .stop-all_stop-all_1Y8P9:hover {\n    background-color: hsla(215, 100%, 65%, 0.15);\n    opacity: 0.5;\n    \n} .stop-all_stop-all_1Y8P9.stop-all_is-active_3nog0 {\n    background-color: hsla(215, 100%, 65%, 0.35);\n    opacity: 0.5;\n} .stop-all_title_3ZrUS {\n    font-size: 14px;\n    color: hsla(0, 0%, 100%, 1);\n}\n\n", ""]);

// exports
exports.locals = {
	"button": "stop-all_button_3CKh4",
	"stop-all": "stop-all_stop-all_1Y8P9",
	"stopAll": "stop-all_stop-all_1Y8P9",
	"is-active": "stop-all_is-active_3nog0",
	"isActive": "stop-all_is-active_3nog0",
	"title": "stop-all_title_3ZrUS"
};