export const config = {
    // appName: "app-casnode",
    redirectPath: "", // in accordance with casdoor configuration
    serverUrl: 'https://caszs.codespore.cn',
    endpoint: 'https://caszs.codespore.cn',
    clientId: '398d84b611b99a2cce8e',
    clientSecret: '919e0a4617a3b94e8877aa3f956df20641cd72e7',
    certificate: 'soke',
    organizationName: 'soke',
    applicationName: 'tpusp',
};
