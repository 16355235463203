import { defineMessages } from "react-intl";

const LANG_MESSAGE = defineMessages({
    MODEL: {
        id: 'gui.blocklyDialog.MODEL',
        description: '模型',
        defaultMessage: '模型'
    },
    TPU_MODEL: {
        id: 'gui.blocklyDialog.TPU_MODEL',
        description: 'TPU模型',
        defaultMessage: 'TPU模型'
    },
    CLOUD_MODEL: {
        id: 'gui.blocklyDialog.CLOUD_MODEL',
        description: '云模型',
        defaultMessage: '云模型'
    },
    DATA_SET: {
        id: 'gui.blocklyDialog.DATA_SET',
        description: '数据集',
        defaultMessage: '数据集'
    },
    TRAIN: {
        id: 'gui.blocklyDialog.TRAIN',
        description: '训练',
        defaultMessage: '训练'
    },
    COMPILE: {
        id: 'gui.blocklyDialog.COMPILE',
        description: '编译',
        defaultMessage: '编译'
    },
    REASONING: {
        id: 'gui.blocklyDialog.REASONING',
        description: '推理',
        defaultMessage: '推理'
    },
    DATA_ENGINEERING: {
        id: 'gui.blocklyDialog.DATA_ENGINEERING',
        description: '数据工程',
        defaultMessage: '数据工程'
    },
    TRAINING_PARAMETERS: {
        id: 'gui.blocklyDialog.TRAINING_PARAMETERS',
        description: '训练参数',
        defaultMessage: '训练参数'
    },
    PRESET_DATASET: {
        id: 'gui.blocklyDialog.PRESET_DATASET',
        description: '预设数据集',
        defaultMessage: '预设数据集'
    },
    PUBLIC_DATASET: {
        id: 'gui.blocklyDialog.PUBLIC_DATASET',
        description: '公共数据集',
        defaultMessage: '公共数据集'
    },
    MY_DATASET: {
        id: 'gui.blocklyDialog.MY_DATASET',
        description: '我的数据集',
        defaultMessage: '我的数据集'
    },
    DEFAULT_MODEL: {
        id: 'gui.blocklyDialog.DEFAULT_MODEL',
        description: '预设模型',
        defaultMessage: '预设模型'
    },
    COMMON_MODEL: {
        id: 'gui.blocklyDialog.COMMON_MODEL',
        description: '公共模型',
        defaultMessage: '公共模型'
    },
    MY_MODEL: {
        id: 'gui.blocklyDialog.MY_MODEL',
        description: '我的模型',
        defaultMessage: '我的模型'
    },
    DATASET_NAME: {
        id: 'gui.blocklyDialog.DATASET_NAME',
        description: '数据集名称',
        defaultMessage: '数据集名称'
    },
    DATASET_DESCRIPTION: {
        id: 'gui.blocklyDialog.DATASET_DESCRIPTION',
        description: '数据集描述',
        defaultMessage: '数据集描述'
    },
    CREATION_TIME: {
        id: 'gui.blocklyDialog.CREATION_TIME',
        description: '创建时间',
        defaultMessage: '创建时间'
    },
    PROVIDER: {
        id: 'gui.blocklyDialog.PROVIDER',
        description: '提供者',
        defaultMessage: '提供者'
    },
    MODEL_NAME: {
        id: 'gui.blocklyDialog.MODEL_NAME',
        description: '模型名称',
        defaultMessage: '模型名称'
    },
    MODEL_DESCRIPTION: {
        id: 'gui.blocklyDialog.MODEL_DESCRIPTION',
        description: '模型描述',
        defaultMessage: '模型描述'
    },
    VERSION: {
        id: 'gui.blocklyDialog.VERSION',
        description: '版本',
        defaultMessage: '版本'
    },
    APPLICABLE_CHIPS: {
        id: 'gui.blocklyDialog.APPLICABLE_CHIPS',
        description: '适用芯片',
        defaultMessage: '适用芯片'
    },
    ALGORITHM: {
        id: 'gui.blocklyDialog.ALGORITHM',
        description: '算法',
        defaultMessage: '算法'
    },
    PARAMETER: {
        id: 'gui.blocklyDialog.PARAMETER',
        description: '参数名称',
        defaultMessage: '参数名称'
    },
    OPERATE: {
        id: 'gui.blocklyDialog.OPERATE',
        description: '操作',
        defaultMessage: '操作'
    },
    DUPLICATE_TRAINING_PARAMETER_NAME: {
        id: 'gui.blocklyDialog.DUPLICATE_TRAINING_PARAMETER_NAME',
        description: '参数名称重复',
        defaultMessage: '参数名称重复'
    },
    PLEASE_SELECT: {
        id: 'gui.blocklyDialog.PLEASE_SELECT',
        description: '请选择',
        defaultMessage: '请选择'
    },
    MANAGE_TRAINING_PARAMETERS: {
        id: 'gui.blocklyDialog.MANAGE_TRAINING_PARAMETERS',
        description: '编辑训练参数',
        defaultMessage: '编辑训练参数'
    },
    ADD_TRAINING_PARAMETERS: {
        id: 'gui.blocklyDialog.ADD_TRAINING_PARAMETERS',
        description: '添加训练参数',
        defaultMessage: '添加训练参数'
    },
    UPLOAD_DATASET: {
        id: 'gui.blocklyDialog.UPLOAD_DATASET',
        description: '上传数据集',
        defaultMessage: '上传数据集'
    },
    NO_LOGIN: {
        id: 'gui.blocklyDialog.NO_LOGIN',
        description: '您还未登录，请先登录',
        defaultMessage: '您还未登录，请先登录'
    },
    CONFIRM_ADD: {
        id: 'gui.blocklyDialog.CONFIRM_ADD',
        description: '确认添加',
        defaultMessage: '确认添加'
    },
    edit: {
        id: 'gui.menuBar.edit',
        description: '编辑',
        defaultMessage: '编辑'
    },
    delete: {
        id: 'gui.soundEditor.delete',
        description: '删除',
        defaultMessage: '删除'
    },
    NEWLY_ADDED: {
        id: 'gui.blocklyDialog.NEWLY_ADDED',
        description: '新增',
        defaultMessage: '新增'
    },
    cancel: {
        id: 'gui.prompt.cancel',
        description: '取消',
        defaultMessage: '取消'
    },
    ok: {
        id: 'gui.prompt.ok',
        description: '确定',
        defaultMessage: '确定'
    },
    KEYWORD_SEARCH: {
        id: 'gui.blocklyDialog.KEYWORD_SEARCH',
        description: '关键词搜索',
        defaultMessage: '关键词搜索'
    },
    filterPlaceholder: {
        id: 'gui.library.filterPlaceholder',
        description: '搜索',
        defaultMessage: '搜索'
    },
    all: {
        id: 'gui.dimesion.all',
        description: '全部',
        defaultMessage: '全部'
    },
    TIME_ON: {
        id: 'gui.blocklyDialog.TIME_ON',
        description: '开始时间',
        defaultMessage: '开始时间'
    },
    END_TIME: {
        id: 'gui.blocklyDialog.END_TIME',
        description: '结束时间',
        defaultMessage: '结束时间'
    },
    ENTER_MODEL_NAME: {
        id: 'gui.blocklyDialog.ENTER_MODEL_NAME',
        description: '请输入模型名称',
        defaultMessage: '请输入模型名称'
    },
    TIME: {
        id: 'gui.blocklyDialog.TIME',
        description: '时间',
        defaultMessage: '时间'
    },
    TPU_HYPERPARAMETERS: {
        id: 'gui.blocklyDialog.TPU_HYPERPARAMETERS',
        description: 'TPU超参数',
        defaultMessage: 'TPU超参数'
    },
    ADD_TPU_PARAMETERS: {
        id: 'gui.blocklyDialog.ADD_TPU_PARAMETERS',
        description: '添加TPU参数',
        defaultMessage: '添加TPU参数'
    },
    FRAMEWORK: {
        id: 'gui.blocklyDialog.FRAMEWORK',
        description: '框架',
        defaultMessage: '框架'
    },
    NEW_DATA_ENGINEERING: {
        id: 'gui.blocklyDialog.NEW_DATA_ENGINEERING',
        description: '新建数据工程',
        defaultMessage: '新建数据工程'
    },
    INFERENCE_HYPERPARAMETERS: {
        id: 'gui.blocklyDialog.INFERENCE_HYPERPARAMETERS',
        description: '推理超参数',
        defaultMessage: '推理超参数'
    },
    ADD_INFERENCE_PARAMETERS: {
        id: 'gui.blocklyDialog.ADD_INFERENCE_PARAMETERS',
        description: '添加推理参数',
        defaultMessage: '添加推理参数'
    },
    download: {
        id: 'gui.stageHeader.downloadModel',
        description: '下载模型',
        defaultMessage: '下载模型'
    },
    DISPLAY_NAME: {
        id: 'gui.blocklyDialog.DISPLAY_NAME',
        description: '显示名称',
        defaultMessage: '显示名称'
    },
    ENTER_MESSAGE: {
        id: 'gui.blocklyDialog.ENTER_MESSAGE',
        description: '请输入显示名称/参数名称',
        defaultMessage: '请输入显示名称/参数名称'
    },
    UPLOAD_MODEL_FILE: {
        id: 'gui.blocklyDialog.UPLOAD_MODEL_FILE',
        description: '请先上传模型文件',
        defaultMessage: '请先上传模型文件'
    },
    status: {
        id: 'gui.blocklyDialog.status',
        description: '状态',
        defaultMessage: '状态'
    }
})

export default LANG_MESSAGE
