import React from 'react';
import { connect } from 'react-redux';
import {FormattedMessage, defineMessages, injectIntl, intlShape} from 'react-intl';
import bindAll from 'lodash.bindall';
import PropTypes from 'prop-types';
import VM from 'scratch-vm';
import {BitmapAdapter as V2BitmapAdapter2} from 'scratch-svg-renderer';
// 声音
import AudioEngine from 'scratch-audio';

import IMG_CONTAINER from './img/index.js'
import LANG_MESSAGE from './lang.js';
import styles from './source-material.css';

import { activateTab, BLOCKS_TAB_INDEX } from '../../reducers/editor-tab.js'
import { changeSourceMaterial } from '../../reducers/status.js';

// 标签名
import spriteTags from '../../lib/libraries/sprite-tags.js';
import backdropTags from '../../lib/libraries/backdrop-tags.js';
import soundTags from '../../lib/libraries/sound-tags.js';
// 资源
import spriteLibraryContent from '../../lib/libraries/sprites.json'; // 角色系列
import randomizeSpritePosition from '../../lib/randomize-sprite-position'; // 角色系列所需方法
import costumeLibraryContent from '../../lib/libraries/costumes.json'; // 角色单个
import backdropLibraryContent from '../../lib/libraries/backdrops.json'; // 背景
import soundLibraryContent from '../../lib/libraries/sounds.json'; // 声音
import storage from '../../lib/storage.js';

import LibraryItem from '../../containers/library-item.jsx';

/**
 * Find the AssetType which corresponds to a particular file extension. For example, 'png' => AssetType.ImageBitmap.
 * @param {string} fileExtension - the file extension to look up.
 * @returns {AssetType} - the AssetType corresponding to the extension, if any.
 */
const getAssetTypeForFileExtension = function(fileExtension) {
    const compareOptions = {
        sensitivity: 'accent',
        usage: 'search'
    }

    for (const assetTypeId in storage.AssetType) {
        const assetType = storage.AssetType[assetTypeId];
        if (fileExtension.localeCompare(assetType.runtimeFormat, compareOptions) === 0) {
            return assetType;
        }
    }
}

/**
 * Figure out one or more icon(s) for a library item.
 * If it's an animated thumbnail, this will return an array of `imageSource`.
 * Otherwise it'll return just one `imageSource`.
 * @param {object} item - either a library item or one of a library item's costumes.
 *   The latter is used internally as part of processing an animated thumbnail.
 * @returns {LibraryItem.PropTypes.icons} - an `imageSource` or array of them, ready for `LibraryItem` & `ScratchImage`
 */
const getItemIcons = function (item) {
    const costumes = (item.json && item.json.costumes) || item.costumes;
    if (costumes) {
        return costumes.map(getItemIcons);
    }

    if (item.rawURL) {
        return {
            uri: item.rawURL
        };
    }

    if (item.assetId && item.dataFormat) {
        return {
            assetId: item.assetId,
            assetType: getAssetTypeForFileExtension(item.dataFormat)
        };
    }

    const md5ext = item.md5ext || item.md5 || item.baseLayerMD5;
    if (md5ext) {
        const [assetId, fileExtension] = md5ext.split('.');
        return {
            assetId: assetId,
            assetType: getAssetTypeForFileExtension(fileExtension)
        };
    }
};

// @todo need to use this hack to avoid library using md5 for image
// 声音资源处理
const soundLibraryThumbnailData = soundLibraryContent.map(sound => {
    const {
        md5ext,
        ...otherData
    } = sound;
    return {
        _md5: md5ext,
        rawURL: IMG_CONTAINER.sound1,
        ...otherData
    }
})

class SourceMaterial extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            tabList: [
                {
                    title: props.intl.formatMessage(LANG_MESSAGE.sprite),
                    tag: spriteTags,
                    icon: IMG_CONTAINER.roleIcon,
                    act_icon: IMG_CONTAINER.roleActiveIcon,
                    data: this.props.sourceType === 0 ? spriteLibraryContent : costumeLibraryContent
                }, 
                {
                    title: props.intl.formatMessage(LANG_MESSAGE.backdropsTab),
                    tag: backdropTags,
                    icon: IMG_CONTAINER.backgroundIcon,
                    act_icon: IMG_CONTAINER.backgroundActiveIcon,
                    data: backdropLibraryContent
                },
                {
                    title: props.intl.formatMessage(LANG_MESSAGE.soundsTab),
                    tag: soundTags,
                    icon: IMG_CONTAINER.soundIcon,
                    act_icon: IMG_CONTAINER.soundActiveIcon,
                    data: soundLibraryThumbnailData
                }
            ],
            tab_list_active: 0,
            tab_list_index: 0,
            filterQuery: '',
            playingItem: null,
            loaded: false,
            is_open: true
        }
        bindAll(this, [
            'changeCategory',
            'changeLi',
            'handleFilterChange',
            'handleMouseEnter',
            'handleMouseLeave',
            'handleSelect',
            'setFilteredDataRef',
            'getTabActive',
            'handleItemMouseEnter',
            'handleItemMouseLeave',
            'stopPlayingSound',
            'onStop',
            'setStopHandler',
            'scrollToTop'
        ])

        // 声音
        /**
         * AudioEngine that will decode and play sounds for us.
         * @type {AudioEngine}
         */
        this.audioEngine = null;
        /**
         * A promise for the sound queued to play as soon as it loads and
         * decodes.
         * @type {Promise<SoundPlayer>}
         */
        this.playingSoundPromise = null;
        /**
         * function to call when the sound ends
         */
        this.handleStop = null;
        this.customStyle = {
            libarayItem: {
                height: '120px',
                width: '120px',
                borderStyle: 'none',
                borderRadius: '8px',
                fontFamily: 'Source Han Sans CN-Regular',
                color: '#333333',
                background: '#FDF6DC'
            },
            libraryItemImageContainerWrapper: {
                height: '70px'
            },
            libraryItemImageContainer: {
                height: '70px',
                lineHeight: '70px',
            },
            libraryItemImage: {
                borderRadius: '4px'
            },
            libraryItemName: {
                fontSize: '14px'
            }

        }
    }
    componentWillMount() {
        // 添加全部
        if (backdropTags.findIndex((item) => item.tag === 'all') === -1) backdropTags.unshift({tag: 'all', intlLabel: LANG_MESSAGE.allTag})
        if (spriteTags.findIndex((item) => item.tag === 'all') === -1) spriteTags.unshift({tag: 'all', intlLabel: LANG_MESSAGE.allTag})
        if (soundTags.findIndex((item) => item.tag === 'all') === -1) soundTags.unshift({tag: 'all', intlLabel: LANG_MESSAGE.allTag})
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                loaded: true,
                tab_list_active: this.getTabActive(this.props.sourceType)
            }, () => {
                if (this.state.tab_list_active === 2) {
                    // 声音
                    this.audioEngine = new AudioEngine();
                    this.playingSoundPromise = null;
                    this.setStopHandler(this.handlePlayingEnd);
                }
            })
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterQuery !== this.state.filterQuery ||
            prevState.tab_list_index !== this.state.tab_list_index) {
                this.scrollToTop()
        } 
    }
    // 处理声音
    componentWillUnmount() {
        this.stopPlayingSound()
    }

    // 分类切换
    changeCategory(index) {
        let value = this.state.tab_list_active === index ? this.state.tab_list_index : 0
        // let is_open = this.state.is_open

        // 改变是否展开
        // if (this.state.tab_list_active === index) {
        //     is_open = !is_open
        // } else {
        //     is_open = false 
        // }

        if (index === 2 && !this.audioEngine) {
            this.audioEngine = new AudioEngine();
            this.playingSoundPromise = null;
        }

        this.setState({
            tab_list_active: index,
            tab_list_index: value,
            // is_open: is_open
        })
    }
    // 分类下，列表点击
    changeLi(index) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: '',
                tab_list_index: index
            })
        } else {
            if (this.state.tab_list_active === 2) {
                this.handleItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
                this.setState({
                    filterQuery: '',
                    playingItem: null,
                    tab_list_index: index
                })
            }
        }
    }
    handleMouseEnter(id) {
        if (this.state.tab_list_active === 2 && this.state.playingItem !== id) {
            this.handleItemMouseEnter(this.getFilteredData()[id])
            this.setState({
                playingItem: id
            })
        }
    }
    handleMouseLeave(id) {
        if (this.state.tab_list_active === 2) {
            this.handleItemMouseLeave(this.getFilteredData()[id])
            this.setState({
                playingItem: null
            })
        }
    }
    // 选中，添加对应内容操作
    handleSelect(id) {
        let item = this.getFilteredData()[id]
        const vmCostume = this.state.tab_list_active === 2 ? {
            format: item.format,
            md5: item._md5,
            rate: item.rate,
            sampleCount: item.sampleCount,
            name: item.name
        } : {
            name: item.name,
            rotationCenterX: item.rotationCenterX,
            rotationCenterY: item.rotationCenterY,
            bitmapResolution: item.bitmapResolution,
            skinId: null
        };
        if (this.getTabActive(this.props.sourceType) !== this.state.tab_list_active) {
            let category = [
                this.props.intl.formatMessage(LANG_MESSAGE.sprite),
                this.props.intl.formatMessage(LANG_MESSAGE.sprite),
                this.props.intl.formatMessage(LANG_MESSAGE.backdropsTab),
                this.props.intl.formatMessage(LANG_MESSAGE.soundsTab)
            ][this.props.sourceType]
            let content = this.props.intl.formatMessage(LANG_MESSAGE.addTip, {category})
            window.globalMsg.tips = {
                content: content,
                type: 'fail',
                data: new Date()
            }
            return false
        }

        if (this.state.tab_list_active === 0) { // 角色
            if (this.props.sourceType === 0) {
                randomizeSpritePosition(item)
                this.props.vm.addSprite(JSON.stringify(item)).then(() => {
                    this.props.onActivateTab(BLOCKS_TAB_INDEX)
                });
            } else if (this.props.sourceType === 1) {
                this.props.vm.addCostumeFromLibrary(item.md5ext, vmCostume);
            }
        } else if(this.state.tab_list_active === 1) { // 背景
            this.props.vm.addBackdrop(item.md5ext, vmCostume, () => {
                let bitmapAdapter2 = new V2BitmapAdapter2();
                // 兼容svg适配舞台（1，没有画板时，填充满。 2，有画板时，不做处理）
                // (this.props.costumesTabVisible && vmCostume.dataFormat !== 'svg') || 
                if (!this.props.costumesTabVisible) {
                    bitmapAdapter2.changeBackdropBitmap(vmCostume.asset.data, `image/${vmCostume.asset.dataFormat}`)
                        .then((imageData) => {
                            this.props.vm.updateStageTargetBitmap(
                                this.props.vm.runtime.getTargetForStage().currentCostume, 
                                imageData,
                                imageData.width / 2,
                                imageData.height / 2,
                                2
                            )
                        })
                        .catch(() => {})
                        .finally(() => { bitmapAdapter2 = null })
                }
            });
        } else if (this.state.tab_list_active === 2) { // 声音
            this.props.vm.addSound(vmCostume).then(() => {
                // this.props.onNewSound()
            })
        }
        
        this.props.onClose()
    }
    // 搜索
    handleFilterChange(event) {
        if (this.state.playingItem === null) {
            this.setState({
                filterQuery: event.target.value,
                tab_list_index: 0
            })
        } else {
            // 原始项目props中没有onItemMouseLeave方法
            if (this.state.tab_list_active === 2) {
                this.handleItemMouseLeave(this.getFilteredData()[[this.state.playingItem]]);
                this.setState({
                    filterQuery: event.target.value,
                    playingItem: null,
                    tab_list_index: 0
                });
            }
        }
    }
    getFilteredData() {
        let category = this.state.tab_list_active
        let index = this.state.tab_list_index
        let tag = this.state.tabList[category].tag[index].tag
        if (tag === 'all') {
            if (!this.state.filterQuery) return this.state.tabList[category].data;
            return this.state.tabList[category].data.filter(dataItem => (
                (dataItem.tags || [])
                    // Second argument to map sets `this`
                    .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                    .concat(dataItem.name ?
                        (typeof dataItem.name === 'string' ?
                            // Use the name if it is a string, else use formatMessage to get the translated name
                            dataItem.name : this.props.intl.FormattedMessage(dataItem.name.props)
                        ).toLowerCase() :
                        null)
                    .join('\n') // unlikely to partially match newlines
                    .indexOf(this.state.filterQuery.toLowerCase()) !== -1
            ))
        }

        return this.state.tabList[category].data.filter(dataItem => (
            dataItem.tags && 
            dataItem.tags
                .map(String.prototype.toLowerCase.call, String.prototype.toLowerCase)
                .indexOf(tag) !== -1
        ))

    }
    scrollToTop() {
        this.filteredDataRef.scrollTop = 0;
    }
    setFilteredDataRef(ref) {
        this.filteredDataRef = ref;
    }
    // 获取当前打开的是那种分类
    getTabActive(type) {
        switch(type) {
            case 0:
            case 1:
                return 0
            case 2: 
                return 1
            case 3:
                return 2
            default:
                return 0
        }
    }

    // 声音
    onStop () {
        if (this.playingSoundPromise !== null) {
            this.playingSoundPromise.then(soundPlayer => soundPlayer.removeListener('stop', this.onStop));
            if (this.handleStop) this.handleStop();
        }
    }
    setStopHandler (func) {
        this.handleStop = func;
    }
    stopPlayingSound () {
        // Playback is queued, playing, or has played recently and finished
        // normally.
        if (this.playingSoundPromise !== null) {
            // Forcing sound to stop, so stop listening for sound ending:
            this.playingSoundPromise.then(soundPlayer => soundPlayer.removeListener('stop', this.onStop));
            // Queued playback began playing before this method.
            if (this.playingSoundPromise.isPlaying) {
                // Fetch the player from the promise and stop playback soon.
                this.playingSoundPromise.then(soundPlayer => {
                    soundPlayer.stop();
                });
            } else {
                // Fetch the player from the promise and stop immediately. Since
                // the sound is not playing yet, this callback will be called
                // immediately after the sound starts playback. Stopping it
                // immediately will have the effect of no sound being played.
                this.playingSoundPromise.then(soundPlayer => {
                    soundPlayer.stopImmediately();
                });
            }
            // No further work should be performed on this promise and its
            // soundPlayer.
            this.playingSoundPromise = null;
        }
    }
    handleItemMouseEnter(soundItem) {
        const md5ext = soundItem._md5;
        const idParts = md5ext.split('.');
        const md5 = idParts[0];
        const vm = this.props.vm;

        // In case enter is called twice without a corresponding leave
        // inbetween, stop the last playback before queueing a new sound.
        this.stopPlayingSound();

        // Save the promise so code to stop the sound may queue the stop
        // instruction after the play instruction.
        this.playingSoundPromise = vm.runtime.storage.load(vm.runtime.storage.AssetType.Sound, md5)
            .then(soundAsset => {
                const sound = {
                    md5: md5ext,
                    name: soundItem.name,
                    format: soundItem.format,
                    data: soundAsset.data
                };
                return this.audioEngine.decodeSoundPlayer(sound);
            })
            .then(soundPlayer => {
                soundPlayer.connect(this.audioEngine);
                // Play the sound. Playing the sound will always come before a
                // paired stop if the sound must stop early.
                soundPlayer.play();
                soundPlayer.addListener('stop', this.onStop);
                // Set that the sound is playing. This affects the type of stop
                // instruction given if the sound must stop early.
                if (this.playingSoundPromise !== null) {
                    this.playingSoundPromise.isPlaying = true;
                }
                return soundPlayer;
            });
    }
    handleItemMouseLeave () {
        this.stopPlayingSound();
    }

    render() {
        return (
            <div className={styles.sourceMaterialWrappe}>
                <div className={styles.sourceMateriaMask}></div>
                <div className={styles.sourceMateriaContainer}>
                    <div className={styles.containerHeader}>
                        <span className={styles.headerTitle}>{this.props.intl.formatMessage(LANG_MESSAGE.addSpriteFromStore)}</span>
                        <div className={styles.headerTab}>
                            <img
                                className={styles.headerSearch}
                                src={IMG_CONTAINER.searchIcon}
                            />
                            <input 
                                className={styles.headerInput}
                                type="input"
                                placeholder={this.props.intl.formatMessage(LANG_MESSAGE.keywordSearchMaterials)}
                                value={this.state.filterQuery}
                                onChange={this.handleFilterChange}
                            />
                            <img
                                src={IMG_CONTAINER.closeIcon}
                                onClick={this.props.onClose}
                            />
                        </div>
                    </div>

                    <div className={styles.containerContent}>
                        <div className={styles.contentTab}>
                            <span className={styles.tabTitle}>{this.props.intl.formatMessage(LANG_MESSAGE.defaultMaterial)}</span>
                            {
                                this.state.tabList.map((item, index) => {
                                    return <div key={index}>
                                        <div className={styles.category} onClick={() => this.changeCategory(index)}>
                                            <img 
                                                src={this.state.tab_list_active === index ? item.act_icon : item.icon}
                                            />
                                            <span 
                                                className={styles.categoryTitle}
                                                style={{
                                                    color: this.state.tab_list_active === index ? '#CE8137' : '#999999'
                                                }}
                                            >{item.title}</span>
                                            <img 
                                                className={styles.categoryMore}
                                                src={this.state.tab_list_active === index ? IMG_CONTAINER.moreActiveIcon : IMG_CONTAINER.moreIcon}
                                            />
                                        </div>
                                        <ul ref="categoryUL" className={styles.ul}>
                                            {this.state.tab_list_active === index ?
                                                (item.tag.map((liItem, liIndex) => {
                                                    return <li 
                                                        className={styles.liItem} 
                                                        style={{color: this.state.tab_list_index === liIndex ? '#CE8137' : '#999999'}} 
                                                        key={liIndex}
                                                        onClick={() => this.changeLi(liIndex)}
                                                    >
                                                        <FormattedMessage {...liItem.intlLabel} />
                                                    </li>
                                                })) : null
                                            }
                                        </ul>
                                    </div>
                                })
                            }
                        </div>

                        <div className={styles.contentData} ref={this.setFilteredDataRef}>
                            {this.state.loaded ?
                                this.getFilteredData().map((dataItem, index) => {
                                    const icons = getItemIcons(dataItem);
                                    return (<LibraryItem
                                        customStyle={this.customStyle}
                                        bluetoothRequired={dataItem.bluetoothRequired}
                                        collaborator={dataItem.collaborator}
                                        description={dataItem.description}
                                        disabled={dataItem.disabled}
                                        extensionId={dataItem.extensionId}
                                        featured={dataItem.featured}
                                        hidden={dataItem.hidden}
                                        icons={icons}
                                        id={index}
                                        insetIconURL={dataItem.insetIconURL}
                                        internetConnectionRequired={dataItem.internetConnectionRequired}
                                        isPlaying={this.state.playingItem === index}
                                        key={typeof dataItem.name === 'string' ? dataItem.name : dataItem.rawURL}
                                        name={dataItem.name}
                                        onMouseEnter={this.handleMouseEnter}
                                        onMouseLeave={this.handleMouseLeave}
                                        onSelect={this.handleSelect}
                                        // 声音
                                        showPlayButton={this.state.tab_list_active === 2}
                                    />)
                                }) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

SourceMaterial.propTypes = {
    intl: intlShape.isRequired,
    handleMouseEnter: PropTypes.func,
    onItemMouseLeave: PropTypes.func,
    onItemSelected: PropTypes.func,
    vm: PropTypes.instanceOf(VM)
}

const mapStateToProps = state => {
    return {
        sourceType: state.scratchGui.status.source_type
    }
}

const mapDispatchToProps = dispatch => ({
    onClose: () => dispatch(changeSourceMaterial({
        status: false,
        type: -1
    })),
    onActivateTab: tabIndex => {
        dispatch(activateTab(tabIndex));
    },
})

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(SourceMaterial))
