exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".right-menu_item_27FEs {\n    display: -webkit-box;\n    display: -webkit-flex;\n    display: -ms-flexbox;\n    display: flex;\n}\n.right-menu_title_3Txg3 {\n    width: 90%;\n}", ""]);

// exports
exports.locals = {
	"item": "right-menu_item_27FEs",
	"title": "right-menu_title_3Txg3"
};