import React from 'react';
import { connect } from 'react-redux';
import styles from './message.css';

import sucessIcon from './success.svg'
import failIcon from './fail.svg'

class Message extends React.Component {
    constructor(props) {
        super(props)
    }
    componentWillMount() {
    }
    componentDidMount() {

    }
    render() {
        return (
            <div className={styles.messageContainer}>
                <img 
                    className={styles.messageImg}
                    src={this.props.message.type === 'success' ? sucessIcon : failIcon} 
                />
                <span className={styles.messageContent}>{ this.props.message.content }</span>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        message: state.scratchGui.status.message
    }
}

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Message)
