exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".group-menu_menu-sections_2Zlcj {\n    max-height: 300px;\n    overflow: hidden;\n    overflow-y: auto;\n}", ""]);

// exports
exports.locals = {
	"menu-sections": "group-menu_menu-sections_2Zlcj",
	"menuSections": "group-menu_menu-sections_2Zlcj"
};