import leftIcon from './left.png'
import rightIcon from './right.png'
import previewIcon from './preview.png'
import backIcon from './back.png'

const IMG_CONTAINER = {
    leftIcon,
    rightIcon,
    previewIcon,
    backIcon
}

export default IMG_CONTAINER
