const service = require('../utils/request');

// 预设数据集
export const getPresetDataSet = function(data) {
    return service({
        url: '/openaiserver/v1/datasetmanage/predataset',
        method: 'get',
        params: data
    })
}

// 公共数据集
export const getCommonDataSet = function(data) {
    return service({
        url: '/openaiserver/v1/datasetmanage/commdataset',
        method: 'get',
        params: data
    })
}

// 我的数据集
export const getMyDataSet = function(data) {
    return service({
        url: '/openaiserver/v1/datasetmanage/mydataset',
        method: 'get',
        params: data
    })
}

// 训练预设模型
export const getPresetModelVersion = function(data) {
    return service({
        url: '/openaiserver/v1/modelmanage/premodelversion',
        method: 'get',
        params: data
    })
}

// 训练公共模型
export const getCommonModelVersion = function(data) {
    return service({
        url: '/openaiserver/v1/modelmanage/commmodelversion',
        method: 'get',
        params: data
    })
}

// 训练我的模型
export const getMyModelVersion = function(data) {
    return service({
        url: '/openaiserver/v1/modelmanage/modelversion',
        method: 'get',
        params: data
    })
}

// 编译预设模型
export const getPresetTpuModelVersion = function(data) {
    return service({
        url: '/openaiserver/v2/tpumodelmanage/pretpumodelversion',
        method: 'get',
        params: data
    })
}

// 编译公共模型
export const getCommonTpuModelVersion = function(data) {
  return service({
    url: '/openaiserver/v2/tpumodelmanage/commtpumodelversion',
    method: 'get',
    params: data
  })
}

// 编译我的模型
export const getMyTpuModelVersion = function(data) {
    return service({
        url: '/openaiserver/v2/tpumodelmanage/mytpumodelversion',
        method: 'get',
        params: data
    }) 
}

// 框架
export const getFrameList = function(data) {
    return service({
        url: '/openaiserver/v1/algorithmmanage/algorithmframework',
        method: 'get',
        params: data
    })
}

// 算法
export const getAlgorithmmList = function(data) {
    return service({
        url: '/openaiserver/v1/algorithmmanage/algorithm',
        method: 'get',
        params: data
    })
}

// 训练状态
export const getTrainStatus = function(data) {
    return service({
        url: '/openaiserver/v1/trainmanage/trainjob/' + data ,
        method: 'get'
    })
}

// 训练详情
export const getTrainDetail = function(data) {
    return service({
        url: '/openaiserver/v2/modelmanage/model/version/' + data,
        method: 'get'
    })
}

// 编译状态
export const getCompileStatus = function(data) {
    return service({
        url: '/openaiserver/v2/compilemanage/compilejob/' + data ,
        method: 'get'
    })
}

// 云部署状态
export const getCloudStatus = function(id) {
    return service({
        url: '/openaiserver/v1/deploymanage/modeldeploy/' + id,
        method: 'get',
    })
}

// 获取默认配置
export const getDefaultConfig = function(params) {
    return service({
        url: '/openaiserver/v1/systemmanage/config',
        method: 'get',
        params
    })
}

// 获取资源池规格
export const getResourceSpec = function(data) {
    return service({
        url: '/openaiserver/v1/resourcemanage/resourcespec',
        method: 'get',
        params: data
    })
}

// 创建训练任务
export const createJob = function(data) {
    return service({
        url: '/openaiserver/v1/trainmanage/trainjob',
        method: 'post',
        data
    })
}

// 获取训练任务列表
export const getTrainList = function(index, size, num1, num2, num3) {
    return service({
        url: '/openaiserver/v1/trainmanage/trainjob?pageIndex=' + index + '&pageSize=' + size + '&statusList=' + num1 + '&statusList=' + num2 + '&statusList=' + num3,
        method: 'get',
    })
}

// 停止训练任务
export const stopAllTranin = function(data) {
    return service({
        url: '/openaiserver/v2/trainmanage/trainjob/stopjobs',
        method: 'post',
        data
    })
}

// 获取部署任务列表
export const getCloudList = function(index, size, num1, num2, num3) {
    return service({
        url: '/openaiserver/v1/deploymanage/modeldeploy?pageIndex=' + index + '&pageSize=' + size + '&statusList=' + num1 + '&statusList=' + num2 + '&statusList=' + num3,
        method: 'get',
    })
}

// 创建云部署
export const createCloud = function(data) {
    return service({
        url: '/openaiserver/v1/deploymanage/modeldeploy',
        method: 'post',
        data
    })
}

// 云部署推理
export const getCloudResult = function(data) {
    return service({
        timeout: '',
        url: '/openaiserver/v1/deploymanage/modeldeploy/inferimage',
        method: 'post',
        data
    })
}

// 停止部署任务
export const stopAllClound = function(data) {
    return service({
        url: '/openaiserver/v1/deploymanage/modeldeploy/stopjobs',
        method: 'post',
        data
    })
}

// 创建编译任务
export const createCompilationTask = function(data) {
    return service({
        url: '/openaiserver/v2/compilemanage/compilejob',
        method: 'post',
        data
    })
}

// 编译任务列表
export const getCompileList = function(index, size, num1, num2, num3) {
    return service({
        url: '/openaiserver/v2/compilemanage/compilejob?pageIndex=' + index + '&pageSize=' + size + '&statusList=' + num1 + '&statusList=' + num2 + '&statusList=' + num3,
        method: 'get',
    })
}

// 编译任务详情
export const getCompileDetail = function(data) {
    return service({
        url: '/openaiserver/v2/tpumodelmanage/tpumodel/version/' + data,
        method: 'get'
    })
}

// 编译日志
export const getCompileLog = function(data) {
    return service({
        timeout: '',
        url: '/log/user/compilejob/' + data + '/compiletask0/0/index.log',
        method: 'get'
    })
}

// 停止编译任务
export const stopAllCompile = function(data) {
    return service({
        url: '/openaiserver/v2/compilemanage/compilejob/stopjobs',
        method: 'post',
        data
    })
}

// 获取下载模型
export const getDownloadModel = function(id, version, data) {
    return service({
        url: '/openaiserver/v2/tpumodelmanage/tpumodel/' + id + '/version/' + version + '/download?domain=' + data,
        method: 'get'
    })
}


// 模型下载
export const downloadModel = function(params) {
    return service({
        url: `/openaiserver/v1/modelmanage/model/${params.modelId}/version/${params.version}/download?domain=${params.domian}`,
        method: 'get'
    })
}

// tpu模型下载
export const tpuDownloadModel = function(params) {
    return service({
        url: `/openaiserver/v2/tpumodelmanage/tpumodel/${params.modelId}/version/${params.version}/download?domain=${params.domian}`,
        method: 'get'
    })
}